import React from 'react';
// import parsePhoneNumber from 'libphonenumber-js';
import TwoLineCell from './TwoLineCell';

interface CallerInfoProps {
    caller: string;
    className?: string;
    headerClassName?: string;
}

const CallerInfo: React.VFC<CallerInfoProps> = ({
    caller,
    className,
    headerClassName,
}) => {
    let headerText: string;
    let descriptionText = '';

    const match = caller?.match(/^(.{1,})\s\((.{1,})\)/);
    if(match) {
        headerText = match[1];
        descriptionText = match[2];
    } else{
        headerText = caller;
        descriptionText = '';
    }

    return (
        <TwoLineCell
            headerText={headerText}
            descriptionText={descriptionText}
            className={className}
            headerClassName={headerClassName}
        />
    );
};

export default CallerInfo;
