import {makeStyles} from "@material-ui/core";
import {Colors} from "../../../styles/Colors";

export const useStyles = makeStyles(() => ({
    opacity: {
        opacity: .5,
        transition: 'opacity .5s'
    },
    container: {
        opacity: 1,
        transition: 'opacity .5s'
    },
    header: {
        color: Colors.Gray5,
        fontSize: 16,
    },
    valueTextContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    valueText: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        fontSize: 20,
        color: Colors.Text,
        minWidth:20
    },
    badge: {
        border: `1px solid ${Colors.Support}`,
        backgroundColor: Colors.White,
        color: Colors.Support,
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        height: 24,
        paddingLeft: 7,
        paddingRight: 8,
        borderRadius: 4,
        marginLeft: 8,
    },
    badgeText: {
        marginLeft: 7,
    },
    negativeBadge: {
        border: `1px solid ${Colors.Error}`,
        color: Colors.Error,
    },
    boldText: {
        fontWeight: 700,
    },
}));