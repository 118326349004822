import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import * as actions from '../../store/actions';
import {
    useStyles,
    DeviceFormProps,
    NewDeviceDialogProps,
    MacAddressPlaceHolder,
    formatAsciiAesAddress,
    uaTypeToAddDeviceTransformer,
    addDeviceValidationSchema,
    getRoute,
    macAddressLengthFuncForMask,
    macAddressLengthFunc,
} from './NewDeviceDialog.utils';
import {ReduxState} from '../../store/types';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import {Permission, PermissionType} from '../../store/types/Permission';
import DialogContainer, {DialogButton} from '../../components/AlertDialog/DialogContainer';
import Loader from '../../components/Loader/Loader';
import SelectField from '../../components/SelectField/SelectField';
import {UATypeInfo} from '../../store/types/Devices';
import CustomizedTextField from '../../components/TextField/TextField';
import IconWithTooltip from '../../components/Tooltip/IconWithTooltip';
import {ReactComponent as GenerateText} from '../../assets/generate_text.svg';
import {usePermissions} from '../../hooks/usePermissions';
import {Routes} from '../../routes/routes';
import history from '../../history';
import classNames from 'classnames';
import TextFieldWithMask from '../../components/TextFieldWithMAsk/TextFieldWithMask';
import MacMaskForFilters from '../../components/TextFieldWithMAsk/Masks/MacMaskForFilters';
import MacMaskForFiltersWithoutMask from '../../components/TextFieldWithMAsk/Masks/MacMaskForFiltersWithoutMask';
import AsciiTextField from "../../components/AsciiTextField/AsciiTextField";
import {showErrorToast} from "../../utils/showErrorToast";

const NewDeviceDialog: React.FC<NewDeviceDialogProps> = ({
                                                             isOpen,
                                                             clickOnClose,
                                                         }) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [macAddressFocusOn, setMackAddressFocusOn] = useState(false);

    const {isCreating, isTypeListLoading, createdUAId, createApiError} = useSelector(
        (state: ReduxState) => state.devices,
    );

    const i_customer = useSelector(
        (state: ReduxState) => state.generic?.sessionData?.i_customer,
    );

    const isLoading: boolean = useMemo(
        () => isTypeListLoading || isCreating || false,
        [isTypeListLoading, isCreating],
    );

    const viewDetails = usePermissions(...Permission.Inventory.Devices.DeviceDetails.value);

    useEffect(() => {
        if (!isCreating && createdUAId && clickOnClose) {
            clickOnClose();
            if (viewDetails !== PermissionType.Hidden) {
                history.push(
                    getRoute(Routes.DevicesDetails, {
                        id: createdUAId,
                    }),
                );
            }
            showErrorToast(t<string>('screens:devices.deviceAdded'));
        }
    }, [isCreating, createdUAId]);

    const {
        ua_type_list
    } = useSelector((state: ReduxState) => state.devices);

    const initialValues: DeviceFormProps = useMemo(
        () => ({
            model: null,
            mac: MacAddressPlaceHolder,
            name: '',
            ascii_key: '',
            inventory_id: '',
            description: '',
        } as DeviceFormProps),
        [],
    );



    const {
        values,
        handleSubmit,
        setFieldValue,
        resetForm,
        errors,
        setFieldError,
        handleChange,
        dirty
    } = useFormik<DeviceFormProps>({
        initialValues,
        onSubmit: (form) => {
            const model = uaTypeToAddDeviceTransformer(form);
            dispatch(actions.addDevice.request(model));
        },
        validationSchema: addDeviceValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true
    });


    useEffect(() => {
        if (isOpen) {
            resetForm()
            dispatch(
                actions.deviceTypeList.request({
                    get_total: 1,
                    i_customer: i_customer
                })
            );
        }
    }, [isOpen, i_customer]);


    useEffect(() => {
        if (isOpen && ua_type_list?.length === 1 && !values.model) {
            const option = ua_type_list[0];
            setFieldValue('model', option);
        }
    }, [isOpen, ua_type_list, values.model]);

    const generateDeviceName = () => {
        const nw = (values.model?.manufacturer || values.model?.name || '') + ' ' + (values.mac || '');
        setFieldValue('name', nw);
    };

    const showGenerateButton: boolean = useMemo(
        () => (values.model?.i_ua_type || 0) > 0 && (values.mac?.length > 0) &&
            macAddressLengthFunc(values.mac) === macAddressLengthFuncForMask(MacAddressPlaceHolder),
        [values.model, values.mac],
    );

    useEffect(() => {
        if (!isCreating && createApiError) {
            switch (createApiError.faultcode) {
                case 'Server.UA.add_ua.mac_duplicate':
                    setFieldError('mac', t('errors:devices.macIsNotUnique'));
                    break;
                case 'Server.UA.internal_error':
                    if (createApiError.faultstring === "The 'name' field is not unique") {
                        setFieldError('name', t('errors:devices.deviceNameIsNotUnique'));
                    }
                    break;
            }
        }
    }, [createApiError, isCreating]);

    const isDisabled = (): boolean => {
        const isAsciiValid = !values.ascii_key?.length ||
            !![16, 32, 64].find((n: number) => n == values.ascii_key?.length);

        return !isAsciiValid || (
            !dirty ||
            !values.model ||
            !(values.mac?.length) ||
            !(values.name?.length) ||
            macAddressLengthFunc(values.mac) !== macAddressLengthFuncForMask(MacAddressPlaceHolder) ||
            isLoading)
    }
    return (
        <PermissionProvider permission={Permission.Inventory.Devices.value}>
            <DialogContainer
                className={classes.modalContainer}
                isOpen={isOpen}
                dataQa={'devices-add-new-modal'}
                header={t('screens:devices.addNewDevice')}
                headerClass={classes.header}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => {
                            clickOnClose?.();
                        }}
                    />,
                    <DialogButton
                        key="save"
                        label={t('common:save')}
                        disabled={
                            isDisabled()
                        }
                        onClick={handleSubmit}
                        dataQa={'add-new-device-save-button'}
                        primary
                    />,
                ]}
            >
                <form
                    className={classes.form}
                    autoComplete="off"
                    data-testid="add-new-device-form"
                >
                    <Grid className={classes.itemsContainer}>
                        <Box className={classes.boxRow}>
                            <SelectField
                                id="model"
                                label={t(
                                    'screens:devices.model',
                                )}
                                getOptionLabel={(value: UATypeInfo) => value?.name || ''}
                                onChange={(_, option: UATypeInfo) => {
                                    if (option) {
                                        setFieldValue('model', option);
                                    }
                                }}
                                items={ua_type_list || []}
                                value={values.model}
                                helperText={
                                    errors.model && errors.model.length > 0
                                        ? errors.model
                                        : ''
                                }
                                classes={{helperText: classes.error}}
                                setFieldError={setFieldError}
                                disableClearable
                                required
                                dataQa={'model-select-field'}
                                dataTestId={'model-select-field'}
                            />
                        </Box>

                        <Box className={classes.boxRow}>
                            <TextFieldWithMask
                                id="mac"
                                label={t('screens:devices.macAddress')}
                                value={values.mac}
                                helperText={
                                    errors.mac && errors.mac.length > 0
                                        ? errors.mac
                                        : ''
                                }
                                setFieldError={setFieldError}
                                placeholder={MacAddressPlaceHolder}
                                required
                                className={classNames(
                                    values.mac == initialValues.mac
                                        ? classes.initMac
                                        : '',
                                )}
                                onFocus={() => {
                                    if (values.mac == '') {
                                        setFieldValue(
                                            'mac',
                                            initialValues.mac
                                        );
                                    }
                                    setMackAddressFocusOn(true);
                                }}
                                onBlur={() => {
                                    if (values.mac == initialValues.mac) {
                                        setFieldValue(
                                            'mac',
                                            ''
                                        );
                                    }
                                    setMackAddressFocusOn(false);
                                }}
                                onChange={(e) => {
                                    setFieldValue(
                                        'mac',
                                        e.target.value
                                    );
                                }}
                                mask={
                                    macAddressFocusOn
                                        ? MacMaskForFilters
                                        : MacMaskForFiltersWithoutMask
                                }
                                autoComplete={"off"}
                                InputProps={{
                                    maxLength: MacAddressPlaceHolder.length,
                                    max: MacAddressPlaceHolder.length
                                }}
                                counterText={
                                    macAddressLengthFunc(values.mac) + '/' + macAddressLengthFuncForMask(MacAddressPlaceHolder)
                                }
                                dataQa={'mac-address'}
                            />
                        </Box>

                        <Box className={classes.boxRow}>
                            <CustomizedTextField
                                id="name"
                                label={t('screens:devices.deviceName')}
                                value={values.name}
                                helperText={
                                    errors.name && errors.name.length > 0
                                        ? errors.name
                                        : ''
                                }
                                setFieldError={setFieldError}
                                onChange={handleChange}
                                required
                                iconPosition="end"
                                maxLength={50}
                                icon={
                                    <IconWithTooltip
                                        dataQa="generate-device-name"
                                        tooltipText={t('tooltips:devices.generateName')}
                                        CustomIconSvg={GenerateText}
                                        className={classes.generateDeviceNameButton}
                                        onClick={generateDeviceName}
                                        disabled={!showGenerateButton}
                                    />
                                }
                                dataQa="devicename-field"
                            />
                        </Box>

                        <Box className={classes.boxRow}>
                            <AsciiTextField
                                id={'ascii_key'}
                                error={
                                    errors.ascii_key && errors.ascii_key.length > 0
                                        ? errors.ascii_key
                                        : ''}
                                setFieldError={setFieldError}
                                onChange={(e) => {
                                    setFieldValue(
                                        'ascii_key',
                                        formatAsciiAesAddress(e.target.value)
                                    );
                                }}
                                model={values?.model ?? undefined}
                                value={values.ascii_key}
                            />
                        </Box>

                        <Box className={classes.boxRow}>
                            <CustomizedTextField
                                id="inventory_id"
                                label={t('screens:devices.inventoryId')}
                                value={values.inventory_id}
                                helperText={
                                    errors.inventory_id && errors.inventory_id.length > 0
                                        ? errors.inventory_id
                                        : ''
                                }
                                setFieldError={setFieldError}
                                onChange={handleChange}
                                maxLength={64}
                                dataQa="inventory-id-field"
                            />
                        </Box>

                        <Box className={classes.boxRow}>
                            <CustomizedTextField
                                id="description"
                                label={t('screens:devices.description')}
                                value={values.description}
                                helperText={
                                    errors.description && errors.description.length > 0
                                        ? errors.description
                                        : ''
                                }
                                setFieldError={setFieldError}
                                onChange={handleChange}
                                maxLength={255}
                                dataQa="description-field"
                            />
                        </Box>
                    </Grid>
                </form>
                {isOpen && isLoading && (
                    <Loader dataQa={'wait-for-data'} absolutePosition/>
                )}
            </DialogContainer>
        </PermissionProvider>
    );
};

export default NewDeviceDialog;
