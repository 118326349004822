import React from 'react';
import {makeStyles} from '@material-ui/core';
import {ReactComponent as NoPermission} from '../../assets/no-permission.svg';
import {Colors} from '../../styles/Colors';
import {useTranslation} from 'react-i18next';


export const useStyles = makeStyles(() => ({
    root: {

        width: '90%',
        flexDirection: 'column',
        height: '90%',
        display: 'flex',
        margin: "auto",
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
        marginBottom: '20px',
        backgroundColor: Colors.SmokeBackground,


    },


    content: {
        width: '100%',
        height: '100%',
        transformOrigin: 'top left',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },

    scaleWrapper: {
        transform: 'scale(1)',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },

    title: {
        fontSize: '2.5vw',
        fontWeight: 500,
        color: Colors.Text,
    },
    description: {
        fontWeight: 400,
        fontSize: '1vw',

        color: Colors.Gray5,
    },

    svg: {
        width: '20vw',
        height: 'auto',
    }
}));

const AccessDeniedForWidget: React.VFC = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.scaleWrapper}>
                    <NoPermission className={classes.svg}/>
                    <h2 className={classes.title}>{t('common:accessDenied')}</h2>
                    <span className={classes.description}>
                        {t('common:noPermissionToPage')}
                    </span>
                </div>

            </div>
        </div>
    );
};

export default AccessDeniedForWidget;
