import SimplePaymentTaxes from "../../../../components/Forms/Billing/PaymentAmountForm/SimplePaymentTaxes";
import IconWithTooltip from "../../../../components/Tooltip/IconWithTooltip";
import React from "react";
import {useTranslation} from "react-i18next";

const WithTax:React.VFC<{visible:boolean, text:string, classContainer?:string, textContainer?:string}> = ({visible, classContainer, textContainer, text})=>{

    const {t} = useTranslation()

    return (
        <div className={classContainer}>
            {visible && (
                <div className={textContainer}
                     data-testid={'simple-payment-taxes-container'}>
                    <SimplePaymentTaxes taxesVisible amount={text}/>
                    <IconWithTooltip
                        tooltipText={t(
                            'tooltips:payment.withTax',
                        )}
                    />
                </div>)
            }
        </div>
    )
}

export default WithTax