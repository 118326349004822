import { api } from './axios';
import { ExtensionType } from '../types/Extension';
import { CPConditionInfo, CPRuleInfo } from '../types/CallScreening';
import { Account } from '../../services/endpoints';
import JSONFormData from '../../utils/JSONFormData';

export async function postUpdateAccount(
    sessionId: string,
    csrf_token: string,
    data: Partial<ExtensionType>,
) {
    const body = new JSONFormData(sessionId, csrf_token);
    body.setParams({ account_info: { ...data } });

    return api.post(Account.UpdateAccount, body);
}

const account = {
    postUpdate: async function (
        sessionId: string,
        csrf_token: string,
        data: Partial<ExtensionType>,
    ) {
        const body = new JSONFormData(sessionId, csrf_token);
        body.setParams({ account_info: { ...data } });

        return api.post(Account.UpdateAccount, body);
    },
    postUpdateAccount: async function (
        sessionId: string,
        csrf_token: string,
        data: Partial<ExtensionType>,
    ) {
        const body = new JSONFormData(sessionId, csrf_token);
        body.setParams({ account_info: { ...data } });

        return api.post(Account.UpdateAccount, body);
    },
    postAddCpRule: async function (sessionId: string, csrf_token: string, data: CPRuleInfo) {
        const body = new JSONFormData(sessionId, csrf_token);
        body.setParams({ cp_rule_info: { ...data } });

        return api.post(Account.AddCpRule, body);
    },

    postDeleteCpRule: async function (sessionId: string, csrf_token: string, data: CPRuleInfo) {
        const body = new JSONFormData(sessionId, csrf_token);
        body.setParams({ cp_rule_info: { ...data } });

        return api.post(Account.DeleteCpRule, body);
    },

    postUpdateCpRule: async function (sessionId: string, csrf_token: string, data: CPRuleInfo) {
        const body = new JSONFormData(sessionId, csrf_token);
        body.setParams({ cp_rule_info: { ...data } });

        return api.post(Account.UpdateCpRule, body);
    },
};

export default account;
