import RingGroupsSection from "../Dashboard/RingGroupsSection";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {Permission} from "../../store/types/Permission";
import {SearchCallback} from "../../views/CallHistory/CallHistory.utils";
import { useMemo } from "react";
import Config from '../../config.json';

const RingGroupsTab: React.VFC<SearchCallback>  = ({callback}) => {

    const {t} = useTranslation()

    const hasWebSocket = useMemo(() => {
        if(!Config.WEBSOCKET_SERVER_URL?.length) {
            return false;
        }
        return true;
    }, [Config]);
    
    return(
        <div style={{maxWidth:1024}}>
                <RingGroupsSection
                    showTitle={false}
                    customThNames={[t('screens:ringGroups.ringGroups')]}
                    showRefresh={!hasWebSocket}
                    useInternalLoader={false}
                    customPermission={Permission.SupervisorPanel.RingGroups.value}
                    callback={callback}
                />
        </div>

    )

}

export default RingGroupsTab