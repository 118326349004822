import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../styles/Colors";
import {ActiveCall} from "../../store/types/CallHistory";
import i18next from "i18next";
import {SipCall} from "../../store/actions/ringgroups/payloads";

export const mockCalls = [
    {
        "service_type_name": "VOICE",
        "duration": 481,
        "extension_name": "Autodialer",
        "i_account": 78594,
        "customer_name": "Pepkor Group",
        "cli": "380661111111",
        "original_cli": "380661111111",
        "call_type": "forwarded",
        "call_id": "kKqp1DkVR7Hd1JMX2QaUpecL~1o",
        "extension_id": "555",
        "h323_conf_id": "C2D36523 CD17BE16 9D65EB60 DD8FF1F8",
        "account_id": "ph7031aa2",
        "i_customer": 7031,
        "i_service_type": 3,
        "original_cld": "X2000",
        "cld": "X2000",
        "hotlined": 0,
        "setup_time": "2023-09-15 10:44:13",
        "connect_time": "2023-09-15 10:44:13",
        "is_disconnectable": 1
    },
    {
        "cli": "ph7031x1000",
        "customer_name": "Pepkor Group",
        "original_cli": "ph7031x1000",
        "extension_name": "Mitchell",
        "i_account": 74012,
        "duration": 464,
        "service_type_name": "VOICE",
        "h323_conf_id": "7BD755EC 0E3B8964 5ADC497E F008C1B2",
        "extension_id": "1000",
        "call_type": "outgoing",
        "call_id": "TqE7JK2sf_uSP0bji7qJRg..",
        "i_service_type": 3,
        "original_cld": "ph7031x1002",
        "source_ip": "37.57.95.190",
        "i_customer": 7031,
        "account_id": "ph7031x1000",
        "connect_time": "2023-09-15 10:44:30",
        "is_disconnectable": 1,
        "hotlined": 0,
        "setup_time": "2023-09-15 10:44:28",
        "cld": "ph7031x1002"
    },
    {
        "i_service_type": 3,
        "original_cld": "ph7031x1002",
        "i_customer": 7031,
        "account_id": "ph7031x1002",
        "connect_time": "2023-09-15 10:44:30",
        "is_disconnectable": 1,
        "setup_time": "2023-09-15 10:44:28",
        "hotlined": 0,
        "cld": "ph7031x1002",
        "customer_name": "Pepkor Group",
        "cli": "ph7031x1000",
        "original_cli": "ph7031x1000",
        "extension_name": "Mike Spencer",
        "i_account": 78690,
        "service_type_name": "VOICE",
        "duration": 464,
        "h323_conf_id": "F8A83E6E C17BF612 409D316C 409D316C",
        "extension_id": "1002",
        "call_type": "incoming",
        "call_id": "TqE7JK2sf_uSP0bji7qJRg.."
    },
    {
        "hotlined": 0,
        "setup_time": "2023-09-15 10:44:13",
        "cld": "ph7031x1000",
        "connect_time": "2023-09-15 10:44:16",
        "is_disconnectable": 1,
        "i_customer": 7031,
        "account_id": "ph7031x1000",
        "i_service_type": 3,
        "original_cld": "ph7031x1000",
        "call_type": "incoming",
        "call_id": "kKqp1DkVR7Hd1JMX2QaUpecL~1o",
        "h323_conf_id": "73C2E6F0 3BD07080 9FE59329 9FE59329",
        "extension_id": "1000",
        "extension_name": "Mitchell",
        "i_account": 74012,
        "duration": 478,
        "service_type_name": "VOICE",
        "customer_name": "Pepkor Group",
        "cli": "380661111111",
        "original_cli": "380661111111"
    },
    {
        "h323_conf_id": "26AF5D9A FB333704 670168EC 670168EC",
        "call_type": "forwarded",
        "call_id": "kKqp1DkVR7Hd1JMX2QaUpecL",
        "cli": "380445550011",
        "customer_name": "Pepkor Group",
        "original_cli": "380445550011",
        "i_account": 78594,
        "duration": 482,
        "service_type_name": "VOICE",
        "connect_time": "2023-09-15 10:44:12",
        "is_disconnectable": 1,
        "hotlined": 0,
        "setup_time": "2023-09-15 10:44:12",
        "cld": "ph7031aa2",
        "i_service_type": 3,
        "source_ip": "217.182.47.207",
        "original_cld": "ph7031aa2",
        "i_customer": 7031,
        "account_id": "27100000003"
    }
]
export const useStyles = makeStyles(() => ({
    tooltipContainer: {
        display: 'flex',
        flexDirection: 'column',
        color: Colors.TooltipTextColor,
        fontSize: 14,
        lineHeight: '24px',
        fontWeight: 400,
        justifyContent: 'space-between',
        width: 204,
        padding: '15px 24px 20px 24px',
        borderRadius: '4px'
    },
    tooltipHeader: {
        fontWeight: 700,
        paddingBottom: 6,
        borderBottom: '1px solid #F5F7F8',
        marginBottom: 9
    },

    tooltipContent: {
        display: 'flex',
        flexDirection: 'column',
    },

    tooltipContentRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    tooltipFooter: {
        display: 'flex',
        flexDirection: 'column',
        borderTop: '1px solid #F5F7F8',
        opacity: .6
    }
}))

enum CallsTypeColorMap {
    forwarded = Colors.Secondary1,
    outgoing = Colors.Support,
    incoming = Colors.Primary
}

enum CallsValueMap {
    incoming = 'incomingCount',
    outgoing = 'outgoingCount',
    forwarded = 'forwardedCount'
}

export const buildCallsArray = (calls: ActiveCall[] | SipCall[], skipType? : string[] ) => {
    const callsData: any[] = [];
    let callsKeys: string[] = [];

    const defMain = [
        'forwarded', 'outgoing', 'incoming'
    ];

    const main = !skipType ?defMain : defMain.filter(el => !skipType.includes(el))

    // @ts-ignore
    calls.forEach(item => callsKeys.push(item?.call_type || item?.type || ''));

    if(skipType)
    {
        callsKeys =  callsKeys.filter(el => !skipType.includes(el))
    }

    const helperFunc = (arr: Array<string>) => {
        return arr.reduce((counts, value) => {
            // @ts-ignore
            counts[value] = (counts[value] || 0) + 1;
            return counts;
        }, {});
    }

    const callsLength = Object.keys(callsKeys).length

    const temp = helperFunc(callsKeys);

    Object.keys(helperFunc((main)))
        .forEach((value, key) => {

            // @ts-ignore
            const translation = i18next.t<string>('screens:wallboard.' + CallsValueMap[value]);

            callsData.push(
                {
                    label: translation,
                    // @ts-ignore
                    value: temp[value] ?? 0,
                    // @ts-ignore
                    color: CallsTypeColorMap[value]
                }
            )
        })

    return {
        items: callsData,
        total: callsLength,
    }

}