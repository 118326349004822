import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../../styles/Colors';
import {ExtensionPlanFormType} from '../../../components/Forms/Extensions/PlanForm.utils';
import {SipFormType} from '../../../components/Extensions/Sip';
import {ExtensionFormType} from '../../../components/Forms/Extensions/Extension/ExtensionForm.utils';
import {ExtensionCallSettingsFormType} from '../../../components/Forms/Extensions/CallSettings/CallSettingsForm.utils';
import {
    ExtensionCallForwardingFormType
} from '../../../components/Forms/Extensions/CallForwarding/CallForwardingForm.utils';
import {CallScreeningFormType} from '../../../components/Forms/Extensions/CallScreening/CallScreeningForm.utils';

export type EditExtensionForm = ExtensionFormType &
    ExtensionCallSettingsFormType &
    CallScreeningFormType &
    ExtensionPlanFormType &
    ExtensionCallForwardingFormType &
    SipFormType;

export type ChangeDirtyStateDetailsTab = (
    funcName: string,
    isDirty: boolean,
) => void;

export const useTabStyles = makeStyles(() => ({
    loader: {
        height: 500,
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        overflowY: 'hidden',
        backgroundColor: Colors.SmokeBackground,
        position: 'relative',

        '& .MuiButton-label': {
            color: Colors.White,
            paddingRight: 25,
            paddingLeft: 25,
        },
        '& .MuiBox-root': {
            overflow: 'visible',
        },
    },
    extensionHeader: {
        padding: '0 90px 0 90px',
        height: 140,
        backgroundColor: Colors.Gray2,
        display: 'flex',
        flexDirection: 'column',
    },

    extensionTop: {
        paddingTop: 54,
        display: 'flex',
        justifyContent: 'space-between',
    },

    extensionStatus: {
        display: 'flex',
        fontSize: 22,
        weight: 400,

        '& > div': {
            marginRight: 40,
        },
        '& > div:last-child': {
            marginRight: 0,
        },
    },

    scrollable: {
        overflowY: 'auto',
        flex: 1,
    },

    extensionBottom: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'end',
    },
    loader: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    actionsPanel: {
        maxWidth: 992,
    },

    '@media (max-width: 1276px)': {
        actionsPanel: {
            left: 41,
            width: 'calc(100% - 146px)'
        },
    },
    '@media (min-width:1277px) and (max-width: 1440px)': {
        actionsPanel: {
            left: 107,
            width: 'calc(100% - 278px)'
        },
    },
    '@media (min-width: 1441px)': {
        actionsPanel: {
            left: 113,
            maxWidth: 992,
            width: 'calc(100% - 290px)'
        },
    },
}));
export type DetailsProps = {
    id: string;
    actionButtonVisible?: boolean;
};

export interface ExtensionDetailsPayload {
    init: Partial<EditExtensionForm>;
    updated: Partial<EditExtensionForm>;
}