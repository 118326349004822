import React, { useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import TextField from '../../components/TextField/TextField';
import DialogContainer, {
    DialogButton,
} from '../../components/AlertDialog/DialogContainer';
import Loader from '../../components/Loader/Loader';

import { Colors } from '../../styles/Colors';
import * as Yup from 'yup';
import i18n from '../../services/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../store';
import { ReduxState } from '../../store/types';
import { PhoneBookListItem } from '../../store/reducers/phoneBook/reducer';
import camelCase from 'camelcase';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import { Permission } from '../../store/types/Permission';
import IconWithTooltip from '../../components/Tooltip/IconWithTooltip';

const EntryValidationSchema = Yup.object().shape({
    speedDial: Yup.string()
        .required(i18n.t<string>('errors:common.emptyInput'))
        .matches(
            /^[a-dA-D0-9*#]+$/,
            i18n.t<string>('errors:phoneBook.speedDialFormat'),
        )
        .max(15, i18n.t<string>('errors:phoneBook.toLong')),
    number: Yup.string()
        .required(i18n.t<string>('errors:common.emptyInput'))
        .matches(/^[0-9*#]+$/, i18n.t<string>('errors:phoneBook.number')),
});

export interface PhoneBookListItemExtended extends PhoneBookListItem {
    id: number;
    actions?: string;
}

export interface EntryModalFormProps {
    speedDial: string | number;
    number: string;
    description: string;
}

export interface EntryModalProps {
    isOpen: boolean;
    editedRow: PhoneBookListItemExtended | null;
    toggleVisibility?: (isSaved: boolean) => void;
    isSupervisor?:boolean
}

const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '0px 25px',
        margin: '22px 30px 20px 30px',
        display: 'flex',
        flexDirection: 'column',

        '& .MuiFormControl-root': {
            flex: 1,
            height: 'auto',
            marginTop: 25,
        },

        '& > :first-child': {
            marginTop: 0,
        },

        '& .MuiInputBase-root': {
            width: 472,
        },
    },
    visibilityIcon: {
        marginRight: 6,
    },

    primaryActionButton: {
        width: 96,
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },

    saveButton: {
        width: 96,
        backgroundColor: Colors.Primary,
        '& .MuiButton-label': {
            paddingLeft: 10,
            paddingRight: 10,
            color: Colors.White,
        },
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.88,
            backgroundColor: Colors.Primary,
        },
    },

    disabledActionButton: {
        backgroundColor: Colors.Gray8,
        width: 96,
        '& .MuiButton-label': {
            color: Colors.Gray10 + '!important',
            paddingLeft: 10,
            paddingRight: 10,
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },
    },
    modalContainer: {
        '& .MuiDialog-paperWidthSm': {
            width: 640,
        },
        '& .MuiDialogContent-root': {
            backgroundColor: Colors.SmokeBackground,
        },
    },
    wait: {
        position: 'absolute',
        display: 'flex',
        top: '50%',
        left: '50%',
        zIndex: 100,
    },
}));

const EntryModal: React.VFC<EntryModalProps> = ({
    isOpen,
    editedRow,
    toggleVisibility,
    isSupervisor
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { savingPhoneBook, errorsApi } = useSelector(
        (state: ReduxState) => state.phoneBook,
    );

    const initialValues = {
        speedDial: editedRow?.abbreviated_number || '',
        number: editedRow?.number_to_dial || '',
        description: editedRow?.description || '',
    };

    const onSuccessfullyAdded = () => {
        toggleVisibility?.(true);
    };

    useEffect(() => {
        if (isOpen) {
            resetForm();
        }
    }, [isOpen]);

    const {
        values,
        handleSubmit,
        handleChange,
        errors,
        resetForm,
        isValid,
        setFieldError,
        dirty,
    } = useFormik<EntryModalFormProps>({
        initialValues,
        onSubmit: () => {
            dispatch(
                actions.addNewPhoneBook.request({
                    abbreviated_number: values?.speedDial.toString(),
                    number_to_dial: values.number,
                    description: values.description,
                    isEdited: editedRow !== null,
                    i_ab_dialing: editedRow?.id,
                    callback: onSuccessfullyAdded,
                }),
            );
        },
        enableReinitialize: true,
        validationSchema: EntryValidationSchema,
        validateOnChange: false,
        validateOnBlur: true,
    });

    const disabledSaveButton = () => {
        return (
            dirty &&
            values.speedDial &&
            values.number &&
            (values.speedDial.toString() !== editedRow?.id?.toString() ||
                values.number.toString() !==
                    editedRow?.number_to_dial?.toString() ||
                values.description !== editedRow?.description)
        );
    };

    const speedDialError = () => {
        let camelFaultCode = '';
        let error = errors?.speedDial || '';

        if (errorsApi?.faultcode !== undefined) {
            camelFaultCode = camelCase(errorsApi.faultcode!);
            error = i18n.exists('errors:phoneBook.' + camelFaultCode)
                ? t('errors:phoneBook.' + camelFaultCode)
                : t('common:undefinedError');
        }

        return error;
    };

    useEffect(() => {
        if (
            errorsApi?.faultcode &&
            i18n.exists('errors:phoneBook.' + camelCase(errorsApi.faultcode))
        ) {
            setFieldError('speedDial', speedDialError());
        }
    }, [errorsApi]);

    return (
        <PermissionProvider permission={Permission.CloudPBX.PhoneBook[editedRow ? 'PhoneBookEntryDetails' : 'AddNewPhoneBookEntry'].value}>
            <DialogContainer
                isOpen={isOpen}
                dataQa="add-edit-entry-dialog"
                header={
                    editedRow
                        ? t('screens:phoneBook.editPhoneBookEntry')
                        : t('screens:phoneBook.addNewEntry')
                }
                headerClass={classes.header}
                className={classes.modalContainer}
                dialogActionsButtons={[
                    <DialogButton
                        className={classes.primaryActionButton}
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => {
                            toggleVisibility?.(false);
                        }}
                    />,
                    <DialogButton
                        primary
                        key="save"
                        label={t('common:save')}
                        onClick={handleSubmit}
                        disabled={!disabledSaveButton() || !isValid}
                    />,
                ]}
            >
                {savingPhoneBook && (
                    <Loader dataQa={'sip-ingo-loader'} absolutePosition />
                )}
                <form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    data-testid="EntryModal-form"
                >
                    <Grid item className={classes.itemsContainer}>
                        <TextField
                            id="speedDial"
                            label={t('screens:phoneBook.speedDial')}
                            onChange={handleChange}
                            iconPosition="end"
                            dataQa="entry-modal-speed-dial"
                            required
                            value={values.speedDial}
                            helperText={errors.speedDial}
                            setFieldError={setFieldError}
                            maxLength={15}
                            icon={
                                <IconWithTooltip
                                    dataQa="phone-book-speed-dial-tooltip"
                                    tooltipText={t('tooltips:phoneBook.speedDial')}
                                    copy={false}
                                />
                            }
                            readOnly={isSupervisor}
                        />

                        <TextField
                            id="number"
                            label={t('common:number')}
                            onChange={handleChange}
                            dataQa="entry-modal-number"
                            required
                            iconPosition="end"
                            value={values.number}
                            helperText={errors.number}
                            setFieldError={setFieldError}
                            maxLength={64}
                            icon={
                                <IconWithTooltip
                                    dataQa="phone-book-number-tooltip"
                                    tooltipText={t('tooltips:phoneBook.number')}
                                    copy={false}
                                />
                            }
                            readOnly={isSupervisor}

                        />

                        <TextField
                            id="description"
                            label={t('common:description')}
                            onChange={handleChange}
                            dataQa="entry-modal-description"
                            value={values.description}
                            maxLength={64}
                            iconPosition="end"
                            icon={
                                <IconWithTooltip
                                    dataQa="phone-book-description-tooltip"
                                    tooltipText={t('tooltips:phoneBook.description')}
                                    copy={false}
                                />
                            }
                            readOnly={isSupervisor}

                        />
                    </Grid>
                </form>
            </DialogContainer>
        </PermissionProvider>
    );
};

export default EntryModal;
