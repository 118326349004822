import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../../..';
import { AssignedPortInfo } from '../../../types/Devices';

export interface DeviceReducerState {
    device?: {
        items?: AssignedPortInfo[];
        isLoading: boolean;
    };
}

export const initialState: DeviceReducerState = {};

export type DeviceReducerActions = ActionType<typeof actions>;

export const deviceReducer = createReducer<
    DeviceReducerState,
    DeviceReducerActions
>(initialState)
    .handleAction(actions.fetchDeviceByIAccount.request, (state) => ({
        ...state,
        device: { 
            items: undefined,
            isLoading: true
        },
    }))
    .handleAction(actions.fetchDeviceByIAccount.success, (state, action) => ({
        ...state,
        device: { 
            items: action.payload.items,
            isLoading: false
        },
    }))
    .handleAction(actions.fetchDeviceByIAccount.failure, (state) => ({
        ...state,
        device: {
            items: undefined,
            isLoading: false
        },
    }));

export default deviceReducer;
