import {useSelector} from "react-redux";
import {ReduxState} from "../store/types";
import {ConfigData} from "../store/types/ConfigData";
import {useMemo} from "react";
import {IndividualType} from "../store/types/CustomerInfo";


export const usePortalUserData = (i_individual?: IndividualType | string) => {


    const {roleList} = useSelector((state: ReduxState) => state.company);

    const {timeZonesList, languagesList} = useSelector(
        (state: ReduxState) => state.generic,
    );

    const config = useSelector<ReduxState, ConfigData | undefined>(
        (state) => state.generic?.configData,
    );

    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const userDateFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_date_format ||
            '',
    );

    const saveClicked = useSelector(
        (state: ReduxState) => state.tabs?.saveForms || false,
    );

    const {isLoading, editedPortlUser, isFormSaving, apiErrors} = useSelector(
        (state: ReduxState) => state.company,
    );


    let initialValues = useMemo(
        () => ({
            login: editedPortlUser?.customer_individual_info.login || '',
            password: editedPortlUser?.customer_individual_info.password || '',
            role:
                roleList?.find(
                    (e) =>
                        e.i_role ===
                        editedPortlUser?.customer_individual_info.i_role,
                )?.name || '',
            email: editedPortlUser?.customer_individual_info.email || '',
            activationDate:
                editedPortlUser?.customer_individual_info.activation_date ||
                null,
            expirationDate:
                editedPortlUser?.customer_individual_info.expiration_date ||
                null,
            timezone:
                timeZonesList.find(
                    (e) =>
                        e.i_time_zone ===
                        editedPortlUser?.customer_individual_info.i_time_zone,
                )?.time_zone_name || '',
            language:
                languagesList?.find(
                    (e) =>
                        e.iso_639_1 ===
                        editedPortlUser?.customer_individual_info.lang,
                )?.name || '',
            ipAddresses: editedPortlUser?.customer_individual_info
                .login_allowed_ip_list
                ? editedPortlUser.customer_individual_info.login_allowed_ip_list.map(
                    (e, index) => {
                        return {
                            isValueRequired: !!e.ip,
                            id: index,
                            value: e.ip,
                        };
                    },
                )
                : [
                    {
                        id: 0,
                        value: '',
                    },
                ],
            extension: editedPortlUser?.customer_individual_info?.type == 'hunt_group_supervisor' ? editedPortlUser.customer_individual_info.extensionDetails : null,
            isCurrentLoginUser: i_individual && i_individual === editedPortlUser?.customer_individual_info?.i_individual
        }),
        [editedPortlUser, timeZonesList],
    );


    if (isLoading) {
        initialValues = {

            login: '',
            password: '',
            role: '',
            email: '',
            activationDate: null,
            expirationDate: null,
            timezone: '',
            language: '',
            ipAddresses: [
                {
                    id: 0,
                    value: '',
                },
            ],
            extension: null,
            isCurrentLoginUser: false
        }
    }


    return {
        timezoneOffset,
        timeZonesList,
        languagesList,
        roleList,
        config,
        userDateFormat,
        saveClicked,
        isLoading,
        editedPortlUser, isFormSaving, apiErrors,
        svTypeSelected: editedPortlUser?.customer_individual_info.type == 'hunt_group_supervisor' || false,
        initialValues
    }
}