import dayjs from "../../services/customDayJs";
import { Colors } from "../../styles/Colors";
import { makeStyles } from '@material-ui/core/styles';

export type AudioPlayerProps = {
    onClose?: (playingFinished: boolean) => void;
    customClasses?: {
        rootContainer?: string
    },
    dataQa?: string;
    dataTestId?: string;
    visible: boolean,
    downloadTranscriptionPermission?: string[];
};

export const useStyles = makeStyles(() => ({
    rootContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: Colors.White,
        margin: '0 !important',
        padding: '0 16px',
        height: 64,
        alignItems: 'center',
        maxWidth: 1008,
        position: 'absolute',
        top: 'calc(100vh - 82px)',
        left: 90,
        zIndex: 10,
        right: 90,
        borderRadius: 8,
        boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.25)`,
    },

    closeButton: {
        marginRight: 16,
        width: 86,
        '& .MuiButton-label': {
            color: Colors.Primary + '!important',
        },
        '&:hover': {
            backgroundColor: Colors.Primary1 + '!important',
        },
    },

    playerContainer: {
        width: 0,
        height: 0,
        display: 'flex',
        visibility: 'hidden',
        
        '& :first-child': {
            width: '0 !important',
            height: '0 !important',
        }
    },

    playNextPrevButtonContainer: {
        marginTop: 2,
    },

    playNextPrevButton: {
        color: Colors.Gray5,
        height: 24,
        width: 24,
    },

    playNextPrevButtonDisabled: {
        color: `${Colors.Gray10}!important`,
        
        '& svg': {
            '& path': {
                fill: `${Colors.Gray10} !important`,
                fillOpacity: 1,
            },
        },
    },

    playPauseButtonBase: {
        height: 33.3,
        width: 33.3,
        
        '& svg': {
            height: 33.3,
            width: 33.3,
            '& path': {
                height: 33.3,
                width: 33.3,
            },
        },
    },

    playPauseButtonLoading: {
        color: Colors.Primary2,
        height: '28px !important',
        width: '28px !important',

        '& .MuiCircularProgress-svg': {
            color: Colors.Primary2
        },
    },

    playPauseButtonPause: {
        color: Colors.Primary2
    },

    durationLabelBase: {
        color: Colors.Text3,
        fontSize: 14,
        width: 56,
        fontWeight: 400
    },

    durationLabelLeft: {
        textAlign: 'right'
    },

    durationLabelRight: {
        textAlign: 'left',
        marginLeft: 4
    },

    sliderContainer: {
        height: '100%',
        width: 'calc(100% - 540px)',
        display: 'flex',
        padding: 12
    },

    additionalTextContainer: {
        width: 172,
        fontSize: 13,
        color: Colors.ListIconColor,
        marginLeft: 40,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },

    volumeContainer: {
        marginTop: 2,
    },

    popupVolumeContainer: {
        background: Colors.White,
        borderRadius: 4,
        border: '1px solid '+Colors.Border,
        padding: '24px 6px 24px 22px',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.25)',
        width: 20,
        height: 100,

        '& .MuiSlider-root': {
            padding: '0 !important',
            width: '20px !important',
            height: 100,
        },
    },

    playButtonContainer: {
        width: 44,
        height: 44
    },

    playButtonContainerPadding: {
        padding: '16px 8px 0px 8px',
        width: '44px !important',
        height: '44px !important',
        display: 'flex',
        flex: 1,
    },
    
    playButtonContainerPaddingNotDownloading: {
        padding: '10px 5.35px 0px 5.35px',
        width: '33.3px !important',
        height: '33.3x !important',
        display: 'flex',
        flex: 1,
    },

    progressSlider: {
        color: Colors.Primary2 + ' !important',

        '& .MuiSlider-thumb': {
            border: '1px solid white',
        },

        '& .MuiSlider-root': {
            color: Colors.Primary2 + ' !important',
        }
    },

    volumeSlider: {
        '& .MuiSlider-thumb': {
            border: '1px solid white',
        }
    }
}));

export const formatDuration = (seconds: number, defaultValue = '0:00') => {
    if(!seconds) {
        return defaultValue;
    }
    const duration = dayjs.duration(seconds, 'seconds');
    let format = 'HH:mm:ss';

    if (seconds < 60) {
        format = '0:ss';
    } else if (seconds >= 60 && seconds < 3600) {
        format = 'mm:ss';
    }

    const formatted = duration.format(format);
    if(formatted.startsWith('00:'))
        return formatted.replace('00:', '0:');
    else if (formatted.startsWith('0') && !formatted.startsWith('0:'))
        return formatted.substring(1);
    else 
        return formatted;
};

export const getVolumeInitialValue = () => {
    const v = localStorage.getItem('audio_volume') || '1.00';
    return parseFloat(v);
};