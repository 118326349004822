import React, {useCallback, useEffect, useState} from "react";
import {TFunction, useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import {useFormik} from "formik";
import AsyncSelectField from "../../AsyncSelectField/AsyncSelectField";
import JSONFormData from "../../../utils/JSONFormData";
import {AxiosResponse} from "axios";
import {api} from "../../../store/services/axios";
import {Customer} from "../../../services/endpoints";
import {
    GetHuntGroupListRequest,
    GetHuntGroupListResponse
} from "../../../store/actions/ringgroups/payloads";
import {useSelector} from "react-redux";
import {ReduxState} from "../../../store/types";

import {
    AssignedExtension,
    AssignedExtensionForMultipleSelectInterface,
    RingGroupListInterface,
    RingGroupType
} from "../../../store/types/RingGroup";

import CustomButton from "../../Button/Button";
import SelectField from "../../SelectField/SelectField";
import classNames from "classnames";
import {DropDownDictionaryItem} from "../../../views/CallHistory/ActiveCalls/ActiveCalls.utils";
import {Colors} from "../../../styles/Colors";
import OverflowTooltip from "../../OverflowTooltip/OverflowTooltip";


const useStyles = makeStyles(() => ({
    multiSelectContainer: {
        minWidth: 'unset',
        flex: 'unset',
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input': {
            padding: '0!important',
            flexGrow: 'unset',
            minWidth: 'unset',
        }
    },

    button: {
        marginLeft: 16
    },

    multiSelectField: {
        minHeight: 56,

        '& .MuiAutocomplete-inputRoot': {
            height: 56,
        }
    },

    multiSelectFieldOverflowText: {
        flex: 1,
        marginLeft: 12,
        maxWidth: 160,
    },
    filtersContainer: {
        marginTop: 10,
        marginBottom: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    extraMargin: {
        marginRight: 16
    },
    select: {
        maxWidth: 240,
        width: 240,

        '& .multiple-input-select__control, & .MuiFormControl-fullWidth': {
            maxHeight: 56
        },

        '& .multiple-input-select__value-container .multiple-input-select__single-value': {
            marginLeft: -5
        },

        '& .multiple-input-select__placeholder': {
            fontSize: 16,
            marginTop: 20,
            fontWeight: 400,
            position: 'unset !important',
            marginLeft: 0,
            color: Colors.Text,
        },
    }
}))

export interface AgentListFilters {
    isInit?: boolean
    page?: number;
    pageSize?: number;
    ringGroupFilter?: RingGroupType;
    agentsFilter?: AssignedExtensionForMultipleSelectInterface[];
}

export interface AgentListFormFiltersType {
    ringGroupFilter?: RingGroupType;
    agentsFilter: AssignedExtensionForMultipleSelectInterface[];
}

interface FilterSComponentInterface {
    handleSearch: (
        payload: AgentListFilters
    ) => void;
    agentsList?: AssignedExtension[],
    sourceAgentList?: AssignedExtension[],
    setFilter?: (data: AgentListFilters) => void

}

const FiltersComponent: React.VFC<FilterSComponentInterface> = (
    {
        handleSearch,
        agentsList,
        sourceAgentList,
        setFilter
    }) => {

    const {t} = useTranslation();
    const classes = useStyles();
    const emptyValueForAny = {label: t('common:any'), value: undefined, id: -1}

    const agentDef = {
        name: t('common:All'),
        value: undefined as number | undefined,
        id: -1
    };

    const {session_id, csrf_token} = useSelector((state: ReduxState) => state.auth);
    const [inputValue, onInputChangeRaw] = useState<string>('');
    const [useSourceAgentList, setUseSourceAgentList] = useState(false)
    const [agents, setAgents]
        // @ts-ignore
        = useState<AssignedExtensionForMultipleSelectInterface[]>([agentDef])


    useEffect(() => {

        if (!useSourceAgentList) {
            const data = agentsList ? [...agentsList].map(item => ({
                ...item,
                value: item.id
            })) : []

            // @ts-ignore
            setAgents([agentDef, ...data])
        }

    }, [agentsList]);

    const onInputChange = (newInputValue: string) => {
        onInputChangeRaw(newInputValue);
    };

    const {
        values,
        handleSubmit,
        setFieldValue,
    } = useFormik<AgentListFormFiltersType>({
        initialValues: {
            // @ts-ignore
            agentsFilter: [agentDef],
            // @ts-ignore
            ringGroupFilter: emptyValueForAny
        },
        onSubmit: (form) => {


            const agents = form.agentsFilter.filter(item => item.value != undefined)
            const payload = {
                page: 0,
                pageSize: 50,
                agentsFilter: agents.length ? agents : undefined,
                // @ts-ignore
                ringGroupFilter: form.ringGroupFilter?.id == -1 ? undefined : form.ringGroupFilter
            }

            handleSearch(payload)
        }
    });


    const setTempAgents = useCallback(() => {
        const data = sourceAgentList?.map(item => ({...item, value: item.id})) || []
        setUseSourceAgentList(true)
        // @ts-ignore
        setAgents([agentDef, ...data])
    }, [values])

    const mapToName = (
        number: RingGroupType,
        t: TFunction<string>,
    ): JSX.Element => {

        return <>{number.id.toString() + ' - ' + number.name ?? t('screens:didNumbers.undefind')}</>
    };

    const addLabel = (item: RingGroupType): RingGroupListInterface => {
        return {
            ...item,
            cache_assigned_extensions: item.assigned_extensions,
            label: mapToName(item, t),
            value: item.id
        };
    };

    let annyAdded = false;


    const loadOptions = async (search: string, prevOptions: unknown[]) => {
        const limit = 50;

        const params: Partial<GetHuntGroupListRequest> = {
            name: search,
            limit: limit,
            offset: prevOptions.length
        };
        const body = new JSONFormData(session_id || '', csrf_token || '');
        body.setParams(params);

        const res: AxiosResponse<GetHuntGroupListResponse> = await api.post(
            Customer.GetHuntGroupList,
            body,
        );
        const items = res.data.huntgroup_list.map(addLabel);

        if (!annyAdded) {
            // @ts-ignore
            items.unshift(emptyValueForAny)
            annyAdded = true;
        }

        return {
            options: items,
            hasMore: res.data.total > prevOptions.length + limit,
        };
    };


    const renderMultiSelectItems = (selected: AssignedExtensionForMultipleSelectInterface[]) => {

        // @ts-ignore
        const renderTagsValue = selected?.map((v) => v?.id == -1 ? v.name : (v.id || v.name))?.join(', ') || '';
        return (
            <div className={classes.multiSelectFieldOverflowText}>
                <OverflowTooltip
                    text={renderTagsValue}
                    tooltip={renderTagsValue}
                    copy={false}
                />
            </div>
        );
    };

    const handleAgentsChange = (event: React.ChangeEvent<{}>, value: AssignedExtensionForMultipleSelectInterface[]) => {

        const allSelected = value[value.length - 1] ?? undefined

        if (value.length > 1 && allSelected && !allSelected.value) {
            setFieldValue('agentsFilter', [agentDef])
            return
        }

        if (!value.length && values.agentsFilter.length) {
            setFieldValue('agentsFilter', [agentDef])
            return
        }

        const someValues = value.length > 1 ?
            value.filter((item: AssignedExtensionForMultipleSelectInterface) => item.id && item.value)
            : value.filter((item: AssignedExtensionForMultipleSelectInterface) => item.id)

        setFieldValue('agentsFilter', someValues)

    }

    const agentOptionSelected = (
        option: DropDownDictionaryItem<AssignedExtensionForMultipleSelectInterface | undefined>,
        value: DropDownDictionaryItem<AssignedExtensionForMultipleSelectInterface | undefined>,
    ) => {
        return value.value === option.value
    }

    const renderAgentOption = (option: AssignedExtension) => {

        // @ts-ignore
        if (!option.value) {
            return <span>{option.name}</span>
        }

        return !option.name ? <span>{option.id}</span> : <span>{option.id} - {option.name}</span>
    }

    const agentOptionLabel = (item: AssignedExtension) => {
        return item.id !== undefined ? item.id + '-' + item?.name : item?.name
    }

    // @ts-ignore
    const formatOptionLabel = (option) => {
        return (
            <div style={{backgroundColor: 'transparent', padding: '5px'}}>
                {!option.label.id ? option.label : option.label.id + '-' + option.label.label}
            </div>
        );
    };


    useEffect(() => {

        setFilter && setFilter(values)

    }, [values]);

    // @ts-ignore
    return (
        <form
            onSubmit={handleSubmit}
            className={classes.filtersContainer}
            data-qa={'agents-list-filters'}
            data-testid={'agents-list-filters'}
        >
            <AsyncSelectField
                forceEnabled={true}
                className={classNames(classes.select, classes.extraMargin)}
                title={t('screens:calls.ringGroup')}
                value={values.ringGroupFilter}
                loadOptions={loadOptions}
                onInputChange={onInputChange}
                inDialog={true}
                onChange={(value) => {

                    const temp: AssignedExtension [] = [];
                    value?.assigned_extensions?.forEach((ext: AssignedExtension) => {
                        ext.type == 'Account' && temp.push(ext)
                    })

                    if (value?.id == -1 || value == undefined) {
                        setTempAgents()
                    } else if (temp.length) {
                        // @ts-ignore
                        setAgents([agentDef, ...temp.map(tempItem => ({
                            ...tempItem, value: tempItem.id
                        }))])
                        setUseSourceAgentList(false)
                    }

                    setFieldValue('ringGroupFilter', value ?? emptyValueForAny);
                    setFieldValue('agentsFilter', [agentDef])

                }}
                useAny
                closeMenuOnSelect={true}
                dataQa={'select-ring-groups'}
                isOptionSelected={(option) => {
                    return values.ringGroupFilter?.id == option.id
                }}
                hideSelectedOptions={false}
                formatOptionLabel={formatOptionLabel}
                placeholder={t('common:any')}
            />
            <SelectField
                className={classNames(classes.select, classes.multiSelectField)}
                classes={{
                    container: classes.multiSelectContainer,
                }}
                items={agents.filter(item => item.type != 'Group') || []}
                value={values.agentsFilter}
                multiple
                label={t('screens:supervisorPanel.agents')}
                // @ts-ignore
                getOptionLabel={agentOptionLabel}
                renderOption={renderAgentOption}
                onChange={handleAgentsChange}
                getOptionSelected={agentOptionSelected}
                defaultValue={agentDef}
                // @ts-ignore
                renderTags={renderMultiSelectItems}
                skipPermission
            />

            <CustomButton
                dataQa="search-button"
                primary accent type="submit"
                className={classes.button}
                skipPermission
            >
                {t('common:search')}
            </CustomButton>

        </form>
    )
}


export default FiltersComponent