import {makeStyles} from '@material-ui/core';
import {Colors} from '../../../styles/Colors';
import {WidgetCallHistory, WidgetCallVolumesTimeRange} from '../../../store/types/Wallboard';
import dayjs from '../../../services/customDayJs';

export type CallStatisticsFormProps = {
    ringGroup: string;
    timeRange: number;
};

export const initialValues: CallStatisticsFormProps = {
    ringGroup: '',
    timeRange: WidgetCallVolumesTimeRange.min10080
};

export type CallStatisticCsvItem = {
    name: string | number | null,
    value: string | number | null,
    ringGroup: string | null
};

export const useStyles = makeStyles(() => ({
    disclaimerContainer: {
        display: "flex",
        justifyContent:'center'
    },
    loadDataInProgress: {
        opacity: .5
    },
    spinnerContainer: {
        position: 'absolute',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    formContainer: {
        minWidth: 1124.910
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    itemsContainer: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'flex-start',
    },
    inputsContainer: {
        display: 'flex',
        alignItems: 'flex-start',

        '& .MuiFormControl-root': {
            marginRight: 18,
        },

        '& .date-time-picker': {
            '& .MuiInputBase-input': {
                width: 208,
            },
        },
    },
    inputs: {
        '& .MuiFormControl-root:first-of-type': {
            marginRight: 90,
        },
    },
    button: {
        '& .MuiButton-label': {
            color: Colors.White,
        },
        width: 96,
        marginTop: 8,
        marginRight: 16,
    },
    ringGroupField: {
        width: '312px !important',
        height: '56px !important',
        marginRight: 16,
    },
    timeRangeField: {
        width: '200px !important',
        height: '56px !important',
        marginRight: 16,
    },
    dropDownMultiSelect: {
        minHeight: 53,
        height: 53,
        padding: 0,

        '& .MuiAutocomplete-inputRoot': {
            height: 'fit-content !important',
            minHeight: 53,
            padding: 0
        }
    },
    downloadButton: {
        backgroundColor: Colors.Secondary5,
        '& .MuiButton-label': {
            color: Colors.Secondary1,
        },

        '& svg > path': {
            fill: Colors.Secondary1,
        },
        '&:hover': {
            backgroundColor: Colors.Secondary1,
            '& .MuiButton-label': {
                color: Colors.White,
            },
            '& svg > path': {
                fill: Colors.White,
            },
        },
        fontWeight: 700,
        borderRadius: '4px',
        padding: '6px 12px',
        textTransform: 'none',
        fontSize: 16,
        minWidth: 96,
        marginTop: 8,
    },
    dataContainer: {
        position: 'relative',
        width: 'fit-content',
        minWidth: 776,
        padding: '8px 32px',
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        background: Colors.White,
        border: '1px solid ' + Colors.Border,
        marginTop: 24,
        borderRadius: 4
    },
    dataContainerRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 'fit-content',
        justifyContent: 'space-between'
    },
    dataContainerRowUnderlined: {
        borderBottom: '1px solid ' + Colors.Border
    },
    supportColor: {
        color: Colors.Support + ' !important'
    },
    errorColor: {
        color: Colors.Error + ' !important'
    },
    blackColor: {
        color: Colors.Black + ' !important'
    }
}));

export const formatDuration = (seconds: number, defaultValue = '00:00:00') => {
    if (!seconds) {
        return defaultValue;
    }
    const duration = dayjs.duration(seconds, 'seconds');
    let format = 'HH:mm:ss';

    if (seconds >= 3600) {
        const hours = Math.trunc(seconds / 3600);
        format = (hours + '').padStart(2, '0') + ':mm:ss';
    }

    const formatted = duration.format(format);
    return formatted;
};

export const calculateAvgCallsDuration = (calls: WidgetCallHistory[] | undefined) => {
    if (!calls || !calls.length) {
        return '00:00';
    }
    const duration = calls?.map(e => e.durationInSec)
        ?.reduce((sum, current) => sum + current, 0) ?? 0;

    const avgDurationInSec = Math.trunc((1.0 * duration) / (1.0 * calls.length));

    return formatDuration(avgDurationInSec);
};