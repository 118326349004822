import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Colors} from '../../styles/Colors';
import {TFunction} from 'react-i18next';
import {Column} from 'react-table';
import EmptyRowContent from '../../components/DataTable/EmptyRowContent';
import {RoleUserItem} from '../../store/actions/company/payloads';
import StatusItem from '../../components/Extensions/StatusItem';
import CustomizedTooltip from '../../components/Tooltip/Tooltip';
import i18n from '../../services/i18n';
import OverflowTooltip from '../../components/OverflowTooltip/OverflowTooltip';
import {Permission, PermissionType} from "../../store/types/Permission";
import DataItemActions from "../../components/DataTable/DataItemActions";
import PermissionLinkCell from "../../components/DataTable/PermissionLinkCell";
import {ReduxState} from '../../store/types';
import {useSelector} from 'react-redux';
import {
    convertDate,
    convertDateFromTimeZoneToTimeZone,
    dateFormat,
    isDatePastOrFuture,
    statusBasedOnDates
} from '../Billing/Transactions/utils/DateUtils';
import {TimeZone} from '../../store/types/TimeZone';
import {YesNo} from '../../store/types/CallScreening';
import moment from 'moment';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        height: '100vh',
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        background: Colors.SmokeBackground,
        '& .MuiButton-label': {
            color: Colors.White,
        },
        '& .icon-actions-wrapper': {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '-40px',
            '& button': {
                marginLeft: 10,
            },
        },

        '& .sv-icon-actions-wrapper': {
            display: 'flex',
            alignItems: 'center',
            '& button': {
                marginLeft: 10,
            },
        },
    },

    svTableContainer: {
      marginTop:48
    },

    scrollable: {
        overflowY: 'auto',
        flex: 1,
        padding: '48px 96px',
        '& .MuiTableContainer-root': {
            maxWidth: 1040,
            width: 'unset !important'
        },
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start',
        '& .MuiButtonBase-root': {
            width: 'auto',
            height: 40,
            marginLeft: 15,
        },
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 25,
        '& h2': {
            fontSize: 32,
            lineHeight: '24px',
            marginBlockEnd: 8,
            marginBlockStart: 4,
            fontWeight: 500,
        },
        '& p': {
            fontSize: 11,
            lineHeight: '16px',
            opacity: 0.38,
            marginBlockStart: 0,
        },
    },
    filters: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 17,
    },
    filtersHidden: {
        width: 50,
        overflow: 'hidden',
        marginBottom: -92,
    },
    pressableSearchIcon: {
        marginTop: 10,
        marginRight: 17,
        marginLeft: 17,
        cursor: 'pointer',
    },
    filtersContainer: {
        marginTop: 20,
        marginBottom: 16,
        backgroundColor: Colors.LightGray,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    filtersInputs: {
        flex: 1,
        display: 'flex',
        '& MuiFormControl-root': {
            flex: 1,
            maxWidth: 400,
        },
    },
    headerRow: {
        textTransform: 'uppercase',
        border: 0,
    },
    headerCell: {
        fontWeight: 700,
        fontSize: 12,
        backgroundColor: Colors.LightGray,
        height: '60px',
    },
    row: {
        borderBottom: 0,
    },
    filterSeparation: {
        marginRight: 18,
        width: '390px',
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    tableContainer: {
        width: 1157,
        overflowX: 'hidden',
    },
    searchButton: {
        marginLeft: 6,
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'center',
        height: 56,
        marginTop: 24,
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormControl-root': {
            flex: 1,
            height: 'auto',
        },

        '& > :first-child': {
            marginTop: 0,
        },

        '& .MuiDialogContent-root': {
            padding: '54px 84px 36px 84px',
        },
    },
    actions: {
        display: 'flex',
        paddingLeft: 0,
        marginLeft: '-3.5rem',
    },
    actionWrapper: {
        width: 156,
    },
    callQueue: {
        width: '60px',
        display: 'flex',
        justifyContent: 'center',
    },
    cell: {
        color: Colors.Gray5,
        fontSize: 14,
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    svTable:{
        color: Colors.Gray5,
        '& .MuiTableRow-root': {
            height: 70,
            borderBottom: `1px solid ${Colors.LightGraySecondary4}`,
            '& .MuiTableRow-footer': {
                height: 64,
            },
        },
        '& .MuiTableBody-root:last-child': {
            borderBottom: 0,
        },
        '& .MuiTableRow-head': {
            height: 64,
        },
    },

    table: {
        marginTop: 30,
        color: Colors.Gray5,
        '& .MuiTableRow-root': {
            height: 70,
            borderBottom: `1px solid ${Colors.LightGraySecondary4}`,
            '& .MuiTableRow-footer': {
                height: 64,
            },
        },
        '& .MuiTableBody-root:last-child': {
            borderBottom: 0,
        },
        '& .MuiTableRow-head': {
            height: 64,
        },
    },
    roleSelect: {
        maxWidth: 360,
        width: 192,
        marginRight: 16,
    },
    addUserButton: {
        width: 129,
        height: 35,
    },
    buttonSearch: {
        width: 96,
        height: 40,
    },
}));

export type PortalUserListItem = {
    id?: number;
    login: string;
    role: number;
    email: string;
    actions?: any;
    i_time_zone: number | undefined;
    activation_date: Date | undefined;
    expiration_date: Date | undefined;
    type:string,
    i_account:number
};



export const svGenerateColumns = (
    t: TFunction<string>,
    onEdit: (row: PortalUserListItem) => void,
    onDelete: (row: PortalUserListItem) => void,
    classes: ReturnType<typeof useStyles>,
    i_account:any,
    currentUserTimeZoneName?: string,
    timeZonesList?: TimeZone[],
    userDateFormat?: string
): Column<PortalUserListItem>[] => {


    return [
        {
            accessor: 'login',
            Header: t<string>('screens:portalUsers.login'),
            width: 1,
            Cell: function Login(params) {
                const login = params.row.original.login;
                if (login) {
                    return (
                        <PermissionLinkCell
                            text={login}
                            onClick={() => onEdit(params.row.original)}
                            permissions={
                                Permission.MyCompany.PortalUsers.PortalUsersDetails.value
                            }
                            customizePermission={i_account === params.row.original.i_account ? ()=> PermissionType.Visible : ()=> PermissionType.ReadOnly }
                        />
                    );
                }
                return <EmptyRowContent/>;
            },
        },
        {
            accessor: 'id',
            width: 1,
            Header: t<string>('screens:portalUsers.status'),
            Cell: function Status(params) {
                const defaultZone = 'Europe/Kyiv';
                const user = params.row.original;
                const timeZoneFrom = timeZonesList?.find(e => e.i_time_zone === user.i_time_zone)
                    ?.time_zone_name ?? defaultZone;

                const activation_date_for_calculations = user.activation_date ? user.activation_date + '' : null;
                const expiration_date_for_calculations = user.expiration_date ? user.expiration_date + '' : null;

                const activation_date = convertDate(user.activation_date, timeZoneFrom, currentUserTimeZoneName ?? defaultZone, userDateFormat);
                const expiration_date = convertDate(user.expiration_date, timeZoneFrom, currentUserTimeZoneName ?? defaultZone, userDateFormat);

                const active = i18n.t<string>('screens:portalUsers.active');
                const expired = i18n.t<string>('screens:portalUsers.expired');
                const status = portalUserStatusBasedOnDates(activation_date_for_calculations, expiration_date_for_calculations, timeZoneFrom, currentUserTimeZoneName ?? defaultZone);

                const activeDateType = isDatePastOrFuture(activation_date_for_calculations, timeZoneFrom, currentUserTimeZoneName ?? defaultZone);
                const expirationDateType = isDatePastOrFuture(expiration_date_for_calculations, timeZoneFrom, currentUserTimeZoneName ?? defaultZone);

                let tooltip = '';
                if (expiration_date && activation_date) {
                    tooltip =
                        activeDateType === 'Future'
                            ? i18n.t<string> (
                                'tooltips:myCompany.willBeActivedOn',
                                {
                                    value: activation_date
                                }
                            )
                            : expirationDateType === 'Past'
                                ? i18n.t<string>(
                                    'tooltips:myCompany.expiredOn',
                                    {
                                        value: expiration_date
                                    },
                                )
                                : i18n.t<string>(
                                    'tooltips:myCompany.expirationDate',
                                    {
                                        value: expiration_date
                                    },
                                )
                    ;
                } else if (expiration_date) {
                    tooltip =
                        expirationDateType === 'Past'
                            ? i18n.t<string>('tooltips:myCompany.expiredOn', {
                                value: expiration_date
                            })
                            : i18n.t<string>(
                                'tooltips:myCompany.expirationDate',
                                {
                                    value: expiration_date
                                },
                            )
                    ;
                } else if (activation_date) {
                    tooltip = activeDateType === 'Future'
                        ? i18n.t<string>(
                            'tooltips:myCompany.willBeActivedOn',
                            {
                                value: activation_date
                            }
                        )
                        : '';
                }

                return (
                    <CustomizedTooltip
                        title={tooltip}
                        dataQa={'status-tooltip'}
                        dataTestId={'status-tooltip'}
                        copy={false}
                        disableHoverListener={tooltip === ''}
                    >
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                flex: 1,
                            }}
                        >
                            <StatusItem
                                blocked={status === expired ? YesNo.Yes : YesNo.No}
                                sip_status={status === active ? 1 : 0}
                                statusOnCustomMessage={t<string>(
                                    status,
                                )}
                                statusOffCustomMessage={t<string>(
                                    status,
                                )}
                                fetchData={() => null}
                                hideRefresh={true}
                            />
                        </div>
                    </CustomizedTooltip>
                );
            },
        },
        {
            accessor: 'email',
            width: 1.5,
            Header: t<string>('screens:portalUsers.email'),
            Cell: function Email(params) {
                const email = params.row.original.email;
                if (email) {
                    return (
                        <OverflowTooltip
                            classes={{
                                text: classes.cell,
                            }}
                            tooltip={email}
                            text={email}
                            copy={false}
                        />
                    );
                }
                return <EmptyRowContent/>;
            },
        },
        {
            accessor: 'type',
            width: 1,
            Header: t<string>('common:type'),
            Cell: function Description(params) {
                const role = params.row.original.type;
                const value = role ==  'hunt_group_supervisor' ? 'Supervisor' : 'Administrator'
                if (value) {
                    return (
                        <OverflowTooltip
                            text={value}
                            tooltip={value}
                            classes={{text: classes.cell}}
                            copy={false}
                        />
                    );
                }
                return <EmptyRowContent/>;
            },
        },
        {
            accessor: 'actions',
            width: 0.75,
            Header: t<string>('common:actions'),
            Cell: function ActionButtons(params) {

                return i_account === params.row.original.i_account ? (
                    <div className="sv-icon-actions-wrapper">
                        <DataItemActions
                            onDelete={() => onDelete(params.row.original)
                            }
                            onEdit={() => onEdit(params.row.original)}
                            editPermission={
                                Permission.MyCompany
                                    .PortalUsers.PortalUsersDetails.value
                            }
                            deletePermission={
                                Permission.MyCompany.PortalUsers.DeletePortalUser.value
                            }
                            hideDelete={true}
                        />

                    </div>

                ) : (<></>);
            },
        },
    ];
};

export const generateColumns = (
    t: TFunction<string>,
    onEdit: (row: PortalUserListItem) => void,
    onDelete: (row: PortalUserListItem) => void,
    classes: ReturnType<typeof useStyles>,
    roleList?: RoleUserItem[],
    currentUserTimeZoneName?: string,
    timeZonesList?: TimeZone[],
    userDateFormat?: string
): Column<PortalUserListItem>[] => {
    
    const { login } = useSelector((state: ReduxState) => state.auth);
    
    return [
        {
            accessor: 'login',
            Header: t<string>('screens:portalUsers.login'),
            width: 1,
            Cell: function Login(params) {
                const login = params.row.original.login;
                if (login) {
                    return (
                        <PermissionLinkCell
                            text={login}
                            onClick={() => onEdit(params.row.original)}
                            permissions={
                                Permission.MyCompany.PortalUsers.PortalUsersDetails.value
                            }
                        />
                    );
                }
                return <EmptyRowContent/>;
            },
        },
        {
            accessor: 'id',
            width: 1,
            Header: t<string>('screens:portalUsers.status'),
            Cell: function Status(params) {
                const defaultZone = 'Europe/Kyiv';
                const user = params.row.original;
                const timeZoneFrom = timeZonesList?.find(e => e.i_time_zone === user.i_time_zone)
                    ?.time_zone_name ?? defaultZone;

                const activation_date_for_calculations = user.activation_date ? user.activation_date + '' : null;
                const expiration_date_for_calculations = user.expiration_date ? user.expiration_date + '' : null;

                const activation_date = convertDate(user.activation_date, timeZoneFrom, currentUserTimeZoneName ?? defaultZone, userDateFormat);
                const expiration_date = convertDate(user.expiration_date, timeZoneFrom, currentUserTimeZoneName ?? defaultZone, userDateFormat);

                const active = i18n.t<string>('screens:portalUsers.active');
                const expired = i18n.t<string>('screens:portalUsers.expired');
                const status = portalUserStatusBasedOnDates(activation_date_for_calculations, expiration_date_for_calculations, timeZoneFrom, currentUserTimeZoneName ?? defaultZone);
                
                const activeDateType = isDatePastOrFuture(activation_date_for_calculations, timeZoneFrom, currentUserTimeZoneName ?? defaultZone);
                const expirationDateType = isDatePastOrFuture(expiration_date_for_calculations, timeZoneFrom, currentUserTimeZoneName ?? defaultZone);

                let tooltip = '';
                if (expiration_date && activation_date) {
                    tooltip =
                        activeDateType === 'Future'
                            ? i18n.t<string> (
                                'tooltips:myCompany.willBeActivedOn',
                                {
                                    value: activation_date
                                }
                            )
                        : expirationDateType === 'Past' 
                            ? i18n.t<string>(
                                'tooltips:myCompany.expiredOn',
                                {
                                    value: expiration_date
                                },
                            )
                            : i18n.t<string>(
                                'tooltips:myCompany.expirationDate',
                                {
                                    value: expiration_date
                                },
                            )
                        ;
                } else if (expiration_date) {
                    tooltip =
                        expirationDateType === 'Past'
                            ? i18n.t<string>('tooltips:myCompany.expiredOn', {
                                value: expiration_date
                            })
                            : i18n.t<string>(
                                    'tooltips:myCompany.expirationDate',
                                    {
                                        value: expiration_date
                                    },
                                )
                            ;
                } else if (activation_date) {
                    tooltip = activeDateType === 'Future'
                        ? i18n.t<string>(
                            'tooltips:myCompany.willBeActivedOn',
                            {
                                value: activation_date
                            }
                        )
                        : '';
                }

                return (
                    <CustomizedTooltip
                        title={tooltip}
                        dataQa={'status-tooltip'}
                        dataTestId={'status-tooltip'}
                        copy={false}
                        disableHoverListener={tooltip === ''}
                    >
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                flex: 1,
                            }}
                        >
                            <StatusItem
                                blocked={status === expired ? YesNo.Yes : YesNo.No}
                                sip_status={status === active ? 1 : 0}
                                statusOnCustomMessage={t<string>(
                                    status,
                                )}
                                statusOffCustomMessage={t<string>(
                                    status,
                                )}
                                fetchData={() => null}
                                hideRefresh={true}
                            />
                        </div>
                    </CustomizedTooltip>
                );
            },
        },
        {
            accessor: 'email',
            width: 1.5,
            Header: t<string>('screens:portalUsers.email'),
            Cell: function Email(params) {
                const email = params.row.original.email;
                if (email) {
                    return (
                        <OverflowTooltip
                            classes={{
                                text: classes.cell,
                            }}
                            tooltip={email}
                            text={email}
                            copy={false}
                        />
                    );
                }
                return <EmptyRowContent/>;
            },
        },
        {
            accessor: 'role',
            width: 1,
            Header: t<string>('screens:portalUsers.role'),
            Cell: function Description(params) {
                const role = params.row.original.role;
                const value =
                    roleList && roleList?.find((e) => e.i_role === role)?.name;
                if (value) {
                    return (
                        <OverflowTooltip
                            text={value}
                            tooltip={value}
                            classes={{text: classes.cell}}
                            copy={false}
                        />
                    );
                }
                return <EmptyRowContent/>;
            },
        },
        {
            accessor: 'actions',
            width: 0.75,
            Header: t<string>('common:actions'),
            Cell: function ActionButtons(params) {
                return (
                    <div className="icon-actions-wrapper">
                        <DataItemActions
                            onDelete={() => onDelete(params.row.original)
                            }
                            onEdit={() => onEdit(params.row.original)}
                            editPermission={
                                Permission.MyCompany
                                    .PortalUsers.PortalUsersDetails.value
                            }
                            deletePermission={
                                Permission.MyCompany.PortalUsers.DeletePortalUser.value
                            }
                            hideDelete={params.row.original.login?.toLocaleLowerCase() === login?.toLocaleLowerCase()}
                        />

                    </div>

                );
            },
        },
    ];
};

export function portalUserStatusBasedOnDates(activation_date: string | null | undefined,
    expiration_date: string | null | undefined, 
    timeZoneFrom: string, timeZoneTo: string, nowDateDefault: Date | null = null): string {
    const nowDate: Date = nowDateDefault ?? convertCurrentDateToUserTimeZone(timeZoneFrom);
    const activationDateConverted = isDatePastOrFuture(activation_date, timeZoneFrom, timeZoneTo, nowDate);
    const expirationDateConverted = isDatePastOrFuture(expiration_date, timeZoneFrom, timeZoneTo, nowDate);
    return statusBasedOnDates(activationDateConverted, expirationDateConverted);
}

export function convertCurrentDateToUserTimeZone(currentUserTimeZoneName: string | undefined): Date {
    const date = moment.utc().format(dateFormat);
    const convertedDate = convertDateFromTimeZoneToTimeZone(date, 'UTC', currentUserTimeZoneName?? 'Europe/Kyiv');
    return new Date(convertedDate);
}