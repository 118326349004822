import * as React from 'react';
import {useCallback, useEffect, useMemo} from 'react';
import {Box, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import { ReduxState } from '../../../../store/types';
import { NewTimeFilterDialogProps, newTimeFilterValidationSchema, useStyles, TimeFiltersPageFilterType, isOnnet } from './NewTimeFilterDialog.utils';
import PermissionProvider from '../../../PermissionProvider/PermissionProvider';
import DialogContainer, { DialogButton } from '../../../AlertDialog/DialogContainer';
import { Permission } from '../../../../store/types/Permission';
import CustomizedTextField from '../../../TextField/TextField';
import Loader from '../../../Loader/Loader';
import { actions } from '../../../../store';
import { CPConditionInfo, YesNo } from '../../../../store/types/CallScreening';
import classNames from 'classnames';
import CustomizedIconButton from '../../../IconButton/IconButton';
import { Add, Delete } from '@material-ui/icons';
import { Colors } from '../../../../styles/Colors';

const NewTimeFilterDialog: React.FC<NewTimeFilterDialogProps> = ({
        isOpen,
        filterType,
        editObject,
        clickOnClose,
    }) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const i_customer = useSelector(
        (state: ReduxState) => state.generic?.sessionData?.i_customer,
    );
    
    const cpConditionsList = useSelector(
        (state: ReduxState) => state.callSettings?.cpConditionsList?.cp_condition_list || [],
    );

    const isLoading: boolean = useSelector((state: ReduxState) => 
        state.callSettings.isCreatingCpCondition || state.callSettings.isEditingCpCondition || false);

    const initialValues: CPConditionInfo = useMemo(
        () => ({
            name: editObject?.name || '',
            i_customer: i_customer,
            numbers: editObject ? [...(editObject.numbers || [])] : (filterType === TimeFiltersPageFilterType.TimeWindow
                ? []
                : [{
                    number: ''
                }]),
            type: filterType.toString(),
            is_used: editObject?.is_used || YesNo.No,
            i_account: editObject?.i_account,
            i_cp_condition: editObject?.i_cp_condition,
            time_window: editObject?.time_window,
            value: editObject?.value,
        } as CPConditionInfo),
        [editObject, i_customer, filterType],
    );

    const {
        values,
        handleSubmit,
        resetForm,
        setFieldError,
        handleChange,
        setFieldValue,
        errors,
        dirty
    } = useFormik<CPConditionInfo>({
        initialValues,
        onSubmit: (form) => {
            if(editObject) {
                dispatch(actions.editCpCondition.request({
                    object: {
                        ...editObject,
                        ...form
                    },
                    callback: () => {
                        clickOnClose?.();
                    }
                }));
            } else {
                dispatch(actions.addCpCondition.request({
                    object: {
                        ...form
                    },
                    callback: () => {
                        clickOnClose?.();
                    }
                }));
            }
        },
        validationSchema: newTimeFilterValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true
    });


    useEffect(() => {
        resetForm()
    }, [isOpen]);
    
    const isNameNotUnique: boolean = useMemo(() => {
        const found = cpConditionsList
            ?.find(e => e.name?.toLocaleUpperCase()?.trim() === values.name?.toLocaleUpperCase()?.trim() &&
                e.type?.toLocaleLowerCase() === filterType.toLocaleLowerCase());
        let notUnique = (found !== undefined);
        if(notUnique && editObject !== undefined && found !== undefined) {
            if(found.i_cp_condition === editObject.i_cp_condition) {
                notUnique = false;
            }
        }
        if(notUnique && errors.name === undefined) {
            setFieldError('name', t('errors:common.thisNameIsAlreadyUsed'));
        } else if(!notUnique && errors.name !== undefined) {
            setFieldError('name', undefined);
        }
        return notUnique;
    }, [cpConditionsList, values.name, errors]);
    
    const numbersFilledIn: boolean = useMemo(() => {
        if(values.numbers?.length) {
            for(const n of values.numbers) {
                if(!n.number?.length) return false;
            }
        }
        return true;
    }, [values.numbers]);

    const onChangeNumberOrExtensionHandler = (value: string, index: number) => {
        setFieldValue(`numbers.${index}.number`, value);
        const onnet = isOnnet(value)
            ? YesNo.Yes
            : YesNo.No;
        setFieldValue(`numbers.${index}.onnet`, onnet);
    };

    const displayNumberError = useCallback((index: number) => {
        if(!errors?.numbers) return '';
        try {
            //@ts-ignore
            const d = errors.numbers as {number: string}[] | null[] | undefined[];
            if((d?.length || 0) < index) return '';
            return d?.[index]?.number || '';
        } catch {}
        return '';
    }, [errors]);
    
    return (
        <PermissionProvider permission={Permission.Calls.Settings.CallScreening.value}>
            <DialogContainer
                className={classNames(classes.modalContainer, 
                    (filterType !== TimeFiltersPageFilterType.TimeWindow) ? classes.modalContainerMinHeight : undefined)}
                isOpen={isOpen}
                dataQa={'time-filter-add-new-modal'}
                header={editObject
                    ? (filterType === TimeFiltersPageFilterType.TimeWindow
                        ? t('screens:callSettings.ediTimeFilterDialogTitle')
                        : t('screens:callSettings.editNumberDialogTitle')
                    )
                    : (filterType === TimeFiltersPageFilterType.TimeWindow
                        ? t('screens:callSettings.addNewTimeFilterDialogTitle')
                        : t('screens:callSettings.addNewNumberDialogTitle')
                    )
                }
                headerClass={classes.header}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => {
                            resetForm();
                            clickOnClose?.();
                        }}
                    />,
                    <DialogButton
                        key="save"
                        label={t('common:save')}
                        disabled={
                            !dirty ||
                            isLoading ||
                            isNameNotUnique || 
                            !numbersFilledIn ||
                            !(values.name?.length)
                        }
                        onClick={handleSubmit}
                        dataTestId={'add-new-time-filter-save-button'}
                        primary
                    />,
                ]}
            >
                <form
                    className={classes.form}
                    autoComplete="off"
                    data-testid="add-new-time-filter-form"
                >
                    <Grid className={classes.itemsContainer}>
                        <Box className={classes.boxRow}>
                            <CustomizedTextField
                                id="name"
                                label={t('screens:callSettings.policyName')}
                                value={values.name}
                                required
                                setFieldError={setFieldError}
                                helperText={
                                    errors.name && errors.name.length > 0
                                        ? errors.name
                                        : ''
                                }
                                onChange={handleChange}
                                maxLength={255}
                                dataQa="name-field"
                            />
                        </Box>

                        {filterType !== TimeFiltersPageFilterType.TimeWindow && (
                            values.numbers?.map((_number, index) => {
                                return (
                                    <Box className={classNames(classes.boxRow, classes.boxRowOfNumbers)} key={'number-' + index}>
                                        <CustomizedTextField
                                            id={`numbers.${index}.number`}
                                            label={t('screens:callSettings.numberOrExtension')}
                                            value={_number.number}
                                            required
                                            setFieldError={setFieldError}
                                            helperText={
                                                displayNumberError(index)
                                            }
                                            onChange={(event) => {
                                                onChangeNumberOrExtensionHandler(event.target.value, index);
                                            }}
                                            maxLength={255}
                                            dataQa={"numbers-field" + index}
                                        />
                                        <CustomizedIconButton
                                            disabled={(values.numbers?.length || 0) <= 1}
                                            onClick={() => {
                                                const cloneArray = [...(values.numbers || [])];
                                                cloneArray?.splice(index, 1);
                                                setFieldError(`numbers.${index}.number`, undefined);
                                                for(let ni = index + 1; ni < (values.numbers?.length || 0); ni++) {
                                                    const error = displayNumberError(ni);
                                                    if(error.length > 0) {
                                                        setFieldError(`numbers.${ni}.number`, undefined);
                                                        setFieldError(`numbers.${ni-1}.number`, error);
                                                    }
                                                }
                                                setFieldValue('numbers', cloneArray);
                                            }}
                                            dataTestId="remove-number-button"
                                            dataQa="remove-number-button"
                                            tooltipText={t<string>('common:delete')}
                                        >
                                            <Delete htmlColor={Colors.ListIconColor}/>
                                        </CustomizedIconButton>
                                        {
                                            index === ((values.numbers?.length || 1) - 1) && (
                                                <CustomizedIconButton
                                                    onClick={() => {
                                                        values.numbers?.push({
                                                            number: ''
                                                        });
                                                        setFieldValue('numbers', values.numbers);
                                                    }}
                                                    dataTestId="add-number-button"
                                                    dataQa="add-number-button"
                                                    tooltipText={t<string>('common:addNewButton')}
                                                    className={classes.customHoverButton}
                                                >
                                                    <Add htmlColor={Colors.Secondary1}/>
                                                </CustomizedIconButton>
                                            )
                                        }
                                    </Box>
                                );
                            })
                        )}

                    </Grid>
                </form>
                {isOpen && isLoading && (
                    <Loader dataQa={'wait-for-data'} absolutePosition />
                )}
            </DialogContainer>
        </PermissionProvider>
    );
};

export default NewTimeFilterDialog;