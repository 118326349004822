import React, {useMemo} from 'react';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import CustomizedCheckbox from '../../../../components/Checkbox/Checkbox';
import IconWithTooltip from '../../../../components/Tooltip/IconWithTooltip';
import {AutoPaymentsForm} from '../PaymentMethod.utils';
import {useStyles} from './AutoPayments.utils';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxState} from '../../../../store/types';
import {
    AutoPaymentInfo,
    OwnerPaymentMethod,
    PaymentMethodInfo,
} from '../../../../store/types/Payment';
import classNames from 'classnames';
import CustomizedTooltip from '../../../../components/Tooltip/Tooltip';
import NumericTextField from '../../../../components/NumericTextField/NumericTextField';
import Warning from '../../../../components/Warning/Warning';
import {
    GlobalCurrencyInfo,
} from '../../../../store/types/CustomerInfo';
import {usePermissionContext} from '../../../../hooks/usePermissions';
import {PermissionType} from '../../../../store/types/Permission';
import {useIndividualTypeHook} from "../../../../hooks/agents/useIndividualTypeHook";
import BalanceExceed from "./BalanceExceed";
import WithTax from "./WithTax";
import {actions} from "../../../../store";

const AutoPaymentsMethod = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const paymentMethod = useSelector<
        ReduxState,
        PaymentMethodInfo | undefined
    >((state) => state.billing.customerPaymentMethod);

    const ownerPaymentsMethods = useSelector<
        ReduxState,
        OwnerPaymentMethod[] | undefined
    >((state) => state.billing.ownerPaymentsMethods);

    const autoPayments = useSelector<ReduxState, AutoPaymentInfo | undefined>(
        (state) => state.billing.autoPaymentInfo,
    );

    const permission = usePermissionContext();

    const currencyInfo = useSelector<
        ReduxState,
        GlobalCurrencyInfo | undefined
    >((state) => state.generic.globalCurrency);

    const {customerInfo} = useSelector((state: ReduxState) => state.billing);

    const disabledAutoPaymentsByProvider = useMemo(() => {
        const method = ownerPaymentsMethods?.find(
            (v) => v.i_payment_method === paymentMethod?.i_payment_method,
        );

        if (method) {
            return method.recurring === 'N' && method.csc_policy === 'A';
        }

        return false;
    }, [ownerPaymentsMethods, paymentMethod]);

    const currentMethod = useMemo(
        () =>
            ownerPaymentsMethods?.find(
                (v) => v.i_payment_method === paymentMethod?.i_payment_method,
            ),
        [ownerPaymentsMethods],
    );

    const {
        setFieldValue,
        values,
        handleChange,
        setFieldError,
        errors,
        initialValues,
    } = useFormikContext<AutoPaymentsForm>();

    const {isSupervisor} = useIndividualTypeHook()

    const isFormBlocked = (!paymentMethod && !disabledAutoPaymentsByProvider) || isSupervisor;
    const isPaymentMethodNotIncludedInAvailableMethods =
        paymentMethod && !currentMethod && !initialValues.autoPayWithValues;


    const taxesVisible =
        customerInfo?.i_balance_control_type === 2 &&
        customerInfo?.estimate_taxes === 1 &&
        customerInfo?.inclusive_taxation === 'N';

    return (
        <CustomizedTooltip
            title={
                !paymentMethod
                    ? t('tooltips:billing.disabledAutoPayments')
                    : t('tooltips:billing.disabledFeature')
            }
            copy={false}
            disableHoverListener={!isFormBlocked}
        >
            <div className={classes.mainContainer}>
                <label className={classes.header}>
                    {t('screens:billing.autoPayments')}
                </label>

                <div>
                    <div>
                        <CustomizedTooltip
                            copy={false}
                            title={t('common:noPermissionToResource')}
                            disableHoverListener={permission !== PermissionType.ReadOnly}
                        >
                            <div className={classes.checkboxContainer}>
                                <CustomizedCheckbox
                                    dataQa="auto-pay-invoice"
                                    label={t('screens:billing.autoPayInvoice')}
                                    checked={values.autoPayInvoice}
                                    onChange={() =>
                                        setFieldValue(
                                            'autoPayInvoice',
                                            !values.autoPayInvoice,
                                        )
                                    }
                                    disabled={isFormBlocked}
                                />
                                <IconWithTooltip
                                    tooltipText={t(
                                        'tooltips:billing.preAuthorizedPaymentMethod',
                                    )}
                                />
                            </div>
                        </CustomizedTooltip>

                        {autoPayments?.frozen === 'Y' && (
                            <Warning
                                className={classes.frozenWarning}
                                text={t(
                                    'screens:billing.autoPaymentsSuspendedPrompt',
                                )}
                            />
                        )}

                        <CustomizedTooltip
                            title={permission === PermissionType.ReadOnly
                                ? t('common:noPermissionToResource')
                                : t('tooltips:billing.disabledFeature')}
                            copy={false}
                            disableHoverListener={
                                permission !== PermissionType.ReadOnly &&
                                !isPaymentMethodNotIncludedInAvailableMethods
                            }
                        >
                            <div className={classes.checkboxContainer2Rows}>
                                <div className={classes.childContainer}>
                                    <div>
                                        <CustomizedCheckbox
                                            dataQa="auto-pay"
                                            label={t('screens:billing.pay')}
                                            checked={values.autoPayWithValues}
                                            onChange={() => {
                                                if (
                                                    !values.autoPayWithValues &&
                                                    currentMethod?.min_allowed_payment &&
                                                    !values.amount
                                                ) {
                                                    setFieldValue(
                                                        'amount',
                                                        currentMethod.min_allowed_payment.toString(),
                                                    );
                                                } else {
                                                    setFieldValue(
                                                        'amount',
                                                        initialValues.amount,
                                                    );
                                                    setFieldValue(
                                                        'balance',
                                                        initialValues.balance,
                                                    );
                                                }

                                                setFieldValue(
                                                    'autoPayWithValues',
                                                    !values.autoPayWithValues,
                                                );

                                                setFieldError('amount', undefined);
                                                setFieldError('balance', undefined);
                                            }}
                                            disabled={
                                                isFormBlocked ||
                                                isPaymentMethodNotIncludedInAvailableMethods
                                            }
                                        />
                                    </div>
                                    <div className={classes.childContainer}>

                                        <div>
                                            <div className={classes.childContainer}>

                                                <NumericTextField
                                                    id="amount"
                                                    label={`${t('screens:billing.amount')}, ${
                                                        currencyInfo?.iso_4217
                                                    }`}
                                                    required
                                                    value={values.amount}
                                                    setFieldError={setFieldError}
                                                    onChange={(event) => {
                                                        setFieldValue('amount', event.target.value);
                                                        dispatch(actions.clearSimpleEstimatedTaxes())
                                                    }}
                                                    className={classes.textFieldMargin}
                                                    disabled={
                                                        isFormBlocked ||
                                                        isPaymentMethodNotIncludedInAvailableMethods ||
                                                        !values.autoPayWithValues
                                                    }
                                                    thousandSeparator=","
                                                    decimalScale={2}
                                                    min={currentMethod?.min_allowed_payment}
                                                    max={9999999999.99}
                                                    helperText={errors.amount}
                                                />


                                                <BalanceExceed
                                                    isDisabled={
                                                        isFormBlocked ||
                                                        isPaymentMethodNotIncludedInAvailableMethods ||
                                                        permission === PermissionType.ReadOnly
                                                    }
                                                    balanceType={customerInfo?.i_balance_control_type}
                                                />

                                            </div>

                                            <WithTax
                                                classContainer={classes.childContainer}
                                                textContainer={classNames(classes.childContainer, classes.center)}
                                                text={values.amount}
                                                visible={taxesVisible && values.autoPayWithValues}
                                            />

                                        </div>

                                        <div className={classes.childContainer}>
                                            <NumericTextField
                                                id="balance"
                                                label={`${t('screens:billing.balance')}, ${
                                                    currencyInfo?.iso_4217
                                                }`}
                                                required
                                                value={values.balance}
                                                setFieldError={setFieldError}
                                                onChange={handleChange}
                                                className={classes.textFieldMargin}
                                                disabled={
                                                    isFormBlocked ||
                                                    isPaymentMethodNotIncludedInAvailableMethods ||
                                                    !values.autoPayWithValues
                                                }
                                                thousandSeparator=","
                                                decimalScale={2}
                                                min={currentMethod?.min_allowed_payment}
                                                max={9999999999.99}
                                                helperText={errors.balance}
                                            />

                                            <IconWithTooltip
                                                tooltipText={t(
                                                    'tooltips:billing.paymentDefinedTreshold',
                                                )}
                                                className={classes.tooltipIcon}
                                            />
                                        </div>

                                    </div>


                                </div>

                            </div>
                        </CustomizedTooltip>
                    </div>
                </div>
            </div>
        </CustomizedTooltip>
    );
};

export default AutoPaymentsMethod;
