import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxState} from '../../store/types';
import dayjs from '../../services/customDayJs';
import {IconButton, makeStyles} from '@material-ui/core';
import {actions} from '../../store';
import {Colors} from '../../styles/Colors';
import relativeTime from 'dayjs/plugin/relativeTime';
import classNames from 'classnames';
import {ReactComponent as RefreshIcon} from '../../assets/refresh.svg';


dayjs.extend(relativeTime);

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const useStyles = makeStyles(() => ({
    refresh: {
        marginRight: -40,
        marginLeft: 8
    },
    mainText: {
        marginRight: 5,
    },
    horizontalContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: -25,
        color: Colors.Text3,
        flexDirection: 'row',
        width: '100%',
        lineHeight: 1.2,
        fontSize: 14,
        fontStyle: "italic",
        fontWeight: 400,
        padding: 8,

        '& svg': {
            width: '20px !important',
            height: '20px !important',

            '& path': {
                fill: Colors.Text
            }
        }
    },
    container: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 15,
        marginTop: 10,
        color: Colors.Text,
        flexDirection: 'column',
        width: 'auto%',
        lineHeight: 1.2,
        marginLeft: 34
    },
    button: {
        marginLeft: 10,
    },
    timeText: {
        fontStyle: 'italic',
        marginLeft: 0,
    },
    noWrap: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    }
}));


interface ActiveCallsInfoProps {
    horizontal?: boolean,
    allowToManualRefresh?: boolean,
    customCallRefreshFunction?: () => void
}

const ActiveCallsInfo: React.VFC<ActiveCallsInfoProps> = (
    {
        horizontal = false,
        allowToManualRefresh = false,
        customCallRefreshFunction
    }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [timeAgo, setTimeAgo] = useState('');

    const refreshTime = useSelector<ReduxState, string | undefined>(
        (state) => state.ringgroups.refreshTime,
    );

    useEffect(() => {
        const interval = setInterval(() => {
            if (refreshTime) {
                setTimeAgo(dayjs(refreshTime, dateFormat).fromNow());
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [refreshTime]);

    const refreshActiveCalls = useCallback(() => {
        dispatch(actions.getSipCallsList.request({}));
        dispatch(actions.getRingGroupsAccountList.request());
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            customCallRefreshFunction ? customCallRefreshFunction() : refreshActiveCalls();
        }, 10 * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, [refreshTime]);

    return (
        <div className={horizontal ? classes.horizontalContainer : classes.container}>
            {timeAgo && (
                <>
                    <span
                        className={horizontal ? classes.mainText : classes.noWrap}>{t('screens:calls.lastUpdated')}{':'}
                    </span>
                    <span className={classNames(classes.timeText, classes.noWrap)}>{timeAgo}</span>

                    {allowToManualRefresh && (
                        <IconButton
                            className={classes.refresh}
                            size="small"
                            onClick={customCallRefreshFunction ? customCallRefreshFunction : refreshActiveCalls}
                        >
                            <RefreshIcon/>
                        </IconButton>
                    )}
                </>
            )}
        </div>
    );
};

export default ActiveCallsInfo;
