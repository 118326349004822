import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    loader: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    customHeader: {
        paddingTop: 40,
        paddingBottom: 24
    },

    mainContainer: {
        position: 'relative',
        height: '100vh',
        display: 'flex',
        minHeight: 'fit-content',
        flexDirection: 'column',
        width: '100%', // Domyślna wartość

        // Warunkowe style dla przeglądarek WebKit
        '@supports (-webkit-fill-available)': {
            width: '-webkit-fill-available',
        },

        // Warunkowe style dla przeglądarek Gecko
        '@supports (-moz-available)': {
            width: '-moz-available',
        },
    }
}));