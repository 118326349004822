import React, {useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { actions } from '../../store';
import { useTranslation } from 'react-i18next';
import Header from '../../components/ListViewHeader/Header';
import { useStyles } from './CallHistory.utils';
import usePageTitle from '../../hooks/usePageTitle';
import { RecentCalls } from './RecentCalls/RecentCalls';
import DetailsWrapper from '../../components/DetailsWraper/DetailsWrapper';
import Loader from '../../components/Loader/Loader';
import { ActiveCalls } from './ActiveCalls/ActiveCalls';
import CallsTodayInfo from '../../components/Calls/CallsTodayInfo/CallsTodayInfo';
import {generateTabs} from "../../utils/generateTabs";
import {Permission} from "../../store/types/Permission";
import {useRawPermissions} from "../../hooks/usePermissions";
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import HeaderStat from "../../components/Calls/CallStatistics";
import { CallStatistics } from './CallStatistics/CallStatistics';

export const CallHistoryComponent: React.VFC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const permissions = useRawPermissions();

    const isCallsDoughnutDataLoading: boolean = useSelector((state: ReduxState) =>
        (state.calls?.isCallsDoughnutExtensionsLoading ?? false)
        || (state.ringgroups?.isLoadingSipCalls ?? false));

    usePageTitle();


    const {sipCallsList} = useSelector(
        (state: ReduxState) => state.ringgroups,
    );

    const isLoading = useSelector<ReduxState, boolean | undefined>(
        (state) => state.calls.areCallsLoading,
    );
    const getBasicData = (updateSipCalls: boolean) => dispatch(actions.getCallHistoryBasicData.request({
        updateSipCalls: updateSipCalls
    }));
    const getInitialBasicData = () => dispatch(actions.getInitialCallHistoryBasicData.request());

    useEffect(() => {
        getInitialBasicData();
        getBasicData(true);
    }, []);

    const { tabNames, tabs, perm } = generateTabs(
        [
            {
                title:  t('screens:calls.currentCalls'),
                permission:
                Permission.Calls.Activity.CurrentCalls.value,
                tab: (<ActiveCalls callback={() => {getBasicData(false);}}/>),
            },
            {
                title:  t('screens:calls.recentCalls'),
                permission:
                Permission.Calls.Activity.RecentCalls.value,
                tab: (
                    <RecentCalls 
                        callback={() => {getBasicData(true);}}
                        playCallRecordingPermission={Permission.Calls.Activity.RecentCalls.PlayCallRecording.value}
                        callDetailRecordPermission={Permission.Calls.Activity.RecentCalls.CallDetailRecord.value}
                        downloadCallRecordingPermission={Permission.Calls.Activity.RecentCalls.DownloadCallRecording.value}
                        deleteCallRecordingPermission={Permission.Calls.Activity.RecentCalls.CallDetailRecord.DeleteCallRecording.value}
                        downloadCallRecordingAtCallDetailPermission={Permission.Calls.Activity.RecentCalls.CallDetailRecord.DownloadCallRecording.value}
                        downloadTranscriptionPermissionOnDetailsDialog={Permission.Calls.Activity.RecentCalls.CallDetailRecord.DownloadCallTranscription.value}
                        downloadTranscriptionPermissionOnPayAudio={Permission.Calls.Activity.RecentCalls.PlayCallRecording.DownloadCallTranscription.value}
                    />
                ),
            },
            {
                title:  t('screens:calls.callStatistics'),
                permission:
                Permission.Calls.Activity.CallStatistics.value,
                tab: (
                    <CallStatistics callback={() => {getBasicData(true);}}/>
                ),
            }
        ],
        permissions,
    );

    const top =useMemo( () =>  {
        return (
            <div className={classes.header}>
                <Header title={t('screens:calls.callActivity')} hideTotal/>
                <div className={classes.rightSection}>
                    <HeaderStat calls={sipCallsList || []} isLoading={isCallsDoughnutDataLoading}/>
                    <CallsTodayInfo/>
                </div>
            </div>
        )
    }, [isCallsDoughnutDataLoading, sipCallsList]);

    if (isLoading) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="call-history-loader"/>
            </div>
        );
    }

    return (
        <PermissionPlaceholder
            permission={Permission.Calls.Activity.value}
        >
        <DetailsWrapper
                tabs={tabs}
                tabsNames={tabNames}
                top={top}
                defaultTabIndex={0}
                deleteText={t('screens:ringGroups.deleteRingGroup')}
                perm={perm}
            />
        </PermissionPlaceholder>
    );
};
