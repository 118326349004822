import {ActionType, createReducer} from 'typesafe-actions';
import * as actions from '../../actions/';
import {PortalUserListItem} from '../../../views/PortalUsers/utils';
import {RoleUserItem} from '../../actions/company/payloads';
import {
    GetPortalUserDetailsResponse,
} from '../../actions/company/payloads';
import {APIErrorInterface} from '../../types';
import {AdditionalElement} from '../../../utils/transformers';

export interface CompanyStateType {
    changePasswordErrors?: { [key in string]: string };
    companyInfoDetails?: {
        companyName: string;
        salutation: string;
        firstName: string;
        middleName: string;
        lastName: string;
        email: string;
        fax: string;
        phone: string | null;
        country: string;
        city: string;
        addressLine1: string;
        addressLine2: string;
        provinceState: string;
        postalCode: string;
        contactPerson: string;
        additionalPhone: string;
        amount?: string;
        additionalDetails: AdditionalElement[];
    };
    portalUserList?: {
        items: Omit<PortalUserListItem, 'actions'>[];
        total: number;
        allTotal: number;
    };
    isLoading?: boolean;
    isFormSaving?: boolean;
    iCustomer?: number;
    roleList?: RoleUserItem[];
    editedPortlUser?: GetPortalUserDetailsResponse;
    isAddPortalUserDataLoading?: boolean;
    apiErrors?: APIErrorInterface;
    updateCompanyInfoEventRaised?: boolean;
}

export const initialState: CompanyStateType = {
    isFormSaving: false,
    isLoading: false

};

export type CompanyActionsType = ActionType<typeof actions>;

const companyReducer = createReducer<CompanyStateType, CompanyActionsType>(
        initialState,
    )
        .handleAction(actions.changePassword.failure, (state, action) => {
            return {
                ...state,
                changePasswordErrors: action.payload,
            };
        })
        .handleAction(actions.changePassword.success, (state) => {
            return {
                ...state,
                changePasswordErrors: undefined,
            };
        })
        .handleAction(actions.getCompanyData.request, (state) => {
            return {
                ...state,
                isLoading: true,
            };
        })
        .handleAction(actions.saveForms, (state) => {
            return {
                ...state,
                apiErrors: undefined
            }
        })
        .handleAction(actions.getPortalUsersList.success, (state, action) => {
            return {
                ...state,
                portalUserList: {
                    ...state.portalUserList,
                    ...action.payload,
                    allTotal: action.payload.total ||
                        state.portalUserList?.allTotal || 0,
                },
                isLoading: false,
            };
        })
        .handleAction(
            actions.getCustomerInfoPortalUser.success,
            (state, action) => {
                return {
                    ...state,
                    iCustomer: action.payload.i_customer,
                };
            },
        )
        .handleAction(actions.getPortalDetails.request, (state) => {
            return {
                ...state,
                isLoading: true,
            };
        })
        .handleAction(actions.getCompanyInfoData.success, (state, action) => {
            return {
                ...state,
                companyInfoDetails: action.payload,
            };
        })
        .handleAction(actions.getCompanyData.success, (state) => {
            return {
                ...state,
                isLoading: false,
            };
        })
        .handleAction(actions.getPortalDetails.success, (state) => {
            return {
                ...state,
                isLoading: false,
            };
        })
        .handleAction(actions.getPortalDetails.failure, (state) => {
            return {
                ...state,
                isLoading: false,
            };
        })
        .handleAction(actions.updateCompanyInfoForm.request, (state) => {
            return {
                ...state,
                isFormSaving: true,
            };
        })
        .handleAction(actions.getRoles.success, (state, action) => {
            return {
                ...state,
                roleList: action.payload.customer_individual_allowed_role_list,
            };
        })
        .handleAction(actions.getPortalUsersList.request, (state) => {
            return {
                ...state,
                isLoading: true,
            };
        })
        .handleAction(actions.getPortalUsersList.failure, (state) => {
            return {
                ...state,
                isLoading: false,
            };
        })
        .handleAction(actions.saveNewPortalUser.request, (state) => {
            return {
                ...state,
                isFormSaving: true,
            };
        })
        .handleAction(actions.saveNewPortalUser.success, (state) => {
            return {
                ...state,
                isFormSaving: false,
            };
        })
        .handleAction(actions.saveNewPortalUser.failure, (state, action) => {
            return {
                ...state,
                isFormSaving: false,
                apiErrors: action.payload,
            };
        })
        .handleAction(actions.getPortalUserInfo.request, (state) => ({
            ...state,
            isLoading: true,
            updateCompanyInfoEventRaised: false,
        }))
        .handleAction(actions.getPortalUserInfo.success, (state) => ({
            ...state,
            isLoading: false
        }))
        .handleAction(actions.getPortalUserInfo.failure, (state) => ({
            ...state,
            isLoading: false
        }))
        .handleAction(actions.getPortalUserDetails.success, (state, action) => ({
            ...state,
            editedPortlUser: action.payload,
            apiErrors: undefined
        }))
        .handleAction(actions.getPortalUserDetails.failure, (state, action) => ({
            ...state,
            isLoading: false,
            apiErrors: action.payload,
        }))
        .handleAction(actions.editPortalUser.request, (state) => ({
            ...state,
            isFormSaving: true,
        }))
        .handleAction(actions.editPortalUser.success, (state) => ({
            ...state,
            apiErrors: undefined
        }))
        .handleAction(actions.editPortalUser.failure, (state, action) => ({
            ...state,
            isFormSaving: false,
            apiErrors: action.payload,
        }))
        .handleAction(actions.deletePortalUser.request, (state) => ({
            ...state,
            isLoading: true,
        }))
        .handleAction(actions.deletePortalUser.success, (state) => ({
            ...state,
            isLoading: false,
        }))

        .handleAction(actions.deletePortalUser.failure, (state) => ({
            ...state,
            isLoading: false,
        }))
        .handleAction(actions.getAddPortalUserData.request, (state) => ({
            ...state,
            isAddPortalUserDataLoading: true,
            apiErrors: undefined,
        }))
        .handleAction(actions.getAddPortalUserData.success, (state) => ({
            ...state,
            isAddPortalUserDataLoading: false,
        }))
        .handleAction(actions.getAddPortalUserData.failure, (state) => ({
            ...state,
            isAddPortalUserDataLoading: false,
        }))
        .handleAction(actions.updateCompanyInfoEventRaised, (state) => ({
            ...state,
            updateCompanyInfoEventRaised: true,
        }))
;

export default companyReducer;
