enum FileFormatType {
    JSON = 'application/json',
    Text = 'text/plain'
}

export function download(data: Blob, name: string, ext: string) {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = name + '.' + ext;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
}


export function downloadToJSON(data: any, name: string) {

    const jsonString = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonString], {type: FileFormatType.JSON});
    download(blob, name, 'json')
}

export function downloadToTxt(data: any, name: string) {
    const blob = new Blob([data], {type: FileFormatType.Text});
    download(blob, name, 'txt')

}