import React, {useCallback, useEffect, useMemo, useState} from "react";
import DialogContainer, {DialogButton} from "../../../components/AlertDialog/DialogContainer";
import {DialogInterface} from "../../../components/Extensions/types/dialog";
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {CreateNewSipTrunkValidationSchema, maxTrunkIdLength, useStyles} from "./CreateNewSipTrunkDialog.utils";
import Loader from "../../../components/Loader/Loader";
import TextField from "../../../components/TextField/TextField";
import IconWithTooltip from "../../../components/Tooltip/IconWithTooltip";
import PasswordTextField from "../../../components/PasswordTextField/PasswordTextField";
import {getConfigFromPasswordRulesOrDefault} from "../../../utils/passwordGenerator";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import CustomizedChip from "../../../components/Chip/Chip";
import {CreateNewSipTrunkFormData} from "../../../components/RingGroups/GroupMemberDialog.utils";
import {actions} from "../../../store";
import {useCreateNewSipTrunk} from "../../../hooks/useCreateNewSipTrunk";
import {CreateExtensionFaultCode, PasswordFaultCode} from "../../../store/types/Extension";
import CreateNewExtensionErrorDialog from "../../../components/Extensions/CreateNewExtensionErrorDialog";
import MainProductSelectField from "../../../components/Extensions/MainProductSelectFiled/MainProductSelectField";
import {MainProductType} from "../../../components/Extensions/MainProductSelectFiled/utils";
import DidNumberSelectField from "../../../components/DIDNumbers/DidNumberSelectField";
import {CreateSipTrunkFaultCode, CreateSipTrunkFaultString} from "../../../store/types/SipTrunk";

const CreateNewSipTrunkDialog: React.VFC<DialogInterface> = ({isOpen, toggleVisibility}) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [fetchData, setFetchData] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);

    const {isLoading, config, isDataFetching, apiErrors} = useCreateNewSipTrunk().data



    useEffect(() => {
        if (apiErrors == undefined) {
            setErrors({});
        } else if (
            apiErrors?.faultstring === CreateSipTrunkFaultString.DuplicateId
        ) {
            setFieldError(
                'trunkId',
                t('errors:sipTrunks.sipTrunkNumberExists'),
            );
        } else if (
            apiErrors?.faultcode?.includes(
                CreateSipTrunkFaultCode.MaxOfferedQuantity,
            ) || apiErrors.faultcode.search(CreateSipTrunkFaultCode.MaxQuantityErrorPartial) != -1
        ) {
            setFieldError(
                'mainProductId',
                t('errors:sipTrunks.limitProductNumber'),
            );
        }
    }, [apiErrors]);

    useEffect(() => {
        if (isDataFetching) {
            setFetchData(false);
        }
    }, [isDataFetching]);


    const initialValues: CreateNewSipTrunkFormData = useMemo(
        () => ({
            trunkId: '',
            password: '',
            mainProductId: undefined,
            didNumber: [],
        }),
        [],
    );

    const handleErrorShow = () => {
        toggleVisibility?.();
        resetForm();
        setShowErrorDialog(true);
    };

    const errorDialog = useMemo(
        () => (
            <CreateNewExtensionErrorDialog
                isOpen={showErrorDialog}
                toggleVisibility={() => setShowErrorDialog(false)}
                customHeader={t('screens:sipTrunks.errorHeader')}
                customDesc={t('screens:sipTrunks.errorDesc')}
            />
        ),
        [showErrorDialog],
    );


    useEffect(() => {
        if (apiErrors == undefined) {
            setErrors({});
        } else if (
            apiErrors?.faultcode === CreateSipTrunkFaultCode.DuplicateId ||
            apiErrors?.faultcode === CreateSipTrunkFaultCode.ServerAccountDuplicateId
        ) {
            setFieldError(
                'trunkId',
                t('errors:sipTrunks.sipTrunkNumberExists'),
            );
        } else if (
            apiErrors?.faultcode?.includes(
                CreateExtensionFaultCode.MaxOfferedQuantity,
            )
        ) {
            setFieldError(
                'mainProductId',
                t('errors:extensions.maximumProviderLimit'),
            );
        } else if (apiErrors?.faultcode == PasswordFaultCode.InvalidChars) {
            setFieldError(
                'password',
                t('errors:sipTrunks.invalidChars'),
            );
        } else if (apiErrors.faultstring == CreateSipTrunkFaultString.InvalidId ||
            apiErrors?.faultcode == CreateSipTrunkFaultCode.SameIdExists ) {
            setFieldError(
                'trunkId',
                t('errors:sipTrunks.sipTrunkNumberInvalid'),
            );
        }
    }, [apiErrors]);

    const {
        values,
        handleSubmit,
        handleChange,
        resetForm,
        setFieldValue,
        setFieldError,
        errors,
        setErrors,
    } = useFormik<CreateNewSipTrunkFormData>({
        initialValues,
        onSubmit: (form) => {
            dispatch(
                actions.createNewSipTrunk.request({
                    ...form,
                    errorCallback: handleErrorShow,
                }),
            );
        },
        enableReinitialize: true,
        validationSchema: CreateNewSipTrunkValidationSchema(Number(config?.Accounts.CreditAccMinServicePasswdLength)),
        validateOnChange: false,
        validateOnBlur: false,
    });

    useEffect(() => {
        if (isOpen) {
            resetForm()
            dispatch(actions.clearErrorsCreateNewSipTrunkForm());
            setFetchData(true);
        }
    }, [isOpen]);

    const defaultRenderDidTags = useCallback(
        (selected: string[]) => {
            return selected.map((v) => (
                <CustomizedChip
                    key={v}
                    label={v}
                    handleDelete={() => {
                        setFieldValue(
                            'didNumber',
                            values.didNumber?.filter((w) => w !== v),
                        );
                    }}
                />
            ));
        },
        [values.didNumber],
    );

    useEffect(() => {
        setFieldValue('password', values.password?.replaceAll(' ', ''))
    }, [values.password]);

    const isDisabledSave = !values.trunkId || !values.mainProductId || !values.password;
    return (
        <>
            <DialogContainer
                isOpen={isOpen}
                dataQa="sip-dialog"
                header={t('screens:sipTrunks.createNewSipTrunk')}
                headerClass={classes.header}
                className={classes.modalContainer}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => {
                            toggleVisibility?.();
                        }}
                    />,
                    <DialogButton
                        disabled={isDisabledSave}
                        key="save"
                        label={t('common:save')}
                        onClick={handleSubmit}
                        primary
                    />,
                ]}

            >

                <form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    data-testid="create-extension-form"
                >
                    <Grid item className={classes.itemsContainer}>

                        <TextField
                            id="trunkId"
                            label={t('screens:sipTrunks.trunkId')}
                            onChange={handleChange}
                            value={values.trunkId}
                            setFieldError={setFieldError}
                            icon={
                                <IconWithTooltip
                                    dataQa="create-sip-trunks-id-tooltip"
                                    tooltipText={t(
                                        'tooltips:sipTrunks.trunkId',
                                    )}
                                    copy={false}
                                />
                            }
                            iconPosition="end"
                            dataQa="create-sip-trunks-id-tooltip"
                            helperText={errors?.trunkId}
                            required
                            maxLength={maxTrunkIdLength}
                        />

                        <PasswordTextField
                            id="password"
                            label={t('screens:extensions.servicePassword')}
                            value={values.password}
                            onChange={handleChange}
                            dataQa="create-sip-trunk-password-input"
                            setFieldError={setFieldError}
                            error={errors.password}
                            isRequired={true}
                            passwordRulesConfig={getConfigFromPasswordRulesOrDefault(
                                config,
                            )}
                            setFieldValue={setFieldValue}
                            maxLength={32}
                            inputProps={{
                                inputProps: {
                                    min:
                                        config?.Accounts
                                            .CreditAccMinServicePasswdLength || 0,
                                    maxLength: 32,
                                },
                            }}
                        />

                        <MainProductSelectField
                            fetchData={fetchData}
                            mainProductId={values.mainProductId}
                            setFieldError={setFieldError}
                            onChange={(_, value) => {
                                setFieldValue('mainProductId', value.i_product);
                            }}
                            errorMsg={errors?.mainProductId}
                            productType={MainProductType.SipTrunk}
                            customApiErrorMessage={t(
                                'errors:extensions.maximumProviderLimit',
                            )}
                            disableInitSet={true}
                        />

                        <DidNumberSelectField
                            noOptionsText={t('common:noOptions')}
                            fetchData={fetchData}
                            dataQa={'sip-trunk-did-numbers-input'}
                            value={values.didNumber}
                            onChange={(e, value) =>
                                setFieldValue('didNumber', value)
                            }
                            tooltipMsg={t(
                                'tooltips:sipTrunks.didNumbers',
                            )}
                            copyTooltip={false}
                            tooltipDataQa="sip-trunk-did-numbers-tooltip"
                            id="didNumber"
                            onlyFree={true}
                            renderDidTags={defaultRenderDidTags}
                            classes={classes}
                        />

                        {isLoading || isDataFetching && (
                            <Loader
                                dataQa="create-new-sip-trunk-loader"
                                absolutePosition
                            />
                        )}

                    </Grid>

                </form>
            </DialogContainer>
            {errorDialog}
        </>
    )
}

export default CreateNewSipTrunkDialog;