import React from "react";
import {SipCall, SipCallState, SipCallType} from "../../store/actions/ringgroups/payloads";
import {FormatOptionLabelMeta} from "react-select/src/Select";
import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../styles/Colors";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles(() => ({
    option: {
        display: 'flex',
        flexDirection: 'column',
        '& .label': {
            lineHeight: '48px',
            color: Colors.Black,
            fontSize: 16,
            weight: 400
        },

        '& .register, & .unregister': {
            fontSize: 14,
            weight: 400,
            lineHeight: '16px',
        },

        '& .register': {
            color: Colors.Support
        },
        '& .unregister': {

            color: Colors.Gray5,
            fontWeight: 400,
        },

        '& .onHold': {
            fontWeight: 700,
            color: Colors.Primary2
        },

        '& .onCall': {
            fontWeight: 700,
            color: '#C40202',
        },
        '& .onRinging': {
            color: Colors.Support,
            fontWeight: 700
        },

        '& .isIdle': {
            color: Colors.Support,
            fontWeight: 700
        }

    },

    durationText: {
        marginLeft: 10,
        lineHeight: '16px',
        fontWeight: 400,
        fontSize: 14,
        color: Colors.Gray5
    },

    disabledOption: {
        display: 'flex',
        flexDirection: 'column',
        color: Colors.Gray + '!important',

        '& .label': {
            lineHeight: '48px',
            fontSize: 16,
            weight: 400
        },

        '& .register, & .unregister': {
            fontSize: 14,
            weight: 400,
            lineHeight: '16px',
        },
    }
}))


const ExtensionWithCallLabel: React.VFC<{
    option: any,
    labelMeta: FormatOptionLabelMeta<any, any>,
    sipCalls?: SipCall[],
    disableUnRegistered?: boolean,
    showRegisterStatus?: boolean,
    useAny?: boolean,


}> = (
    {
        option, labelMeta,
        sipCalls,
        disableUnRegistered,
        showRegisterStatus,
        useAny
    }) => {


    const classes = useStyles();

    const {t} = useTranslation()

    let registerText = option.sip_status ? t('screens:dashboard.registered') : t('screens:dashboard.unregistered');

    const extId = option.extension_id

    const callsData = sipCalls?.filter(el => (el.callee.extension_id === extId || el.caller.extension_id === extId))

    const isOnCall = callsData?.find(el => (
        (el.state === SipCallState.Connected)
        || (el.state === SipCallState.Trying && el.type === SipCallType.Outgoing)
        || (el.state === SipCallState.Ringing && el.type === SipCallType.Outgoing)
        || (el.state === SipCallState.Queued && el.type === SipCallType.Outgoing)
        || (el.state === SipCallState.Dequeued)
    ))

    const isOnHold = callsData?.find(el => (el.callee.extension_id === extId || el.caller.extension_id === extId) && (
        el.state === SipCallState.Holding || el.state === SipCallState.Held))


    const isRinging = callsData?.find(el => (el.state === SipCallState.Ringing && el.type === SipCallType.Incoming)
        || (el.state === SipCallState.Trying && el.type === SipCallType.Incoming))

    const isIdle = !isOnCall && !isOnHold && !isRinging && option.sip_status

    if (isIdle) {
        registerText = t('screens:wallboard.idleCounts')
    }

    const classNameValue = isOnCall ? 'onCall' : isOnHold ? 'onHold' : isRinging ? 'onRinging' : isIdle ? 'isIdle' : option.sip_status ? 'register' : 'unregister';

    return <>
        <div
            className={disableUnRegistered && option.sip_status == 0 ? classes.disabledOption : classes.option}>
            <span>{option.extension_name ? option.extension_id + ' - ' + option.extension_name : useAny && !option.extension_id ? option.label : option.extension_id}</span>

            {labelMeta.context == 'menu' && showRegisterStatus && (
                <div>
                        <span className={classNameValue}>
                            {isOnCall ? t('screens:wallboard.onCallCount') :
                                isOnHold ? t('screens:wallboard.onHoldCount') :
                                    isRinging ? t('screens:wallboard.ringingCount') : registerText}
                        </span>


                    <span className={classNameValue}>
                        {isOnCall ? <span className={classes.durationText}>{isOnCall.durationFormatSec}</span> :
                            isOnHold ? <span className={classes.durationText}>{isOnHold.durationFormatSec}</span> :
                                isRinging ?
                                    <span className={classes.durationText}>{isRinging.durationFormatSec}</span> : ''}
                        </span>
                </div>
            )
            }

        </div>
    </>


}

export default ExtensionWithCallLabel