import {DialogButton} from "../../components/AlertDialog/DialogContainer";
import React from "react";
import {useTranslation} from "react-i18next";
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import {makeStyles} from "@material-ui/core";
import {Colors} from "../../styles/Colors";


export const useStyles = makeStyles(() => ({

    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },

    dialog: {
        '& .MuiDialogContent-root': {
            background: Colors.White,
        },
    },
}));


interface DeletePortalUserDialogProps {
    isRemoveModalOpen: boolean,
    initialValues: { login: string },
    closeRemoveModal: () => void,
    removePortalUser: () => void
}

const DeletePortalUserDialog: React.VFC<DeletePortalUserDialogProps> = (
    {
        isRemoveModalOpen,
        initialValues,
        closeRemoveModal,
        removePortalUser
    }) => {

    const {t} = useTranslation()
    const classes = useStyles();

    return (
        <AlertDialog
            isOpen={isRemoveModalOpen}
            dataQa="remove-ringgroup-modal"
            contentClass="medium-width-modal-delete"
            hideHeader={true}
            className={classes.dialog}
            description={t(
                'screens:portalUsers.deletePortalUser',
                {name: initialValues.login},
            )}
            dialogActionsButtons={[
                <DialogButton
                    key="cancel"
                    label={t('common:cancel')}
                    onClick={closeRemoveModal}
                />,
                <DialogButton
                    key="delete"
                    label={t('common:delete')}
                    className={classes.primaryModalButton}
                    onClick={removePortalUser}
                />,
            ]}
        />
    )
}

export default DeletePortalUserDialog