import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { SipCall } from '../../store/actions/ringgroups/payloads';
import Config from '../../config.json';
import { useHistory, useLocation } from 'react-router';

type WebSocketObject = {
    cseq: number | undefined,
    action: string | undefined,
    event: string | undefined,
    result: {
        success: boolean | undefined,
        call_info: SipCall | undefined
    },
    success: boolean | undefined,
};

type WebSocketDataProviderProps = {
    callStatusUpdate: (call: SipCall) => void,
    recalcCallsDurationEvent?: () => void
};

const WebSocketDataProvider: React.FC<WebSocketDataProviderProps> = ({
    children,
    callStatusUpdate,
    recalcCallsDurationEvent
}) => {
    const dispatch = useDispatch();
    const prevLocation = useLocation().pathname;
    const history = useHistory();
    const { session_id, csrf_token } = useSelector((state: ReduxState) => state.auth);
    
    const updateStatus = useCallback((object) => {
        if(object.event === 'sip.call_control_notifications' && object.result.call_info)
        {
            callStatusUpdate(object.result.call_info);
        }
    }, [callStatusUpdate]);
    
    useEffect(() => {
        let shouldInitSignaling = false;
        let shouldDestroySignaling = false;

        if(!session_id || !callStatusUpdate) {
            shouldDestroySignaling = true;
        }
        
        //@ts-ignore
        if(!window.signaling && session_id && callStatusUpdate) {
            shouldInitSignaling = true;
        }

        //@ts-ignore
        if(shouldDestroySignaling && window.signaling) {
            try{
                //@ts-ignore
                window.signaling?.close();
            } catch {

            }
            //@ts-ignore
            window.signaling = null;
        }

        if(shouldInitSignaling) {
            //@ts-ignore
            if(!Config.WEBSOCKET_SERVER_URL?.length) {
                return;
            }
            const socket = new WebSocket(Config.WEBSOCKET_SERVER_URL);

            const getCseg = () => {
                //@ts-ignore
                return socket.cseq ?? 1;
            }

            const increaseCseq = () => {
                const cseq = getCseg() + 1;
                //@ts-ignore
                socket.cseq = cseq;
            }

            const setCseg = (cseq: number) => {
                //@ts-ignore
                socket.cseq = cseq;
            }

            socket.onopen = function() {
                socket.send(JSON.stringify({
                    "cseq": getCseg(),
                    "auth_info": {
                        "session_id": session_id,
                        "csrf_token": csrf_token
                    },
                    "service": "CallControl",
                    "method": "enable_api_notifications",
                    "params": {
                        "event": "sip.call_control_notifications"
                    }
                }));
                
                //@ts-ignore
                socket.pingTimer = setInterval(() => {
                    increaseCseq();
                    socket.send(JSON.stringify({
                        "cseq": getCseg(),
                        "service": "Session",
                        "method": "ping",
                        "params": {
                            "session_id": session_id,
                            "csrf_token": csrf_token
                        }
                    }));
                }, 2 * 60 * 1000); 
            };
            
            socket.onmessage = (event) => {
                const object: WebSocketObject = JSON.parse(event.data);
                if(object.cseq) {
                    setCseg(object.cseq);
                } else {
                    increaseCseq();
                }
                updateStatus(object);
            };

            socket.onclose = function() {
                //@ts-ignore
                if(socket.pingTimer) {
                    //@ts-ignore
                    clearInterval(socket.pingTimer);
                }
            };
            
            socket.onerror = function() {
                //@ts-ignore
                if(socket.pingTimer) {
                    //@ts-ignore
                    clearInterval(socket.pingTimer);
                }
            };

            //@ts-ignore
            window.signaling = socket;
        }
    }, [session_id, csrf_token, dispatch]);

    useEffect(() => {
        if(!Config.WEBSOCKET_SERVER_URL?.length) {
            return;
        }
        const interval = setInterval(() => {
            recalcCallsDurationEvent?.();
        }, 1000);
        return () => clearInterval(interval);
    }, [recalcCallsDurationEvent]);

    useEffect(() => {
        const unlisten = history.listen((location) => {
            if (location.pathname !== prevLocation) {
                //@ts-ignore
                window.signaling?.close();
                //@ts-ignore
                window.signaling = null;
            }
        });
        return () => {
            unlisten();
        }
    }, [history]);

    return (
        <>
            {children}
        </>
    );
};

export default WebSocketDataProvider;
