import React from "react";
import {makeStyles} from "@material-ui/core";
import {Colors} from "../../styles/Colors";


export const useStyles = makeStyles(() => ({
    root: {
        background: Colors.LightGraySecondary6,
        color: Colors.Text3,
        padding: '12px 20px' ,
        alignItems: "center",
        gap:8,
        display: "flex",
        lineHeight: '20px',
        fontSize: 14,
        fontWeight: 400,
        width: "fit-content",
        borderRadius: '4px',
        marginBottom:10
    },
    title: {
        fontWeight: 400,

    },
    subPageIcon: {
        marginTop: 3
    },

}));
const Disclaimer: React.FC<{ title: string }> = ({title, children}) => {

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <span className={classes.title} dangerouslySetInnerHTML={{ __html: title }}/>
            {children}
        </div>
    )
}

export default Disclaimer