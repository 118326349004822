import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DialogContainer, {
    DialogButton,
} from '../../../components/AlertDialog/DialogContainer';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import TextField from '../../../components/TextField/TextField';
import IconWithTooltip from '../../../components/Tooltip/IconWithTooltip';
import { APIErrorInterface, ReduxState } from '../../../store/types';
import MainProductSelectField from '../../../components/Extensions/MainProductSelectFiled/MainProductSelectField';
import Loader from '../../../components/Loader/Loader';
import DidNumberSelectField from '../../../components/DIDNumbers/DidNumberSelectField';
import * as Yup from 'yup';
import i18n from '../../../services/i18n';
import { actions } from '../../../store';
import { CreateNewFaxMailboxFormData, useStyles } from './utils';
import { MainProductType } from '../../../components/Extensions/MainProductSelectFiled/utils';
import CustomizedChip from '../../../components/Chip/Chip';
import { Box } from '@material-ui/core';
import { ReactComponent as WarningIcon } from '../../../assets/warning.svg';

const requiredFieldError = i18n.t('errors:common.emptyInput');
const numberDigitsError = i18n.t('errors:ringGroups.numberOnlyDigits');
const numberMaxLengthError = i18n.t('errors:ringGroups.numberMaxLength');
const nameMaxLengthError = i18n.t('errors:extensions.max32Length');
const emailError = i18n.t('errors:extensions.emailAddress');

const CreateNewFaxMailboxValidationSchema = Yup.object().shape({
    extensionNumber: Yup.string()
        .max(5, numberMaxLengthError)
        .matches(/^[0-9]*$/, numberDigitsError)
        .required(requiredFieldError),
    extensionName: Yup.string()
        .max(32, nameMaxLengthError)
        .required(requiredFieldError),
    mainProductId: Yup.number().required(requiredFieldError),
    extensionEmail: Yup.string().email(emailError).required(requiredFieldError),
});

type AddNewFaxMailboxesDialogProps = {
    isOpen: boolean;
    toggleVisibility?: () => void;
    skipLoader?: boolean;
};

const AddNewFaxMailboxesDialog: React.VFC<AddNewFaxMailboxesDialogProps> = ({
    isOpen,
    toggleVisibility,
    skipLoader = false,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [otherError, setOtherError] = useState<boolean>(false);
    const [fetchData, setFetchData] = useState(false);

    const isDataFetching = useSelector<ReduxState, boolean>(
        (state) => !!state.extensions.createExtensionDataIsLoading,
    );

    const apiErrors = useSelector<ReduxState, APIErrorInterface | undefined>(
        (state) => state.faxMailboxes.createNewFaxMailboxesErrors,
    );

    useEffect(() => {
        if (isDataFetching) {
            setFetchData(false);
        }
    }, [isDataFetching]);

    const initialValues = useMemo(
        () => ({
            extensionNumber: '',
            extensionName: '',
            mainProductId: undefined,
            extensionEmail: '',
            didNumber: [],
        }),
        [],
    );

    useEffect(() => {
        if (
            apiErrors?.faultcode ===
            'Server.Customer.Ext_HG_number_already_in_use'
        ) {
            setFieldError(
                'extensionNumber',
                t('errors:faxMailboxes.numberInUse'),
            );
        } else if (apiErrors?.faultcode === 'Server.Customer.duplicate_id') {
            setFieldError(
                'extensionNumber',
                t('errors:faxMailboxes.alreadyExists'),
            );
        } else if (
            apiErrors?.faultcode.includes('Server.Customer.Maximum offered quantity exceeded')
        ) {
            setFieldError(
                'mainProductId',
                t('errors:faxMailboxes.quantityExceeded'),
            );
        } else if (apiErrors !== undefined) {
            setOtherError(true);
        }
    }, [apiErrors]);

    const {
        values,
        handleSubmit,
        handleChange,
        resetForm,
        setFieldValue,
        setFieldError,
        errors,
        dirty,
    } = useFormik<CreateNewFaxMailboxFormData>({
        initialValues,
        onSubmit: (form) => {
            dispatch(actions.createNewFaxMailbox.request(form));
        },
        enableReinitialize: true,
        validationSchema: CreateNewFaxMailboxValidationSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });

    useEffect(() => {
        isOpen && resetForm()
      isOpen &&  setFetchData(true);

    }, [isOpen]);

    const defaultRenderDidTags = useCallback(
        (selected: string[]) => {
            return selected.map((v) => (
                <CustomizedChip
                    key={v}
                    label={v}
                    handleDelete={() => {
                        setFieldValue(
                            'didNumber',
                            values.didNumber?.filter((w) => w !== v),
                        );
                    }}
                />
            ));
        },
        [values.didNumber],
    );

    const dialogActionsButtons = otherError
        ? [
              <DialogButton
                  key="close"
                  label={t('common:close')}
                  onClick={() => {
                      toggleVisibility?.();
                      setOtherError(false);
                      dispatch(actions.clearAddNewFaxApiErrors())
                  }}
                  primary
              />,
          ]
        : [
              <DialogButton
                  key="cancel"
                  label={t('common:cancel')}
                  onClick={() => {
                      dispatch(actions.clearAddNewFaxApiErrors())
                      toggleVisibility?.();
                  }}
              />,
              <DialogButton
                  className={classNames(
                      (!dirty ||
                          !(
                              values.extensionName &&
                              values.extensionEmail &&
                              values.extensionNumber &&
                              values.mainProductId
                          )) &&
                          classes.actionButtonDisabled,
                  )}
                  key="save"
                  label={t('common:save')}
                  onClick={handleSubmit}
                  disabled={
                      !dirty ||
                      !(
                          values.extensionName &&
                          values.extensionEmail &&
                          values.extensionNumber &&
                          values.mainProductId
                      )
                  }
                  id="save-button"
                  primary
              />,
          ];

    const showLoader = skipLoader
        ? false
        : isDataFetching;

    return (
        <DialogContainer
            isOpen={isOpen}
            header={t('screens:faxMailboxes.adNew')}
            dialogActionsButtons={dialogActionsButtons}
            className={classNames(
                classes.modalContainer,
                otherError && classes.warningModal,
            )}
            headerClass={classes.header}
        >
            {showLoader ? (
                <Loader
                    dataQa="create-fax-mailbox-data-fetching-loader"
                    absolutePosition={true}
                />
            ) : (
                <form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    data-testid="create-fax-mailbox-form"
                >
                    {otherError ? (
                        <Box
                            className={classes.warningWrapper}
                            display="flex"
                            alignItems="center"
                        >
                            <WarningIcon
                                data-qa={'warning-icon'}
                                data-testid="warning-icon"
                            />
                            <span className={classes.otherErrorLabel}>
                                {t('errors:faxMailboxes.otherError')}
                            </span>
                        </Box>
                    ) : (
                        <>
                            {' '}
                            <Grid
                                className={classNames(
                                    classes.itemsContainer,
                                    classes.sectionPadding,
                                )}
                            >
                                <TextField
                                    id="extensionName"
                                    label={t('common:name')}
                                    onChange={handleChange}
                                    value={values.extensionName}
                                    setFieldError={setFieldError}
                                    icon={
                                        <IconWithTooltip
                                            dataQa="create-faxMailbox-name-tooltip"
                                            tooltipText={t(
                                                'tooltips:faxMailboxes.name',
                                            )}
                                            copy={false}
                                        />
                                    }
                                    iconPosition="end"
                                    dataQa="create-extension-name-input"
                                    helperText={errors?.extensionName}
                                    required
                                    className={classes.marginTop0}
                                    maxLength={32}
                                />
                                <TextField
                                    id="extensionNumber"
                                    label={t(
                                        'screens:extensions.extensionNumber',
                                    )}
                                    onChange={handleChange}
                                    value={values.extensionNumber}
                                    setFieldError={setFieldError}
                                    icon={
                                        <IconWithTooltip
                                            dataQa="create-faxMailbox-number-tooltip"
                                            copy={false}
                                            tooltipText={t(
                                                'tooltips:faxMailboxes.extensionNumber',
                                            )}
                                        />
                                    }
                                    iconPosition="end"
                                    dataQa="create-extension-number-input"
                                    helperText={errors?.extensionNumber}
                                    required
                                    maxLength={5}
                                />
                                <MainProductSelectField
                                    fetchData={fetchData}
                                    mainProductId={values.mainProductId}
                                    setFieldError={setFieldError}
                                    onChange={(_, value) => {
                                        setFieldValue(
                                            'mainProductId',
                                            value.i_product,
                                        );
                                    }}
                                    errorMsg={errors?.mainProductId}
                                    apiErrors={apiErrors}
                                    productType={MainProductType.FaxMailboxes}
                                    customApiErrorMessage={t(
                                        'errors:faxMailboxes.quantityExceeded',
                                    )}
                                    disableInitSet={true}
                                />
                            </Grid>
                            <Grid
                                className={classNames(
                                    classes.itemsContainer,
                                    classes.sectionMargin,
                                )}
                            >
                                <TextField
                                    id="extensionEmail"
                                    label={t('common:emailAddress')}
                                    onChange={handleChange}
                                    value={values.extensionEmail}
                                    setFieldError={setFieldError}
                                    icon={
                                        <IconWithTooltip
                                            copy={false}
                                            dataQa="create-fax-mailbox-email-tooltip"
                                            tooltipText={t(
                                                'tooltips:faxMailboxes.email',
                                            )}
                                        />
                                    }
                                    iconPosition="end"
                                    dataQa="create-fax-mailbox-email-input"
                                    helperText={errors?.extensionEmail}
                                    required
                                    maxLength={128}
                                />

                                <DidNumberSelectField
                                    fetchData={fetchData}
                                    dataQa={'fax-mailbox-did-numbers-input'}
                                    value={values.didNumber}
                                    onChange={(e, value) =>
                                        setFieldValue('didNumber', value)
                                    }
                                    tooltipMsg={t(
                                        'tooltips:faxMailboxes.didNumbers',
                                    )}
                                    copyTooltip={false}
                                    tooltipDataQa="fax-mailbox-did-numbers-tooltip"
                                    id="didNumber"
                                    onlyFree={true}
                                    renderDidTags={defaultRenderDidTags}
                                />
                            </Grid>
                        </>
                    )}
                </form>
            )}
        </DialogContainer>
    );
};

export default AddNewFaxMailboxesDialog;
