import {makeStyles} from '@material-ui/core';
import {Colors} from '../../styles/Colors';
import * as Yup from "yup";
import {emailValidError, nameMaxLengthError, requiredFieldError} from "../../components/PortalUsers/utils";
import dayjs from "../../services/customDayJs";
import {convertUtcToUserLocalTime} from "../../utils/dateWithTimezoneConversion";
import i18n from "i18next";

export const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        height: '100vh',
        width: '100%',
        '& .MuiButton-label': {
            color: Colors.White,
            paddingRight: 25,
            paddingLeft: 25,
        },
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    scrollable: {
        overflowY: 'auto',
        flex: 1,
        backgroundColor: Colors.SmokeBackground,
    },
    headerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: Colors.White,
        alignItems: 'center',
        '& h3': {
            fontSize: 22,
            margin: 0,
        },
        '& p': {
            fontSize: 16,
            color: Colors.Gray5,
            margin: 0,
        },
    },
    statusWrapper: {
        display: 'flex',
        flexDirection: 'column',
        '& > :last-child': {
            color: Colors.Text,
        },
    },
    extensionHeader: {
        display: 'flex',
        alignItems: 'center',
        '& label': {
            marginRight: 18,
            fontWeight: 300,
            fontSize: 32,
        },
        '& p': {
            fontWeight: 500,
            fontSize: 32,
            color: Colors.Text,
        },
    },
    subTitle: {
        color: Colors.Gray5,
        fontSize: 22,
    },
    extensionTop: {
        paddingTop: 57,
        display: 'flex',
        justifyContent: 'space-between',
    },
    loader: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomBar: {
        padding: '25px 90px 25px 90px',
        borderTop: `1px rgba(0, 0, 0, 0.12) solid`,
        backgroundColor: Colors.White,
        margin: '0 !important',
    },

    addButton: {
        '& .MuiButton-label': {
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
        },
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    status: {
        fontWeight: 500,
    },
    dialog: {
        '& .MuiDialogContent-root': {
            background: Colors.White,
        },
    },
}));

export type EditPortalUserDetailsProps = {
    id: number;
};

export const makeCreateNewSvPortalUserValidationSchema = (config: any, timezoneOffset: any, userDateFormat: any) => {
    return Yup.object().shape({
        password: Yup.string()
            .test(
                'passLength',
                i18n.t<string>('enums:passwordRules.PasswordMinLength', {
                    charLength: config?.Web.PasswordMinLength,
                }),
                function (value?: string) {
                    if (
                        value !== undefined &&
                        config?.Web.PasswordMinLength
                    ) {
                        return (
                            value.length >=
                            config?.Web.PasswordMinLength
                        );
                    }

                    return true;
                },
            )
            .test(
                'passContainLetters',
                i18n.t<string>('enums:passwordRules.cr'),
                function (value?: string) {
                    if (
                        value !== undefined &&
                        config?.Web.PasswordComplexityRules
                    ) {
                        return /[a-zA-Z]/g.test(value);
                    }

                    return true;
                },
            )
            .test(
                'passContainLetters',
                i18n.t<string>('enums:passwordRules.nr'),
                function (value?: string) {
                    if (
                        value !== undefined &&
                        config?.Web.PasswordComplexityRules
                    ) {
                        return /\d/.test(value);
                    }

                    return true;
                },
            )
            .max(32, nameMaxLengthError)
            .required(requiredFieldError),
        login: Yup.string()
            .matches(
                /(^[A-Za-z0-9-_@.]*$)/,
                i18n.t<string>('errors:portalUsers.portalRegex'),
            )
            .required(requiredFieldError),
        email: Yup.string().email(emailValidError).notRequired(),
        activationDate: Yup.string()
            .test('past', (value, {createError, path}) => {
                if (!value) {
                    return true;
                }

                const date = dayjs(value, 'YYYY-MM-DD');
                const today = dayjs().startOf('date');

                const convertedValue = convertUtcToUserLocalTime(
                    value,
                    timezoneOffset,
                    userDateFormat,
                );

                return date >= today
                    ? true
                    : createError({
                        path,
                        message: i18n.t<string>('errors:portalUsers.activationDate', {
                            value: convertedValue,
                        }),
                    });
            })
            .nullable(),
        expirationDate: Yup.string()
            .test('past', (value, {createError, path}) => {
                if (!value) {
                    return true;
                }

                const date = dayjs(value, 'YYYY-MM-DD');
                const today = dayjs().startOf('date');

                const convertedValue = convertUtcToUserLocalTime(
                    value,
                    timezoneOffset,
                    userDateFormat,
                );

                return date >= today
                    ? true
                    : createError({
                        path,
                        message: i18n.t<string>('errors:portalUsers.activationDate', {
                            value: convertedValue,
                        }),
                    });
            })
            .test(
                'dobY',
                i18n.t<string>('errors:portalUsers.expirationDateAfterActivation'),
                (value, ctx) => {
                    const expired = value
                        ? dayjs(value, 'YYYY-MM-DD').toDate()
                        : null;
                    const activation = Yup.ref('activationDate')
                        ? dayjs(
                            ctx.parent.activationDate,
                            'YYYY-MM-DD',
                        ).toDate()
                        : null;

                    if (!expired || !activation || !ctx.parent.activationDate) {
                        return true;
                    }

                    return activation < expired;
                },
            )
            .nullable()
            .notRequired(),
    })
}


export const makeCreateNewPortalUserValidationSchema = (config: any, timezoneOffset: any, userDateFormat: any) => {
    return Yup.object().shape({
        role: Yup.string().required(requiredFieldError).nullable(),
        password: Yup.string()
            .test(
                'passLength',
                i18n.t<string>('enums:passwordRules.PasswordMinLength', {
                    charLength: config?.Web.PasswordMinLength,
                }),
                function (value?: string) {
                    if (
                        value !== undefined &&
                        config?.Web.PasswordMinLength
                    ) {
                        return (
                            value.length >=
                            config?.Web.PasswordMinLength
                        );
                    }

                    return true;
                },
            )
            .test(
                'passContainLetters',
                i18n.t<string>('enums:passwordRules.cr'),
                function (value?: string) {
                    if (
                        value !== undefined &&
                        config?.Web.PasswordComplexityRules
                    ) {
                        return /[a-zA-Z]/g.test(value);
                    }

                    return true;
                },
            )
            .test(
                'passContainLetters',
                i18n.t<string>('enums:passwordRules.nr'),
                function (value?: string) {
                    if (
                        value !== undefined &&
                        config?.Web.PasswordComplexityRules
                    ) {
                        return /\d/.test(value);
                    }

                    return true;
                },
            )
            .max(32, nameMaxLengthError)
            .required(requiredFieldError),
        login: Yup.string()
            .matches(
                /(^[A-Za-z0-9-_@.]*$)/,
                i18n.t<string>('errors:portalUsers.portalRegex'),
            )
            .required(requiredFieldError),
        email: Yup.string().email(emailValidError).notRequired(),
        activationDate: Yup.string()
            .test('past', (value, {createError, path}) => {
                if (!value) {
                    return true;
                }

                const date = dayjs(value, 'YYYY-MM-DD');
                const today = dayjs().startOf('date');

                const convertedValue = convertUtcToUserLocalTime(
                    value,
                    timezoneOffset,
                    userDateFormat,
                );

                return date >= today
                    ? true
                    : createError({
                        path,
                        message: i18n.t<string>('errors:portalUsers.activationDate', {
                            value: convertedValue,
                        }),
                    });
            })
            .nullable(),
        expirationDate: Yup.string()
            .test('past', (value, {createError, path}) => {
                if (!value) {
                    return true;
                }

                const date = dayjs(value, 'YYYY-MM-DD');
                const today = dayjs().startOf('date');

                const convertedValue = convertUtcToUserLocalTime(
                    value,
                    timezoneOffset,
                    userDateFormat,
                );

                return date >= today
                    ? true
                    : createError({
                        path,
                        message: i18n.t<string>('errors:portalUsers.activationDate', {
                            value: convertedValue,
                        }),
                    });
            })
            .test(
                'dobY',
                i18n.t<string>('errors:portalUsers.expirationDateAfterActivation'),
                (value, ctx) => {
                    const expired = value
                        ? dayjs(value, 'YYYY-MM-DD').toDate()
                        : null;
                    const activation = Yup.ref('activationDate')
                        ? dayjs(
                            ctx.parent.activationDate,
                            'YYYY-MM-DD',
                        ).toDate()
                        : null;

                    if (!expired || !activation || !ctx.parent.activationDate) {
                        return true;
                    }

                    return activation < expired;
                },
            )
            .nullable()
            .notRequired(),
    })
}
