import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../store';
import {ReduxState} from '../../store/types';
import {
    EditPortalUserDetailsProps,
    makeCreateNewPortalUserValidationSchema, makeCreateNewSvPortalUserValidationSchema,
    useStyles
} from './editPortalUserUtils';
import Loader from '../../components/Loader/Loader';
import {
    CreateNewPortalUserFormData,
    SecurityValidationSchema, SupervisorValidationSchema,
} from '../../components/PortalUsers/utils';
import PortalUserSettings from '../../components/PortalUsers/Settings/PortalUserSettings';
import PortalUserSecurity from '../../components/PortalUsers/Security/PortalUserSecurity';
import history from '../../history';
import {Routes} from '../../routes/routes';
import dayjs from '../../services/customDayJs';
import {getTabNumber} from '../../utils/getTabNumber';
import usePageTitle from '../../hooks/usePageTitle';
import _ from 'lodash';
import DetailsWrapper from '../../components/DetailsWraper/DetailsWrapper';
import {useRawPermissions} from "../../hooks/usePermissions";
import {generateTabs} from "../../utils/generateTabs";
import {Permission, PermissionType} from "../../store/types/Permission";
import PermissionProvider from "../../components/PermissionProvider/PermissionProvider";
import NotFound from '../NotFound/NotFound';
import ErrorNotification from "../../components/TabErrors/ErrorNotification";
import {useIndividualTypeHook} from "../../hooks/agents/useIndividualTypeHook";
import {usePortalUserData} from "../../hooks/usePortalUserData";
import Header from "./Header";
import DeletePortalUserDialog from "./DeletePortalUserDialog";

const USER_NOT_FOUND_API_ERROR_CODE = 'Server.Customer.i_individual.not_found';

const EditPortalUser: React.FC<EditPortalUserDetailsProps> = ({id}) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    usePageTitle();

    const {isSupervisor, i_account, i_individual} = useIndividualTypeHook()
    const {login} = useSelector((state: ReduxState) => state.auth);
    const iTimeZone = useSelector(
        (state: ReduxState) => state.myProfile?.customer?.iTimeZone,
    );

    const {
        isLoading,
        editedPortlUser,
        isFormSaving,
        apiErrors,
        roleList,
        timeZonesList,
        config,
        timezoneOffset,
        userDateFormat,
        saveClicked,
        initialValues,
        svTypeSelected
    } = usePortalUserData(i_individual)

    const permissions = useRawPermissions();

    const [toastApiErrorVisible, setToastApiErrorVisible] = useState(false);
    const [isSvTypeSelected, setIsSvTypeSelected] = useState(svTypeSelected)
    const [tabIndex, setTabIndex] = useState(getTabNumber([0, 1, 2]));
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

    const CreateNewPortalUserValidationSchema = makeCreateNewPortalUserValidationSchema(config, timezoneOffset, userDateFormat)
    const CreateNewSvPortalUserValidationSchema = makeCreateNewSvPortalUserValidationSchema(config, timezoneOffset, userDateFormat)

    const formValidationSchema = useMemo(() => {

        if (!isSupervisor && isSvTypeSelected) {
            return CreateNewPortalUserValidationSchema.concat(SecurityValidationSchema.concat(SupervisorValidationSchema))
        } else if (isSupervisor) {
            return CreateNewSvPortalUserValidationSchema
        } else {
            return CreateNewPortalUserValidationSchema
        }

    }, [isSvTypeSelected, isSupervisor])

    const isTheUserEqualsCurrent = useMemo(() => {
        return (login || '').toUpperCase() === (initialValues?.login || '').toUpperCase();
    }, [login, initialValues?.login]);


    const currentUserTimeZoneName = useMemo(() => {
        return timeZonesList?.find(e => e.i_time_zone === iTimeZone)?.time_zone_name
            ?? 'Europe/Kyiv';
    }, [iTimeZone, timeZonesList]);

    const onSubmitForm = useCallback(
        (form: CreateNewPortalUserFormData, blockRedirection: boolean) => {
            // @ts-ignore
            dispatch(
                actions.editPortalUser.request({
                    // @ts-ignore
                    initialValues: {
                        ...initialValues,
                        activationDate: initialValues.activationDate
                            ? dayjs(initialValues.activationDate || '').format(
                                'YYYY-MM-DD',
                            )
                            : null,
                        expirationDate: initialValues.expirationDate
                            ? dayjs(initialValues.expirationDate || '').format(
                                'YYYY-MM-DD',
                            )
                            : null,
                    },
                    changedValues: {
                        ...form,
                        activationDate: form.activationDate
                            ? dayjs(form.activationDate || '').format(
                                'YYYY-MM-DD',
                            )
                            : null,
                        expirationDate: form.expirationDate
                            ? dayjs(form.expirationDate || '').format(
                                'YYYY-MM-DD',
                            )
                            : null,
                        // @ts-ignore
                        extension: roleList?.find(role => role.name == form.role)?.i_role_type == 7 ?
                            form.extension?.i_account : undefined
                    },
                    redirectTab: tabIndex,
                    i_individual: id,
                    blockRedirection: blockRedirection
                }),
            );
        },
        [initialValues, id, tabIndex],
    );


    useEffect(() => {
        if (!isLoading && id) {
            dispatch(actions.getPortalUserInfo.request({i_individual: id}));
        }
    }, []);

    useEffect(() => {
        if (apiErrors != undefined && tabIndex != 0 && !toastApiErrorVisible) {
            setToastApiErrorVisible(true);
        }
    }, [apiErrors]);

    const backToList = useCallback(() => {
        history.push(Routes.PortalUsers);

    }, [])

    const onDelete = useCallback(() => {
        setIsRemoveModalOpen(true);

    }, [])

    const removePortalUser = useCallback(() => {
        dispatch(
            actions.deletePortalUser.request({
                i_individual: id,
                callback: backToList,
            }),
        );
    }, [id])


    const closeRemoveModal = useCallback(() => {
        setIsRemoveModalOpen(false)
    }, []);

    if (isLoading) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="edit-ring-group-sending-loader"/>
            </div>
        )
    }

    if (apiErrors?.faultcode === USER_NOT_FOUND_API_ERROR_CODE) {
        return (
            <NotFound/>
        );
    }


    const {tabNames, tabs, perm} = generateTabs(
        [
            {
                title: t('common:settings'),
                permission:
                Permission.MyCompany.PortalUsers.PortalUsersDetails.PortalUsersSettings.value,
                tab: <PortalUserSettings
                    tabIndex={0}
                    tabName={t('common:settings')}
                    callback={
                        () => dispatch(actions.saveForms({visible: true}))
                    }
                    callback2={(flag) => setIsSvTypeSelected(flag)}
                    isSupervisor={isSupervisor}
                    roleList={roleList}
                    apiErrors={apiErrors}
                />,
                customizePermission: isSupervisor ?
                    () => i_account == editedPortlUser?.customer_individual_info?.i_account ?
                        PermissionType.Visible : PermissionType.ReadOnly : undefined
            },
            {
                title: t('screens:portalUsers.security'),
                permission:
                Permission.MyCompany.PortalUsers.PortalUsersDetails.Security.value,
                tab: (
                    <PortalUserSecurity key={'tab-2'} tabName={t('screens:portalUsers.security')} tabIndex={1}/>
                ),
                customizePermission: isSupervisor ? () => i_account == editedPortlUser?.customer_individual_info?.i_account ? PermissionType.Visible : PermissionType.ReadOnly : undefined

            },
        ],
        permissions,
    );


    return (
        <Formik
            initialValues={initialValues}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            validate={(values: any) => {
                try {
                    if ((!values.activationDate && !initialValues.activationDate) || (
                        values.activationDate && initialValues.activationDate &&
                        values.activationDate === initialValues.activationDate
                    )) {
                        values = {
                            ...values,
                            activationDate: null,
                        };
                    }
                    if ((!values.expirationDate && !initialValues.expirationDate) || (
                        values.expirationDate && initialValues.expirationDate &&
                        values.expirationDate === initialValues.expirationDate
                    )) {
                        values = {
                            ...values,
                            expirationDate: null,
                        };
                    }

                    formValidationSchema.validateSync(values, {
                        abortEarly: false,
                        context: values,
                    });
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (error: any) {
                    if (error.name !== 'ValidationError') {
                        throw error;
                    }

                    return error.inner.reduce(
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (errors: any, currentError: any) => {
                            errors = _.set(
                                errors,
                                currentError.path,
                                currentError.message,
                            );
                            return errors;
                        },
                        {},
                    );
                }

                return {};
            }}
            // @ts-ignore
            onSubmit={(form) => onSubmitForm(form, false)}
            enableReinitialize
            validateOnChange={false}
        >
            {({submitForm, dirty, values}) => (
                <>
                    <DetailsWrapper
                        tabs={tabs}
                        top={
                            <PermissionProvider
                                permission={
                                    Permission.MyCompany.PortalUsers.PortalUsersDetails.value
                                }
                            >
                                <Header
                                    backToList={backToList}
                                    // @ts-ignore
                                    initialValues={initialValues}
                                    currentUserTimeZoneName={currentUserTimeZoneName}
                                />

                            </PermissionProvider>
                        }
                        tabsNames={tabNames}
                        defaultTabIndex={tabIndex}
                        onTabChange={(_, index) => {
                            dispatch(actions.saveForms({visible: false}))
                            setTabIndex(index);
                        }}
                        showActionButtons
                        saveEnable={dirty}
                        preventIsOpen={dirty && !isRemoveModalOpen}
                        onBackPress={backToList}
                        onSavePress={() => {
                            submitForm().then(() => {
                                dispatch(actions.saveForms({visible: true}));
                                setToastApiErrorVisible(false);
                            })
                        }}
                        onDeletePress={onDelete}
                        // @ts-ignore
                        preventSavePress={() => onSubmitForm(values, true)}
                        deleteText={t(
                            'screens:portalUsers.deletePortalUserButton',
                        )}
                        deleteEnable={!isTheUserEqualsCurrent}
                        deletePermission={
                            Permission.MyCompany.PortalUsers.PortalUsersDetails.DeletePortalUser.value
                        }
                        perm={perm}
                    >
                        {isFormSaving && (
                            <Loader
                                dataQa="edit-ring-group-sending-loader"
                                absolutePosition
                            />
                        )}

                        <DeletePortalUserDialog
                            isRemoveModalOpen={isRemoveModalOpen}
                            initialValues={initialValues}
                            closeRemoveModal={closeRemoveModal}
                            removePortalUser={removePortalUser}/>

                        <ErrorNotification tabName={tabNames[tabIndex]}
                                           isSubmitting={saveClicked}/>
                        <ErrorNotification tabName={tabNames[tabIndex]}
                                           isSubmitting={toastApiErrorVisible}/>
                    </DetailsWrapper>
                </>
            )}
        </Formik>
    );
};

export default EditPortalUser;
