import React, {useMemo} from 'react';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import CustomizedCheckbox from '../../../../components/Checkbox/Checkbox';
import IconWithTooltip from '../../../../components/Tooltip/IconWithTooltip';
import {AutoPaymentsForm} from '../PaymentMethod.utils';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../../../store/types';
import {
    AutoPaymentInfo,
    OwnerPaymentMethod,
    PaymentMethodInfo,
} from '../../../../store/types/Payment';
import classNames from 'classnames';
import CustomizedTooltip from '../../../../components/Tooltip/Tooltip';
import NumericTextField from '../../../../components/NumericTextField/NumericTextField';
import Warning from '../../../../components/Warning/Warning';
import {
    GlobalCurrencyInfo,
} from '../../../../store/types/CustomerInfo';
import {usePermissionContext} from '../../../../hooks/usePermissions';
import {PermissionType} from '../../../../store/types/Permission';
import {makeStyles} from "@material-ui/core";
import {Colors} from "../../../../styles/Colors";
import WithTax from "./WithTax";
import BalanceExceed from "./BalanceExceed";


const useStyles = makeStyles(() => ({
    mainContainer: {
        maxWidth: 850,
    },
    center: {
        alignItems: 'center',
        marginTop: 24
    },
    header: {
        fontWeight: 500,
        color: Colors.Text,
        marginBottom: 22,
        display: "flex"
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 40,
    },

    checkboxContainer2Rows: {
        display: 'flex',
        marginTop: 20,
        marginBottom: 40,
        flexDirection: 'column',
        alignItems: 'flex-start'
    },

    childContainer: {
        display: 'flex'
    },

    extraMargin: {
        marginLeft: 72,
        marginTop: 24,
        display: "flex",
        alignItems: 'center'
    },

    insideText: {
        marginLeft: 16,
        marginRight: 26,
    },
    tooltipIcon: {
        marginLeft: 10,
    },
    textFieldMargin: {
        marginTop: -15,
        width: 200,
    },
    disabledText: {
        color: Colors.Gray9,
    },
    frozenWarning: {
        marginBottom: 35,
        maxWidth: 708,
    },
}));


const SvAutoPayments = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    const paymentMethod = useSelector<
        ReduxState,
        PaymentMethodInfo | undefined
    >((state) => state.billing.customerPaymentMethod);

    const ownerPaymentsMethods = useSelector<
        ReduxState,
        OwnerPaymentMethod[] | undefined
    >((state) => state.billing.ownerPaymentsMethods);

    const autoPayments = useSelector<ReduxState, AutoPaymentInfo | undefined>(
        (state) => state.billing.autoPaymentInfo,
    );

    const permission = usePermissionContext();

    const currencyInfo = useSelector<
        ReduxState,
        GlobalCurrencyInfo | undefined
    >((state) => state.generic.globalCurrency);

    const {customerInfo} = useSelector((state: ReduxState) => state.billing);

    const currentMethod = useMemo(
        () =>
            ownerPaymentsMethods?.find(
                (v) => v.i_payment_method === paymentMethod?.i_payment_method,
            ),
        [ownerPaymentsMethods],
    );

    const {
        setFieldValue,
        values,
        handleChange,
        setFieldError,
        errors,
        initialValues,
    } = useFormikContext<AutoPaymentsForm>();

    const isFormBlocked = true
    const isPaymentMethodNotIncludedInAvailableMethods =
        paymentMethod && !currentMethod && !initialValues.autoPayWithValues;


    const taxesVisible =
        customerInfo?.i_balance_control_type === 2 &&
        customerInfo?.estimate_taxes === 1 &&
        customerInfo?.inclusive_taxation === 'N';

    return (
        <CustomizedTooltip
            title={t('common:limitedPermissionToPage')}
            copy={false}
            disableHoverListener={!isFormBlocked}
        >
            <div className={classes.mainContainer}>
                <label className={classes.header}>
                    {t('screens:billing.autoPayments')}
                </label>

                <div style={{display: "flex"}}>
                    {autoPayments?.frozen === 'Y' && (
                        <Warning
                            className={classes.frozenWarning}
                            text={t(
                                'screens:billing.autoPaymentsSuspendedPrompt',
                            )}
                        />
                    )}

                    <CustomizedTooltip
                        title={permission === PermissionType.ReadOnly
                            ? t('common:noPermissionToResource')
                            : t('tooltips:billing.disabledFeature')}
                        copy={false}
                        disableHoverListener={
                            permission !== PermissionType.ReadOnly &&
                            !isPaymentMethodNotIncludedInAvailableMethods
                        }
                    >
                        <div className={classes.checkboxContainer2Rows}>
                            <div className={classes.childContainer}>
                                <div>
                                    <CustomizedCheckbox
                                        dataQa="auto-pay"
                                        label={t('screens:billing.pay')}
                                        checked={values.autoPayWithValues}
                                        onChange={() => {
                                            if (
                                                !values.autoPayWithValues &&
                                                currentMethod?.min_allowed_payment &&
                                                !values.amount
                                            ) {
                                                setFieldValue(
                                                    'amount',
                                                    currentMethod.min_allowed_payment.toString(),
                                                );
                                            } else {
                                                setFieldValue(
                                                    'amount',
                                                    initialValues.amount,
                                                );
                                                setFieldValue(
                                                    'balance',
                                                    initialValues.balance,
                                                );
                                            }

                                            setFieldValue(
                                                'autoPayWithValues',
                                                !values.autoPayWithValues,
                                            );

                                            setFieldError('amount', undefined);
                                            setFieldError('balance', undefined);
                                        }}
                                        disabled={
                                            isFormBlocked ||
                                            isPaymentMethodNotIncludedInAvailableMethods
                                        }
                                    />
                                </div>
                                <div className={classes.childContainer}>
                                    <div>

                                        <div className={classes.childContainer}>


                                            <NumericTextField
                                                id="amount"
                                                label={`${t('screens:billing.amount')}, ${
                                                    currencyInfo?.iso_4217
                                                }`}
                                                required
                                                value={values.amount}
                                                setFieldError={setFieldError}
                                                onChange={handleChange}
                                                className={classes.textFieldMargin}
                                                disabled={
                                                    isFormBlocked ||
                                                    isPaymentMethodNotIncludedInAvailableMethods ||
                                                    !values.autoPayWithValues
                                                }
                                                thousandSeparator=","
                                                decimalScale={2}
                                                min={currentMethod?.min_allowed_payment}
                                                max={9999999999.99}
                                                helperText={errors.amount}
                                            />

                                            <BalanceExceed
                                                isDisabled={
                                                    isFormBlocked ||
                                                    isPaymentMethodNotIncludedInAvailableMethods ||
                                                    permission === PermissionType.ReadOnly
                                                }
                                                balanceType={customerInfo?.i_balance_control_type}
                                            />


                                        </div>


                                        <WithTax
                                            classContainer={classes.childContainer}
                                            textContainer={classNames(classes.childContainer, classes.center)}
                                            text={values.amount}
                                            visible={taxesVisible && values.autoPayWithValues}
                                        />

                                    </div>

                                    <div className={classes.childContainer}>

                                        <NumericTextField
                                            id="balance"
                                            label={`${t('screens:billing.balance')}, ${
                                                currencyInfo?.iso_4217
                                            }`}
                                            required
                                            value={values.balance}
                                            setFieldError={setFieldError}
                                            onChange={handleChange}
                                            className={classes.textFieldMargin}
                                            disabled={
                                                isFormBlocked ||
                                                isPaymentMethodNotIncludedInAvailableMethods ||
                                                !values.autoPayWithValues
                                            }
                                            thousandSeparator=","
                                            decimalScale={2}
                                            min={currentMethod?.min_allowed_payment}
                                            max={9999999999.99}
                                            helperText={errors.balance}
                                        />

                                        <IconWithTooltip
                                            tooltipText={t(
                                                'tooltips:billing.paymentDefinedTreshold',
                                            )}
                                            className={classes.tooltipIcon}
                                        />

                                    </div>

                                </div>

                            </div>

                        </div>
                    </CustomizedTooltip>
                </div>
            </div>
        </CustomizedTooltip>
    );
};

export default SvAutoPayments;
