export default class JSONFormData extends FormData {
    constructor(session_id: string, csrf_token: string) {
        super();
        this.append('auth_info', JSON.stringify({ session_id: session_id, csrf_token: csrf_token }));

        //fix for Safari - extends does not work properly in Safari.
        this.setParams = (params: Object) =>  {
            this.delete('params');
            this.append('params', JSON.stringify(params));
        };
    }

    public setParams(params: Object) {
        this.delete('params');
        this.append('params', JSON.stringify(params));
    }
}


export class JSONAsyncFormData extends FormData {
    constructor(session_id: string, csrf_token: string) {
        super();
        this.append('auth_info', JSON.stringify({ session_id: session_id, csrf_token: csrf_token }));
        this.append('aux_info', JSON.stringify({ async: 1 }));

        //fix for Safari - extends does not work properly in Safari.
        this.setParams = (params: Object) =>  {
            this.delete('params');
            this.append('params', JSON.stringify(params));
        };
    }

    public setParams(params: Object) {
        this.delete('params');
        this.append('params', JSON.stringify(params));
    }
}