import React, { useEffect, useState } from 'react';
import DialogContainer, { DialogButton } from '../AlertDialog/DialogContainer';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import AsyncSelectField from '../AsyncSelectField/AsyncSelectField';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { SelectExtensionListItem } from '../../store/reducers/extensions/extensions/reducer';
import * as actions from '../../store/actions';
import { EditDialogProps, useStyles, loadOptionsFromBackend, getAssignedDevice } from './EditDeviceDialog.utils';
import { AssignUaRequest } from '../../store/types/Devices';
import {showErrorToast} from "../../utils/showErrorToast";

const EditDeviceDialog: React.FC<EditDialogProps> = ({
    isOpen,
    cancelClick,
    i_ua,
    account_id,
    line,
    className,
    extension_id,
    extension_name,
    toggleModal,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [inputValue, onInputChangeRaw] = useState<string>('');
    const [value, setValue] = useState<SelectExtensionListItem | undefined>();
    const i_customer: number = useSelector((state: ReduxState) => state.generic?.sessionData?.i_customer ?? 0);

    const { session_id, csrf_token } = useSelector((state: ReduxState) => state.auth);

    const onInputChange = (newInputValue: string) => {
        onInputChangeRaw(newInputValue);
    };

    const loadOptions = async (search: string, prevOptions: unknown[], additional?: { totalOffset: number }) => {
        return loadOptionsFromBackend(search, prevOptions, additional?.totalOffset ?? prevOptions.length, session_id || '', csrf_token || '', i_customer, i_ua ?? 0);
    };

    useEffect(() => {
        setValue(undefined);
    }, [isOpen]);

    useEffect(() => {
        if (isOpen && account_id && !extension_id) {
            setValue({
                label: t('common:reservedForId', { id: account_id }),
                value: account_id.toString(),
                id: account_id.toString(),
                i_c_ext: account_id.toString(),
                account_id: parseInt(account_id ?? '0'),
            });
        }
    }, [account_id, isOpen]);

    useEffect(() => {
        if (isOpen && extension_id) {
            setValue({
                label: extension_id + ((extension_name?.length ?? 0) > 0 ? ' - ' + extension_name : ''),
                value: extension_id, 
                ip_phone_name: '',
                i_c_ext: extension_id,
                id: extension_id,
                account_id: 0
            } as SelectExtensionListItem);
        }
    }, [isOpen, extension_id, extension_name]);

    const disabledSave = !value?.account_id;

    return (
        <DialogContainer
            className={className}
            isOpen={isOpen}
            dataTestId={'edit-dialog'}
            header={t('screens:devices.editLine', {
                line: line,
            })}
            dialogActionsButtons={[
                <DialogButton
                    key="no"
                    label={t('common:cancel')}
                    onClick={() => {
                        cancelClick?.();
                    }}
                />,
                <DialogButton
                    key="yes"
                    label={t('common:save')}
                    disabled={disabledSave}
                    primary           
                    onClick={async () => {
                        if (line && i_ua) {
                            const withRelease = !!account_id;
                            const assignObject: AssignUaRequest = {
                                i_account: value?.account_id ?? 0,
                                i_ua: i_ua,
                                port: line,
                                callback: toggleModal
                            };
                            if(withRelease) {
                                const objId = await getAssignedDevice(session_id || '', csrf_token || '', i_customer, i_ua, account_id, line);
                                dispatch(
                                    actions.releaseDevice.request({
                                        i_ua_link: objId,
                                        withAssign: true,
                                        onSuccess: () => {
                                            dispatch(
                                                actions.assignUA.request(
                                                    assignObject
                                                ),
                                            );
                                        },
                                      callback: ()=> {showErrorToast(t('screens:devices.lineUpdated'))}

                            }),
                                );
                            } else {
                                dispatch(
                                    actions.assignUA.request(
                                        {...assignObject, onSuccess: ()=> {showErrorToast(t('screens:devices.lineAssigned'))} }
                                    ),
                                );
                            }
                        }
                    }}
                />,
            ]}
        >
            <Grid className={classes.itemsContainer}>
                <Box className={classes.boxRow}>
                    <AsyncSelectField
                            title={t('screens:extensions.extension')}
                        value={value}
                        loadOptions={loadOptions}
                        onChange={(value) => setValue(value)}
                        onInputChange={onInputChange}
                        cacheUniqs={[inputValue]}
                    />
                </Box>
            </Grid>
        </DialogContainer>
    );
};

export default EditDeviceDialog;
