import React, {useEffect, useRef, useState} from "react";
import PermissionProvider from "../../../components/PermissionProvider/PermissionProvider";
import {Permission} from "../../../store/types/Permission";
import DetailsWrapper from "../../../components/DetailsWraper/DetailsWrapper";
import SipTrunkDetailsHeader from "./SipTrunkDetailsHeader";
import {actions} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {generateTabs} from "../../../utils/generateTabs";
import {convertBool} from "../../../utils/parseBool";
import Config from "../../../config.json";
import CallForwardingTab from "../../../components/Tabs/CallForwardingTab";
import {MainProductType} from "../../../components/Extensions/MainProductSelectFiled/utils";
import CallHistoryForm from "../../../components/Forms/Extensions/CallHistory/CallHistoryForm";
import {TabInterface} from "../../../components/Tabs/Tabs.utils";
import {useRawPermissions} from "../../../hooks/usePermissions";
import {getTabNumber} from "../../../utils/getTabNumber";
import {EditSipTrunkForm} from "./utils";
import Loader from "../../../components/Loader/Loader";
import {useStyles} from "./SipTrunkDetails.utils";
import SipTrunkTab from "./SipTrunkTab";
import {SipTrunkFormType} from "../../../components/Forms/SipTrunks/SipTrunk/SipTrunkForm.utils";
import CallSettingsTab from "./CallSettingsTab";
import PlanTab from "./PlanTab";
import history from "../../../history";
import {Routes} from "../../../routes/routes";
import CallScreeningTab from "./CallScreeningTab";
import NotFound from "../../NotFound/NotFound";
import {useSipTrunkMain} from "../../../hooks/useSipTrunkMain";
import usePageTitle from "../../../hooks/usePageTitle";
import ErrorNotification from "../../../components/TabErrors/ErrorNotification";
import {ReduxState} from "../../../store/types";
import {DetailsProps} from "../../Extensions/ExtensionDetails/utils";

interface SipTrunkDetailsPayload {
    init: Partial<SipTrunkFormType>;
    updated: Partial<SipTrunkFormType>;
}

const SipTrunkDetails: React.VFC<DetailsProps> = (
    {
        id,
        actionButtonVisible,
    }) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const permissions = useRawPermissions();

    usePageTitle();

    const [allTabsLoaded, setAllTabsLoaded] = useState(false);
    const [isFormSending, setIsFormSending] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [tabIndex, setTabIndex] = useState(
        getTabNumber([0, 1, 2, 3, 4, 5]),
    );

    const {
        notFound,
        sipTrunk,
        name,
        extension_id,
        is_blocked,
        sip_status,
        sip_agent,
        didNumbers,
        i_account,
        calls,
        bill_status,
        i_customer,
        account_id,
        um_domain,
        isSipTrunkDetailsLoaded
    } = useSipTrunkMain();

    const saveClicked = useSelector(
        (state: ReduxState) => state.tabs?.saveForms || false,
    );

    useEffect(() => {

        if (id) {
            dispatch(
                actions.getSipTrunkBasicDetailsData.request({
                    id,
                }),
            );

        }
    }, [id]);

    useEffect(() => {
        let loadingInProgress = false;

        if (isSipTrunkDetailsLoaded) {
            loadingInProgress = true;
        } else if (sipTrunk) {
            Object.keys(sipTrunk).forEach((atr: string) => {
                if (
                    // @ts-ignore
                    sipTrunk[atr] &&
                    // @ts-ignore
                    sipTrunk[atr]?.isLoading === true
                ) {
                    loadingInProgress = true;
                }
            });

            if (!loadingInProgress && !isSipTrunkDetailsLoaded) {
                setAllTabsLoaded(true);
            }
        }
    }, [isSipTrunkDetailsLoaded]);


    useEffect(() => {
        (allTabsLoaded && isDirty) || actionButtonVisible
            ? setSaveEnabled(true)
            : setSaveEnabled(false);
    }, [allTabsLoaded, isDirty]);

    useEffect(() => {
        if (!isSipTrunkDetailsLoaded && i_account && i_customer) {
            dispatch(
                actions.getSipTrunkTabData.request({
                    i_account,
                    i_customer,
                }),
            );
            dispatch(
                actions.getSipTrunkCallSettingsTabData.request({
                    id: account_id || '',
                    domain: um_domain || '',
                    i_account,
                }),
            );
            dispatch(actions.getSipTrunkPlanTabData.request({i_account}));
            dispatch(actions.getSipTrunkCallForwardingTabData.request({i_account}));
            dispatch(
                actions.getSipTrunkCallScreeningTabData.request({
                    i_account,
                    i_customer,
                }),
            );
        }
    }, [sipTrunk, isSipTrunkDetailsLoaded]);


    const formData = useRef<SipTrunkDetailsPayload>();

    const formsSubmitFuncRef = useRef<
        { [key in string]: (() => void) | undefined }
    >({
        details: undefined,
        callSettings: undefined,
        plan: undefined,
        callScreening: undefined,
        callForwarding: undefined
    });

    const formsErrorsValidationFuncRef = useRef<
        { [key in string]: (() => boolean) | undefined }
    >({
        details: undefined,
        callSettings: undefined,
        plan: undefined,
        callScreening: undefined,
        callForwarding: undefined
    });

    const formsIsDirtyFuncRef = useRef<{ [key in string]: boolean }>({
        details: false,
        callSettings: false,
        plan: false,
        callScreening: false,
        callForwarding: false
    });

    const checkAllDirtyState = () => {
        let isAnyFormDirty = false;

        Object.keys(formsIsDirtyFuncRef.current).forEach((key) => {
            if (formsIsDirtyFuncRef.current[key]) {
                isAnyFormDirty = true;
            }
        });

        isAnyFormDirty ? setIsDirty(true) : setIsDirty(false);
    };

    const setSubmitFunc = (
        funcName: keyof typeof formsSubmitFuncRef.current,
        func: () => void,
    ) => {
        formsSubmitFuncRef.current[funcName] = func;
    };


    const setFormIsValidFunc = (
        funcName: keyof typeof formsErrorsValidationFuncRef.current,
        func: () => boolean,
    ) => {
        formsErrorsValidationFuncRef.current[funcName] = func;
    };


    const handleDataFormUpdate = (form: Partial<EditSipTrunkForm>) => {
        formData.current = {
            init: {...formData.current?.init},
            updated: {...formData.current?.updated, ...form},
        };
    };

    const handleDataInitFormUpdate = (form: Partial<EditSipTrunkForm>) => {
        formData.current = {
            init: {...formData.current?.init, ...form},
            updated: {...formData.current?.updated},
        };
    };

    const setIsDirtyFunc = (
        funcName: keyof typeof formsSubmitFuncRef.current,
        isDirty: boolean,
    ) => {
        formsIsDirtyFuncRef.current[funcName] = isDirty;
        checkAllDirtyState();
    };

    const tabProps = {
        handleSubmitForm: handleDataFormUpdate,
        handleInitDataChange: handleDataInitFormUpdate,
        handleDirtyChange: setIsDirtyFunc,
        handleSetSubmitFunc: setSubmitFunc,
        handleSetIsValidFunc: setFormIsValidFunc,
    } as TabInterface;

    const tabsNameResolver = {
        details: t('screens:sipTrunks.detailsHeader'),
        callSettings: t('screens:extensions.callSettings'),
        callForwarding: t('screens:extensions.callForwarding'),
        plan: t('screens:extensions.plan'),
        callScreening: t('screens:extensions.callScreening')
    }

    const {tabNames, tabs, perm} = generateTabs(
        [
            {
                title: t('screens:sipTrunks.detailsHeader'),
                permission:
                Permission.SipTrunks.SipTrunksDetails.SipTrunk.value,
                tab: (
                    <SipTrunkTab
                        {...tabProps}
                        key={'tab1'}
                        prefixEnabled={convertBool(Config.PREFIX_DID_ENABLED) || false}
                        isActive={tabIndex == 0}
                        tabName={t('screens:sipTrunks.detailsHeader')}
                        tabIndex={0}
                        callback={() => {
                            markTabAsHaveError(t('screens:sipTrunks.detailsHeader'), tabNames.indexOf(t('screens:sipTrunks.detailsHeader')), true);
                            dispatch(actions.saveForms({visible: true}));
                        }}
                    />),
            },
            {
                title: t('screens:extensions.callSettings'),
                permission:
                Permission.SipTrunks.SipTrunksDetails.CallSettings.value,
                tab: <CallSettingsTab
                    {...tabProps} key={'tab2'}
                    isActive={tabIndex == 1}
                    tabName={t('screens:extensions.callSettings')}
                />,
            },
            {
                title: t('screens:extensions.callScreening'),
                permission:
                Permission.SipTrunks.SipTrunksDetails.CallScreening.value,
                tab: (
                    <CallScreeningTab
                        {...tabProps}
                        extensionNumber={extension_id}
                        key={'tab3'}
                        showPBX={false}
                        isActive={tabIndex == 2}
                        tabName={t('screens:extensions.callScreening')}
                    />
                ),
            },
            {
                title: t('screens:extensions.callForwarding'),
                permission:
                Permission.SipTrunks.SipTrunksDetails.CallForwarding.value,
                tab: <CallForwardingTab
                    {...tabProps} key={'tab4'}
                    isActive={tabIndex == 3}
                    tabName={t('screens:extensions.callForwarding')}
                />,
            },
            {
                title: t('screens:extensions.plan'),
                permission:
                Permission.SipTrunks.SipTrunksDetails.Plan.value,
                tab: (
                    <PlanTab
                        {...tabProps}
                        productType={MainProductType.SipTrunk}
                        key={'tab5'}
                        isActive={tabIndex == 4}
                        tabName={t('screens:extensions.plan')}
                    />
                ),
            },
            {
                title: t('screens:calls.callHistory'),
                permission:
                Permission.SipTrunks.SipTrunksDetails.CallHistory.value,

                tab: (
                    <CallHistoryForm
                        accountId={i_account}
                        key={'tab7'}
                        deletePermission={
                            Permission.SipTrunks.SipTrunksDetails.CallHistory.CallDetailRecord.DeleteCallRecording.value
                        }
                        downloadPermission={
                            Permission.SipTrunks.SipTrunksDetails.CallHistory.CallDetailRecord.DownloadCallRecording.value

                        }
                        recordPermission={
                            Permission.CloudPBX.Extensions.ExtensionDetails
                                .CallHistory.CallDetailRecord.value
                        }
                        downloadTranscriptionPermission={
                            Permission.SipTrunks.SipTrunksDetails.CallHistory.CallDetailRecord.DownloadCallRecording.value
                        }
                    />
                ),
            }
        ],
        permissions
    );

    const submitAllForms = () => {
        Object.keys(formsSubmitFuncRef.current).forEach((key) => {
            formsSubmitFuncRef.current?.[key]?.();
        });
    };

    const markTabAsHaveError = (tabName: string, tabIndex: number, setTabError: boolean) => {
        dispatch(actions.setErrorInTab({name: tabName, index: tabIndex, markAsInvalid: setTabError}))
    }

    const areAllFormsValid = () => {
        let formsAreValid = true;
        Object.keys(formsErrorsValidationFuncRef.current).forEach((key) => {
            let setTabError: boolean;
            if (
                formsErrorsValidationFuncRef.current[key] !== undefined &&
                !formsErrorsValidationFuncRef.current[key]?.()
            ) {
                formsAreValid = false;
                setTabError = true;

            } else {
                setTabError = false

            }

            // @ts-ignore
            markTabAsHaveError(tabsNameResolver[key], tabNames.indexOf(tabsNameResolver[key]), setTabError);

        });
        return formsAreValid;
    };

    const onSubmitSipTrunkDetails = (blockRedirection?: boolean) => {
        submitAllForms();
        setIsFormSending(true)

        let formsAreValid = true;
        setTimeout(() => {
            formsAreValid = areAllFormsValid();
        }, 0);

        setTimeout(() => {
            if (formsAreValid) {
                setTimeout(() => {
                    if (formData.current) {
                        const {init, updated} = formData.current;
                        if (i_account && account_id) {
                            dispatch(
                                actions.editSipTrunk.request({
                                    accountId: i_account,
                                    // @ts-ignore
                                    initialValues: init,
                                    // @ts-ignore
                                    changedValues: updated,
                                    redirectTab: tabIndex,
                                    id: account_id,
                                    domain: um_domain || '',
                                    blockRedirection: blockRedirection,
                                    callback: () => setIsFormSending(false)

                                }),
                            );
                        }
                    }
                }, 0);
            } else {
                setIsFormSending(false)
                dispatch(actions.saveForms({visible: true}))
            }
        }, 1000);
    };

    const clearDetails = () => {
        history.push(Routes.SipTrunks);
    };

    const onTabChange = (_: React.ChangeEvent<{}>, index: number) => {
        dispatch(actions.saveForms({visible: false}))
        areAllFormsValid();
        setTabIndex(index);
    }

    if (notFound) {
        return (
            <NotFound/>
        );
    }

    if (isSipTrunkDetailsLoaded) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="sip-trunk-loader" dataTestId={'sip-trunk-loader'}/>
            </div>
        );
    }

    return (
        <>
            <DetailsWrapper
                showActionButtons
                top={
                    <PermissionProvider
                        permission={Permission.SipTrunks.SipTrunksDetails.value}
                    >
                        <SipTrunkDetailsHeader
                            name={name}
                            extension_id={extension_id ?? id}
                            is_blocked={is_blocked}
                            sip_status={sip_status}
                            sip_agent={sip_agent}
                            didNumbers={didNumbers}
                            i_account={i_account}
                            calls={calls}
                            bill_status={bill_status}
                        />
                    </PermissionProvider>
                }
                headerCustomClass={classes.headerContainer}
                tabs={tabs}
                saveEnable={saveEnabled}
                preventIsOpen={(sipTrunk !== undefined) && isDirty}
                preventSavePress={() => onSubmitSipTrunkDetails(true)}
                onTabChange={onTabChange}
                tabsNames={tabNames}
                defaultTabIndex={tabIndex}
                onSavePress={() => onSubmitSipTrunkDetails(false)}
                onBackPress={clearDetails}
                deleteEnable
                perm={perm}
                actionsCustomClass={classes.actionsCustomClass}
            >
                {isFormSending && (
                    <Loader
                        dataQa="sip-trunk-form-data-sending-loader"
                        absolutePosition
                        dataTestId={'send-data-loader'}
                    />
                )}
                <ErrorNotification tabName={tabNames[tabIndex]} isSubmitting={saveClicked}/>

            </DetailsWrapper>
        </>
    )
}


export default SipTrunkDetails;
