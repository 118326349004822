import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ExtensionType} from '../../../store/types/Extension';
import {WidgetCallVolumesTimeRange} from "../../../store/types/Wallboard";
import {useDispatch} from "react-redux";
import {actions} from "../../../store";

import BarLoader from "../../../components/BarLoader/BarLoader";

export interface LastRowInterface {
    extension: ExtensionType;
    darkMode: boolean;
    id?: string;
    period?: WidgetCallVolumesTimeRange;
    isLoadingChart?: boolean;
    fetchMore: () => void
}

const LastRow: React.VFC<LastRowInterface> = (
    {
        extension,
        id,
        period,
        isLoadingChart,
        fetchMore
    }) => {

    const elementRef = useRef(null);
    const [fetchData, setFetchData] = useState<boolean>(false)


    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    fetchMore()
                }
            });
        });

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, [id, period, fetchData],);


    useEffect(() => {
        setFetchData(false);
    }, [period, isLoadingChart]);


    return (
        <div ref={elementRef}>
            <BarLoader
                dataQa={
                    'fetch-calls-loader-' + extension.extension_id
                }
            />
        </div>
    )

};

export default LastRow;