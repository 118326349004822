import React from 'react';
import {ExtensionsListItem} from '../../store/reducers/extensions/extensions/reducer';
import CallerInfo from '../Calls/CallerInfo';
import {Column} from 'react-table';
import {CloudDownload} from '@material-ui/icons';
import {
    convertFormatFromBackendToDayJs,
    convertToUserFormat
} from '../../utils/dateWithTimezoneConversion';
import {Colors} from '../../styles/Colors';
import * as Yup from 'yup';
import {TFunction} from 'react-i18next';
import {makeStyles} from '@material-ui/core';
import dayjs from '../../services/customDayJs';
import {CallHistory} from '../../store/types/CallHistory';
import CustomizedIconButton from '../../components/IconButton/IconButton';
import {prepareCallRecordName} from '../../utils/calls/prepareCallRecordName';
import i18n from '../../services/i18n';
import DateTimeCell from '../Calls/DateTimeCell';
import PermissionProvider from '../PermissionProvider/PermissionProvider';

export type CallHistoryFormProps = {
    from: string;
    till: string;
    cli: string;
    cld: string;
    limit?:number
};
export const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const emptyDateError = i18n.t('errors:ringGroups.emptyInput');
const wrongFormat = i18n.t('errors:ringGroups.wrongFormat');

const dateTestFrom = Yup.string()
    .required(emptyDateError)
    .test('is-valid-date', wrongFormat, (v) =>
        dayjs(new Date(v?.replace(/-/g, '/') || '')).isValid(),
    );

const dateTestTill = Yup.string()
    .required(emptyDateError)
    .test('is-valid-date', wrongFormat, (v) =>
        dayjs(new Date(v?.replace(/-/g, '/') || '')).isValid(),
    );

export const dateFromDateTillValidationSchema = (timezoneOffset: number, userDateTimeFormat: string) => {
    return Yup.object().shape({
        from: dateTestFrom.test(
            'dobY',
            '',
            (value, ctx) => {
                const from = value
                    ? dayjs(value, dateFormat).toDate()
                    : null;
                const till = Yup.ref('till')
                    ? dayjs(
                        ctx.parent.till,
                        dateFormat,
                    ).toDate()
                    : null;

                // @ts-ignore
                return !ctx.parent.till || from < till ? true
                    : ctx.createError({
                        message: i18n.t<string>('errors:common.enterDateBefore', {
                            value: convertToUserFormat(
                                ctx.parent.till,
                                convertFormatFromBackendToDayJs(userDateTimeFormat || dateFormat)),
                        }),
                        path: 'from',
                    });
            },
        ),
        till: dateTestTill
    })
};

export const dateFromDateTillValidationSchema2 = () => {
    return Yup.object().shape({
        beforeFilter: Yup.date().typeError(wrongFormat),
        afterFilter:Yup.date().typeError(wrongFormat),
        coveringFilter:Yup.date().typeError(wrongFormat)
    })
};

export interface CallHistoryDialogProps {
    isOpen: boolean;
    toggleVisibility?: () => void;
    accountId?: number;
    config?: ExtensionsListItem | null;
    customHeader?: string;
    downloadRecordingPermission?: string[];

    hiddeName?: boolean
}

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        display: 'flex',
        alignItems: 'flex-start',

        '& .MuiFormControl-root': {
            marginRight: 15,
            width: 209,
        },
    },
    inputs: {
        '& .MuiFormControl-root:first-of-type': {
            marginRight: 90,
        },

        '& .MuiInputBase-root': {
            height: 60,
            marginTop: 0,
        },
    },
    primaryActionButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },
    },
    modalContainer: {
        '& .MuiDialogContent-root': {
            background: Colors.SmokeBackground,
            width: 1002,
            padding: '36px 32px',
            overflowX: 'hidden'
        },
    },
    button: {
        '& .MuiButton-label': {
            color: Colors.White,
        },
        marginTop: 10,
    },
    table: {
        marginTop: 30,

        '& .MuiTableBody-root': {
            '& .MuiTableRow-root': {
                height: 66,
                display: 'flex',
                alignItems: 'center',
            },
        },
    },
    pagination: {
        color: Colors.Gray,
    },
    centeredChargeValue: {
        paddingLeft: '0!important',
        marginLeft: 50,
    },
    centeredRecordingValue: {
        paddingLeft: '0!important',
        marginLeft: 10,
    },
    headerCell: {
        fontSize: 16,
    },
    filterSeparation: {
        paddingBottom: 25,
    },
    connectTime: {
        color: Colors.Gray5,
        fontSize: 14,
    },
    timePicker: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0,
            marginRight: 0,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            fontSize: '0.73rem'
        },
    }
}));

export const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    timezoneOffset: number,
    customerCurrency: string,
    downloadFile: (
        i_xdr: number,
        call_recording_id: string,
        fileName: string,
        index: number,
    ) => void,
    userDateTimeFormat?: string,
    isDownloadingGoingOn?: boolean[],
    downloadRecordingPermission?: string[],
): Column<CallHistory>[] => {
    return [
        {
            accessor: 'connect_time',
            Header: t<string>('screens:calls.connectTime'),
            width: 0.8,
            Cell: function Cell(params) {
                return (
                    <DateTimeCell
                        timezoneOffset={timezoneOffset}
                        time={params.row.original.connect_time as string}
                        customDateFormat={userDateTimeFormat}
                    />
                );
            },
        },
        {
            accessor: 'CLI',
            Header: t<string>('screens:calls.callingNumber'),
            width: 0.8,
            Cell: function Cell(params) {
                if(params.row.original?.CLI)
                return (
                    <CallerInfo
                        caller={params.row.original.CLI}
                        headerClassName={classes.headerCell}
                    />
                );
                return <></>;
            },
        },
        {
            accessor: 'CLD',
            Header: t<string>('screens:calls.calledNumber'),
            width: 1,
            Cell: function Cell(params) {
                if(params.row.original?.CLD)
                return (
                    <CallerInfo
                        caller={params.row.original.CLD}
                        headerClassName={classes.headerCell}
                    />
                );
                return <></>;
            },
        },
        {
            accessor: 'charged_quantity',
            Header: t<string>('screens:calls.duration'),
            width: 0.5,
            Cell: function Cell(params) {
                return (
                    <span style={{marginLeft: '-6px'}}>
                        {params.row.original?.charged_quantity
                            ? new Date(
                                params.row.original.charged_quantity * 1000,
                            )
                                .toISOString()
                                .substr(11, 8)
                            : ''}
                    </span>
                );
            },
        },
        {
            accessor: 'charged_amount',
            Header:
                t('screens:calls.charge') +
                (customerCurrency ? `, ${customerCurrency}` : ''),
            width: 0.6,
            Cell: function Cell(params) {
                return (
                    <div className={classes.centeredChargeValue}>
                        <span>
                            {params.row.original.charged_amount?.toFixed(2)}
                        </span>
                    </div>
                );
            },
        },
        {
            accessor: 'cr_download_ids',
            Header: t<string>('screens:calls.recording'),
            width: 1,
            maxWidth: 100,
            minWidth: 100,
            Cell: function Cell(params) {
                const downloadId = params.row.original.cr_download_ids?.[0];
                const index = params.row.index;

                return (
                    <div className={classes.centeredRecordingValue}>
                        {!params.row.original.cr_download_ids?.[0] ? (
                            <span style={{marginLeft: 10}}/>
                        ) : (
                            <PermissionProvider
                                permission={downloadRecordingPermission || []} skipReadOnly
                            >
                                <CustomizedIconButton
                                    tooltipText={t(
                                        'tooltips:ringGroups.downloadRecording',
                                    )}
                                    dataQa="detail-download-icon"
                                    dataTestId="download-icon"
                                    onClick={() =>
                                        downloadFile(
                                            params.row.original.i_xdr as number,
                                            downloadId || '',
                                            prepareCallRecordName(
                                                params.row.original,
                                                timezoneOffset,
                                            ),
                                            index,
                                        )
                                    }
                                    disabled={
                                        !(
                                            downloadId &&
                                            !isDownloadingGoingOn?.[
                                                params.row.index
                                                ]
                                        )
                                    }
                                >
                                    <CloudDownload/>
                                </CustomizedIconButton>
                            </PermissionProvider>
                        )}
                    </div>
                );
            },
        },
    ];
};
