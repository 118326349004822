import classNames from 'classnames';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import OverflowTooltip from '../../../components/OverflowTooltip/OverflowTooltip';
import {ExtensionType} from '../../../store/types/Extension';
import {makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Colors} from '../../../styles/Colors';
import {WidgetCallVolumesTimeRange} from "../../../store/types/Wallboard";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../../store";
import {ReduxState} from "../../../store/types";
import {getDurationFromSec} from "../../../utils/transformers";
import BarLoader from "../../../components/BarLoader/BarLoader";

export interface LazyCallVolumesWidgetListViewItem {
    extension: ExtensionType;
    numberOfCalls: boolean;
    darkMode: boolean;
    id?: string;
    period?: WidgetCallVolumesTimeRange;
    isLoadingChart?: boolean;
    hideWhenNoCalls?: boolean;
}

const useStyles = makeStyles(() => ({
    hide: {
        display: 'none !important'
    },
    listViewRow: {
        width: 'calc(100% - 16px)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 44,
        alignItems: 'center',
    },
    listViewRowSeparator: {
        display: 'block',
        left: 0,
        bottom: 0,
        height: 1,
        width: 'calc(100% - 40px - 16px)',
        marginLeft: 40,
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    },
    listViewIconText: {
        height: 'fit-content',
        width: '100%',
        fontWeight: 700,
        fontSize: 14,
        color: Colors.Text,
        marginTop: 'calc(50% - 14px)',
    },
    listViewIconTextDarkMode: {
        color: '#F5F7F8'
    },
    listViewIconContainer: {
        marginRight: 14,
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 20,
        backgroundColor: Colors.Secondary11,
        marginTop: 2,
        minWidth: 40,
    },
    listViewIconContainerDarkMode: {
        backgroundColor: '#494A55'
    },
    listViewNameText: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '19px',
        color: Colors.Text2,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        flex: 1,
        textAlign: 'left',
        minWidth: '30%',
    },
    listViewNameTextDarkMode: {
        color: '#F5F7F8'
    },
    noName: {
        opacity: 0.5
    },
    listViewDurationText: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: Colors.Text,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    listViewDurationTextDarkMode: {
        color: '#DDE1E7',
        fontSize: 13
    },
    callsCounter: {
        marginLeft: 10,
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '16px',
        color: Colors.Text,
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    callsCounterDarkMode: {
        color: '#F5F7F8'
    },
}));

const LazyCallVolumesWidgetListViewItem: React.VFC<LazyCallVolumesWidgetListViewItem> = (
    {
        extension,
        numberOfCalls,
        darkMode,
        id,
        period,
        isLoadingChart,
        hideWhenNoCalls
    }) => {

    const {t} = useTranslation();
    const classes = useStyles();
    const customTransactionRefreshData = useSelector((state: ReduxState) => state.wallboard.customTransactionRefreshData);
    const isFetching = useRef(false);

    const calcExtNameFontSize = (name: string) => {
        if (name.length <= 6) return 20 - ((name.length - 1) * 2);
        if (name.length <= 10) return 9 - (name.length - 7);
        return '6px';
    };
    const dispatch = useDispatch();
    const elementRef = useRef(null);

    const [componentState, setComponentState] = useState<{ forceShow: boolean, fetchData: boolean }>({
        forceShow: false,
        fetchData: false
    })

    const fetch = useCallback(() => {
        id && period && !componentState.fetchData && isFetching.current && dispatch(actions.fetchCallDataPerExtensionWidgetItem.request({
            i_account: extension.i_account,
            id,
            period,
            callback: () => {
                setComponentState({forceShow: false, fetchData: true})
                isFetching.current = false
            }
        }))

    }, [id, period, componentState.fetchData])

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    fetch()
                }
            });
        });

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, [id, componentState]);

    const previousValue = useRef(period);
    const element = customTransactionRefreshData.find(el => el.id == id)
    const onlyZero = !element?.details.sum && !element?.details.duration

    useEffect(() => {
        if(isLoadingChart && !isFetching.current)
        {
            setComponentState({forceShow: true, fetchData: false})
            isFetching.current = true
        }

        if(period !== previousValue.current && !isFetching.current)
        {
            setComponentState({forceShow: true, fetchData: false})
            previousValue.current = period
            isFetching.current = true
        }
    }, [period, isLoadingChart]);

    return (
        <>
            <div
                id={(hideWhenNoCalls && (element?.details?.sum ?? 0) === 0 && !componentState.forceShow) ? undefined : id}
                ref={elementRef}
                className={classNames(hideWhenNoCalls && (element?.details?.sum ?? 0) === 0 && !componentState.forceShow ? classes.hide : classes.listViewRow)}>
                <div
                    className={classNames(classes.listViewIconContainer, darkMode && classes.listViewIconContainerDarkMode)}>
                    <div className={classNames(classes.listViewIconText, darkMode && classes.listViewIconTextDarkMode)}
                         style={{
                             fontSize: calcExtNameFontSize(extension.extension_id || extension.id)
                         }}>
                        {extension.extension_id || extension.id}
                    </div>
                </div>
                <div
                    className={classNames(classes.listViewNameText, !extension.extension_name?.length && classes.noName, darkMode && classes.listViewNameTextDarkMode)}>
                    <OverflowTooltip
                        tooltip={(extension.extension_name?.length ?? 0) > 0 ? extension.extension_name : t('common:unnamed')}
                        text={(extension.extension_name?.length ?? 0) > 0 ? extension.extension_name : t('common:unnamed')}
                        classes={{
                            text: classNames(classes.listViewNameText,
                                !extension.extension_name?.length && classes.noName,
                                darkMode && classes.listViewNameTextDarkMode
                            )
                        }}
                        dataQa={"tooltip-list-view-name"}
                        copy={false}
                    />
                </div>

                {
                    !element?.loading ? onlyZero ? <>0</> : (
                        <>
                            <div
                                className={classNames(classes.listViewDurationText, darkMode && classes.listViewDurationTextDarkMode)}>
                                <OverflowTooltip
                                    tooltip={getDurationFromSec(element?.details?.duration || 0).string}
                                    text={getDurationFromSec(element?.details?.duration || 0).string}
                                    classes={{text: classNames(classes.listViewDurationText, darkMode && classes.listViewDurationTextDarkMode)}}
                                    dataQa={"tooltip-list-view-duration"}
                                    copy={false}
                                />
                            </div>
                            {numberOfCalls && (
                                <div
                                    className={classNames(classes.callsCounter, darkMode && classes.callsCounterDarkMode)}>
                                    {element?.details.sum}
                                </div>
                            )}
                        </>

                    ) : <BarLoader
                        dataQa={
                            'fetch-calls-loader-' +
                            extension.extension_id
                        }
                    />
                }
            </div>
            <div
                className={classNames(hideWhenNoCalls && (element?.details?.sum ?? 0) === 0 ? classes.hide : classes.listViewRowSeparator)}>
            </div>
        </>
    );
};

export default LazyCallVolumesWidgetListViewItem;