import classNames from "classnames";
import {CustomerBillingType} from "../../../../store/types/CustomerInfo";
import React from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import {Colors} from "../../../../styles/Colors";

const useStyles = makeStyles(() => ({
    disabledText: {
        color: Colors.Gray9,
    },
    insideText: {
        marginLeft: 16,
        marginRight: 26,
    },
}))

const BalanceExceed: React.VFC<{
    isDisabled: boolean,
    balanceType?: CustomerBillingType,
}> = ({isDisabled, balanceType}) => {

    const {t} = useTranslation()
    const classes = useStyles()

    const text = balanceType === CustomerBillingType.Postpaid
        ? t('screens:billing.whenBalanceExceed')
        : t('screens:billing.whenBalanceDropsBelow')

    const customClass = classNames(
        classes.insideText,
        isDisabled && classes.disabledText,
    )

    return <span className={customClass}>{text}</span>


}

export default BalanceExceed