import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import {ReactComponent as BadgeTop} from '../../../assets/BadgeTop.svg';
import {ReactComponent as BadgeBottom} from '../../../assets/BadgeBottom.svg';
import classNames from 'classnames';
import CustomizedTooltip from '../../Tooltip/Tooltip';
import AnimatedNumberText from "../../AnimatedNumber/AnimatedNumber";
import {useStyles} from "./CallsTodayInfo.styles";
import {numberWithSpaces} from "./CallsTodayInfo.utils";


const CallsTodayInfo: React.VFC = () => {
    const {t} = useTranslation();
    const classes = useStyles();

    const {todayCalls, yesterdayCalls, getCallHistoryBasicDataRefresh} = useSelector<
        ReduxState,
        { todayCalls: number; yesterdayCalls: number; getCallHistoryBasicDataRefresh: boolean }
    >((state) => state.calls);

    const difference = todayCalls - yesterdayCalls;

    const tooltipText = t('tooltips:calls.comparedToPrevousDay', {
        value: numberWithSpaces(yesterdayCalls),
    }).split('(');

    return (
        <div className={classNames(classes.container, getCallHistoryBasicDataRefresh && classes.opacity)}>
            <span className={classes.header}>
                {t('screens:calls.totalCallsToday')}
            </span>

            <div className={classes.valueTextContainer}>
                <span className={classes.valueText}>
                        <AnimatedNumberText
                            value={todayCalls}
                            showLongDash={getCallHistoryBasicDataRefresh}
                        />
                </span>

                {difference !== 0 && (
                    <CustomizedTooltip
                        title={
                            <span>
                                {tooltipText[0]}
                                <span className={classes.boldText}>
                                    ({tooltipText[1]}
                                </span>
                            </span>
                        }
                        copy={false}
                    >
                        <div
                            className={classNames(
                                classes.badge,
                                difference < 0 && classes.negativeBadge,
                            )}
                        >
                            {difference < 0 ? (
                                <BadgeBottom data-testid="arrow-down"/>
                            ) : (
                                <BadgeTop data-testid="arrow-up"/>
                            )}
                            <span
                                className={classes.badgeText}
                                data-testid="calls-delta"
                            >
                                {numberWithSpaces(Math.abs(difference))}
                            </span>
                        </div>
                    </CustomizedTooltip>
                )}
            </div>
        </div>
    );
};

export default CallsTodayInfo;
