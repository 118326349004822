import React from "react";
import PermissionLinkCell from "../DataTable/PermissionLinkCell";
import {ReactComponent as LinkIcon} from '../../assets/link.svg';
import {makeStyles} from "@material-ui/styles";
import history from "../../history";
import {Colors} from "../../styles/Colors";
import classNames from 'classnames';
import {PermissionType} from "../../store/types/Permission";

const useStyles = makeStyles(() => ({
    container: {
        position: "relative",
        width: '200px'
    },

    link: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '16px',

    },

    normal: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '16px',
        color: Colors.Text3,
    },


    position: {
        position: 'absolute',
        top: '-6px',
    }

}))

const RingGroupCell: React.VFC<{ title: string, link: string }> = ({title, link}) => {

    const classes = useStyles();

    const linkComponent = (
        <PermissionLinkCell
            text={title}
            onClick={() => history.push(link)}
            // @ts-ignore
            icon={<LinkIcon/>}
            className={classNames(classes.link, classes.position)}
            customizePermission={() => PermissionType.Visible}
        />
    );

    return link ? <div className={classes.container}>{linkComponent}</div> :
        (
            <div className={classes.container}>
                <div className={classNames(classes.normal, classes.position)}>{title}</div>
            </div>
        )
}

export default RingGroupCell;