import React, {useMemo, useState} from "react";
import {useTranslation} from 'react-i18next';
import CreationButton from "../../../CreationButton/CreationButton";
import {ReduxState} from "../../../../store/types";
import {useSelector} from "react-redux";
import RowExpander from "../../../Expander/RowExpander";
import DataGrid from "../../../DataGrid/DataGrid";
import {PeriodInfo} from "../../../../store/types/AccountFollowMeSettings";
import {CPConditionInfo} from "../../../../store/types/CallScreening";
import classNames from "classnames";
import {usePermissionContext} from "../../../../hooks/usePermissions";
import {TimeFiltersPageFilterType} from "./NewTimeFilterDialog.utils";
import NewTimeFilterDialog from "./NewTimeFilterDialog";
import NewIntervalDialog from "./NewIntervalDialog";
import {
    generateColumnsFromTo,
    generateColumnsTimeIntervals,
    TimeFiltersTabProps,
    useStyles
} from "./TimeFiltersTab.utils";

const TimeFiltersTab: React.VFC<TimeFiltersTabProps> = ({
    pageFilterType,
    onDelete,
    onDeleteInterval
}) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const permission = usePermissionContext();

    const policies = useSelector(
        (state: ReduxState) => state.extensions?.callProcessingPolicyList || [],
    );
    
    const { cpConditionsList } = useSelector(
        (state: ReduxState) => state.callSettings,
    );
    
    const [showTimeFilterDialog, setShowTimeFilterDialog] = useState<{
        isOpen: boolean;
        editObject?: CPConditionInfo;
        filterType: TimeFiltersPageFilterType;
    }>({
        isOpen: false,
        filterType: TimeFiltersPageFilterType.TimeWindow
    });
    
    const [showIntervalDialog, setShowIntervalDialog] = useState<{
        isOpen: boolean;
        cpCondition: CPConditionInfo;
        editObject?: PeriodInfo;
    }>({
        isOpen: false,
        cpCondition: {}
    });
    
    const outTimeFormat = useSelector<ReduxState, string | undefined>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_time_format,
    );

    const ampm = outTimeFormat?.includes('AM');

    const isFilterInUse = (filter: CPConditionInfo) => {
        if(!policies?.length) return false;
        for(const policy of policies) {
            if(!policy?.rule_list?.length) continue;
            for(const rule of policy.rule_list) {
                const usedIds = [
                    rule.from_number_i_cp_condition || 0,
                    rule.to_number_i_cp_condition || 0,
                    rule.time_window_i_cp_condition || 0
                ];
                if(usedIds.includes(filter.i_cp_condition || -1)) {
                    return true;
                }
            }
        }
        return false;
    };
    
    const columnsTimeIntervals = generateColumnsTimeIntervals(
        t,
        permission,
        (object: PeriodInfo, rowId: string) => {
            const indexIds = rowId.split('_');
            const index = parseInt(indexIds[1] + '');
            const cpCondition = cpConditionsList?.cp_condition_list.find(e => e.i_cp_condition === index);
            if(cpCondition) {
                setShowIntervalDialog({
                    isOpen: true,
                    editObject: object,
                    cpCondition: cpCondition
                });
            }
        },
        (interval: PeriodInfo, rowId: string) => {
            const indexIds = rowId.split('_');
            const index = parseInt(indexIds[1] + '');
            const cpCondition = cpConditionsList?.cp_condition_list.find(e => e.i_cp_condition === index);
            if(cpCondition) {
                onDeleteInterval?.(interval, cpCondition);
            }
        },
        ampm
    );

    const columnsFromTo = generateColumnsFromTo(
        t,
        classes,
        permission,
        isFilterInUse,
        (object: CPConditionInfo) => {
            const type = object.type?.toString() || TimeFiltersPageFilterType.FromNumber.toString();
            setShowTimeFilterDialog({
                isOpen: true,
                editObject: object,
                filterType: type as TimeFiltersPageFilterType
            });
        },
        onDelete
    );
    
    const conditions = useMemo(() => {
        return (
            cpConditionsList?.cp_condition_list?.filter((v) => v.type === pageFilterType.toString()) ||
            []
        );
    }, [cpConditionsList]);
    
    const onClickAddIntervalHandler = (condition: CPConditionInfo) => {
        setShowIntervalDialog({
            isOpen: true,
            editObject: undefined,
            cpCondition: condition
        });
    };

    return (
        <div className={classNames(classes.pageContainer, (pageFilterType !== 'TimeWindow'))}
        >
            {pageFilterType === TimeFiltersPageFilterType.TimeWindow ? (<CreationButton
                data-qa="add-filter-button"
                onClick={() => {
                    setShowTimeFilterDialog({
                        isOpen: true,
                        editObject: undefined,
                        filterType: TimeFiltersPageFilterType.TimeWindow
                    });
                }}
                className={classes.addFilterButton}
                title={t('screens:callSettings.addFilter')}
            />) : (<></>)}
            {pageFilterType === TimeFiltersPageFilterType.TimeWindow ? (<div>
                    {conditions.map((condition, indx) => {
                        return (
                            <RowExpander key={'time_window' + indx}
                                title={condition.name}
                                isOpenedOnInit={false}
                                badgeCount={condition.time_window?.definition_list?.length || 0}
                                className={classes.timeIntervalCollapseContainer}
                                showUnderline={indx !== (conditions.length - 1)}
                                onDelete={() => {
                                    onDelete(condition);
                                }}
                                hideDelete={isFilterInUse(condition)}
                                onEdit={() => {
                                    setShowTimeFilterDialog({
                                        isOpen: true,
                                        editObject: condition,
                                        filterType: pageFilterType
                                    });
                                }}
                            >
                                <DataGrid<PeriodInfo>
                                    classes={{
                                        tableContainer: classNames(classes.tableContainer, classes.tableInsideRowExpander),
                                    }}
                                    columns={columnsTimeIntervals}
                                    data={condition.time_window?.definition_list || []}
                                    rowCount={condition.time_window?.definition_list?.length || 0}
                                    pageSizeOptions={[condition.time_window?.definition_list?.length || 0]}
                                    initialPageSize={Number.MAX_VALUE}
                                    centeredRows
                                    hideFooter
                                    getItemId={(r, i) => 'cp_' + condition.i_cp_condition + '_interval_' + i}
                                />
                                <CreationButton
                                    data-qa="add-interval-button"
                                    className={classes.addIntervalButton}
                                    onClick={() => onClickAddIntervalHandler(condition)}
                                    title={t('screens:callSettings.addInterval')}
                                />
                            </RowExpander>
                        );
                    })}
                </div>) : (
                    <DataGrid<CPConditionInfo>
                        classes={{
                            tableContainer: classes.tableContainer,
                        }}
                        columns={columnsFromTo}
                        data={conditions}
                        rowCount={conditions.length || 0}
                        pageSizeOptions={[conditions.length || 0]}
                        initialPageSize={Number.MAX_VALUE}
                        centeredRows
                        hideFooter
                        getItemId={(r) => (r.i_cp_condition || 0) + ''}
                    />
                )
            }
            {pageFilterType !== TimeFiltersPageFilterType.TimeWindow ? (<CreationButton
                data-qa="add-filter-button"
                onClick={() => {
                    setShowTimeFilterDialog({
                        isOpen: true,
                        editObject: undefined,
                        filterType: pageFilterType
                    });
                }}
                className={classes.addNumberButton}
                title={t('screens:callSettings.addNumber')}
            />) : (<></>)}
            <NewTimeFilterDialog
                isOpen={showTimeFilterDialog.isOpen}
                clickOnClose={() => {
                    setShowTimeFilterDialog(prev => ({
                        ...prev,
                        isOpen: false
                    }));
                }}
                editObject={showTimeFilterDialog.editObject}
                filterType={showTimeFilterDialog.filterType}
            />
            <NewIntervalDialog
                isOpen={showIntervalDialog.isOpen}
                clickOnClose={() => {
                    setShowIntervalDialog(prev => ({
                        ...prev,
                        isOpen: false
                    }));
                }}
                editObject={showIntervalDialog.editObject}
                cpCondition={showIntervalDialog.cpCondition}
                disableRestoreFocus={true}
            />
        </div>
    )
}

export default TimeFiltersTab;