import { createAction, createAsyncAction } from 'typesafe-actions';
import { APIErrorInterface } from '../../types';
import { ApiFile } from '../../types/ApiFile';
import { ExtensionType } from '../../types/Extension';
import {RingGroupType} from '../../types/RingGroup';
import {
    AddRingGroupRequest,
    ChangeCallQueueStatusRequest,
    ChangeCallQueueStatusResponse,
    EditRingGroupRequest,
    GroupMembersDetailsSuccessPayload,
    RemoveRingGroupPayload,
    RingGroupDetailsRequest,
    RingGroupListRequestPayload,
    RingGroupListResponsePayload,
    UploadRingbackToneRequestPayload,
    CreateRingGroupRequest,
    GetHuntGroupListRequest,
    GetSipCallsListRequest,
    SipCall, UpdatedRingGroupMember,
} from './payloads';
import {TransferCallFormType} from "../../../components/CallControls/TransferCallDialog";
import {AgentJoinCallFormType, JoinCallFormType} from "../../../components/CallControls/AddParticipantCallDialog";

export const ringGroupsList = createAsyncAction(
    'GET_RINGGROUPS_LIST',
    'GET_RINGGROUPS_LIST_SUCCESSED',
    'GET_RINGGROUPS_LIST_FAILED',
)<RingGroupListRequestPayload, RingGroupListResponsePayload, Error>();

export const removeRingGroup = createAsyncAction(
    'REMOVE_RINGGROUP',
    'REMOVE_RINGGROUP_SUCCESSED',
    'REMOVE_RINGGROUP_FAILED',
)<RemoveRingGroupPayload, undefined, Error>();

export const ringbackTonesList = createAsyncAction(
    'GET_RINGBACK_TONES_LIST',
    'GET_RINGBACK_TONES_LIST_SUCCESSED',
    'GET_RINGBACK_TONES_LIST_FAILED',
)<undefined, ApiFile[], Error>();

export const onHoldList = createAsyncAction(
    'GET_ON_HOLD_LIST',
    'GET_ON_HOLD_LIST_SUCCESSED',
    'GET_ON_HOLD_LIST_FAILED',
)<undefined, ApiFile[], Error>();

export const onResponseMessageList = createAsyncAction(
    'GET_RESPONSE_MESSAGE_LIST',
    'GET_RESPONSE_MESSAGE_LIST_SUCCESS',
    'GET_RESPONSE_MESSAGE_LIST_SUCCESS',
)<undefined, ApiFile[], undefined>();

export const uploadRingbackTone = createAsyncAction(
    'UPLOAD_RINGBACK_TONE_FILE',
    'UPLOAD_RINGBACK_TONE_FILE_SUCCESSED',
    'UPLOAD_RINGBACK_TONE_FILE_FAILED',
)<UploadRingbackToneRequestPayload, undefined, Error>();

export const prepareDataForAddNewRingGroup = createAsyncAction(
    'PREPARE_DATA_FOR_ADD_NEW_RING_GROUP',
    'PREPARE_DATA_FOR_ADD_NEW_RING_GROUP_SUCCESSED',
    'PREPARE_DATA_FOR_ADD_NEW_RING_GROUP_FAILED',
)<undefined, undefined, Error>();

export const addRingGroup = createAsyncAction(
    'ADD_RING_GROUP',
    'ADD_RING_GROUP_SUCCEEDED',
    'ADD_RING_GROUP_FAILED',
)<AddRingGroupRequest, undefined, APIErrorInterface>();

export const createNewRingGroup = createAsyncAction(
    'CREATE_RING_GROUP',
    'CREATE_RING_GROUP_RING_GROUP_SUCCEEDED',
    'CREATE_RING_GROUP_RING_GROUP_FAILED',
)<CreateRingGroupRequest, undefined, APIErrorInterface>();

export const groupMembersDetails = createAsyncAction(
    'GROUP_MEMBERS_DETAILS',
    'GROUP_MEMBERS_DETAILS_SUCCEEDED',
    'GROUP_MEMBERS_DETAILS_FAILED',
)<undefined, GroupMembersDetailsSuccessPayload, undefined>();

export const ringGroupDetails = createAsyncAction(
    'RING_GROUP_DETAILS',
    'RING_GROUP_DETAILS_SUCCEEDED',
    'RING_GROUP_DETAILS_FAILED',
)<RingGroupDetailsRequest, RingGroupType, APIErrorInterface | undefined>();

export const editRingGroup = createAsyncAction(
    'EDIT_RING_GROUP',
    'EDIT_RING_GROUP_SUCCEEDED',
    'EDIT_RING_GROUP_FAILED',
)<EditRingGroupRequest, undefined, APIErrorInterface>();

export const editRingGroupCallQueueStatus = createAsyncAction(
    'EDIT_RING_GROUP_CALL_QUEUE_STATUS',
    'EDIT_RING_GROUP_CALL_QUEUE_STATUS_SUCCEEDED',
    'EDIT_RING_GROUP_CALL_QUEUE_STATUS_FAILED',
)<
    ChangeCallQueueStatusRequest,
    ChangeCallQueueStatusResponse,
    APIErrorInterface
>();

export const getHuntGroupList = createAsyncAction(
    'GET_HET_HUNT_GROUP_LIST',
    'GET_HET_HUNT_GROUP_LIST_SUCCESS',
    'GET_HET_HUNT_GROUP_LIST_FAILED',
)<GetHuntGroupListRequest, RingGroupType[], undefined>();

export const updateRingGroupMember = createAsyncAction(
    'UPDATE_RING_GROUP_MEMBER_REQUEST',
    'UPDATE_RING_GROUP_MEMBER_SUCCESS',
    'UPDATE_RING_GROUP_MEMBER_FAILED',
)<UpdatedRingGroupMember,undefined,undefined>()

export const getSipCallsList = createAsyncAction(
    'GET_SIP_CALLS_LIST',
    'GET_SIP_CALLS_LIST_SUCCESS',
    'GET_SIP_CALLS_LIST_FAILED',
)<GetSipCallsListRequest, SipCall[], APIErrorInterface>();

export const getRingGroupsAccountList = createAsyncAction(
    'GET_RING_GROUPS_ACCOUNTS_LIST',
    'GET_RING_GROUPS_ACCOUNTS_SUCCESS',
    'GET_RING_GROUPS_ACCOUNTS_FAILED',
)<undefined, ExtensionType[], APIErrorInterface>();

export const transferCall = createAsyncAction(
    'TRANSFER_CALL',
    'TRANSFER_CALL_SUCCESS',
    'TRANSFER_CALL_FAILED',
)<TransferCallFormType & {callback?:()=>void},undefined,undefined>();

export const joinToActiveCall = createAsyncAction(
    'JOIN_TO_ACTIVE_CALL',
    'JOIN_TO_ACTIVE_CALL_SUCCESS',
    'JOIN_TO_ACTIVE_CALL_FAILED',
)<JoinCallFormType & {callback?:()=>void},undefined,undefined>();

export const unHoldCall = createAsyncAction(
    'UN_HOLD_CALL',
    'UN_HOLD_CALL_SUCCESS',
    'UN_HOLD_CALL_FAILED',
)<{id:string,tag?:string, callback?:()=>void},undefined,undefined>();

export const agentJoinToActiveCall = createAsyncAction(
    'AGENT_JOIN_TO_ACTIVE_CALL',
    'AGENT_JOIN_TO_ACTIVE_CALL_SUCCESS',
    'AGENT_JOIN_TO_ACTIVE_CALL_FAILED',
)<AgentJoinCallFormType & {callback?:()=>void},undefined,undefined>();

export const terminateCall = createAsyncAction(
    'TERMINATE_CALL',
    'TERMINATE_CALL_SUCCESS',
    'TERMINATE_CALL_FAILED',
)<{id:string,tag?:string, callback?:()=>void},undefined,undefined>();

export const holdCall = createAsyncAction(
    'HOLD_CALL',
    'HOLD_CALL_SUCCESS',
    'HOLD_CALL_FAILED',
)<{id:string,tag?:string, callback?:()=>void},undefined,undefined>();

export const updateSipCallStatusByWebSocketEvent = createAsyncAction(
    'UPDATE_SIP_CALL_STATUS_BY_WEBSOCKET',
    'UPDATE_SIP_CALL_STATUS_BY_WEBSOCKET_SUCCESS',
    'UPDATE_SIP_CALL_STATUS_BY_WEBSOCKET_FAILED',
)<SipCall, SipCall[], APIErrorInterface>();

export const recalcSipCallsDuration = createAsyncAction(
    'RECALC_SIP_CALLS_DURATION',
    'RECALC_SIP_CALLS_DURATION_SUCCESS',
    'RECALC_SIP_CALLS_DURATION_FAILED',
)<undefined, SipCall[], APIErrorInterface>();

export const updateServerTimeDifference = createAction(
    'UPDATE_SERVER_TIME_DIFFERENCE'
)<number>();