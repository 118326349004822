import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Colors} from '../../../styles/Colors';
import classNames from 'classnames';
import AnimatedNumberText from '../../../components/AnimatedNumber/AnimatedNumber';
import {TimeUnitsDisplay} from "./TimeUnitsDisplay";

export type CallStatisticTileProps = {
    value: string,
    textLine: string,
    isLoading: boolean,
    valueClass?: string,
    dataTestId?: string;
    padValue?: boolean;
    preventShowDash?: boolean;
    hideThousands?:boolean;
    inLine?:boolean
};

export function numberWithSpaces(x: number | undefined) {
    const input = (Math.ceil(x ?? 0) + '')
        .replaceAll('.', '')
        .replaceAll(',', '')
        .replaceAll(' ', '')
        .trim();
    
    const putSpaceAfterChars = 3;
    const stringParts = Array(Math.ceil(input.length / putSpaceAfterChars));
    if (stringParts.length) {
        stringParts[0] = input.slice(0, input.length % putSpaceAfterChars || putSpaceAfterChars);
        for (let i = 1, index = stringParts[0].length; index < input.length; i++) {
            stringParts[i] = input.slice(index, index += putSpaceAfterChars);
        }
    }

    return stringParts.join(' ').trim();
}

export const useStyles = makeStyles(() => ({
    container: {
        width: 212,
        height: 57,
        padding: '24px 0px',
        display: 'flex',
        flexDirection: 'column'
    },
    valueLabelTopDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    valueLabel: {
        width: '100%',
        height: 39,
        fontWeight: 300,
        fontSize: 28,
        lineHeight: '38.28px',

        "& hr": {
            marginBlockStart: 'unset',
            marginBlockEnd: 'unset',
            marginInlineStart: 'unset',
            marginInlineEnd: 'unset',
            height: 2,
            marginTop: 5
        },

        '& span': {
            fontWeight: 300,
            fontSize: 28,
            height: 39,
            margin: 0,
            padding: 0
        }
    },
    valueLabelBottomDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: 39,
        
        '& span': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
        }
    },
    containerLoading: {
        marginTop: -18
    },
    valueLabelPercentage: {
        fontSize: '21px !important',
        marginTop: 3
    },
    textLabel1: {
        width: '100%',
        height: 16,
        verticalAlign: 'middle',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '16px',
        color: Colors.Black,
        marginTop: 2,
        opacity: 0.6
    },
    textLabel2: {
        width: '100%',
        height: 16,
        verticalAlign: 'middle',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: Colors.Black,
        marginTop: 2,
        opacity: 0.5,
        textAlign: 'center'
    },
    timeMarkerLabel: {
        marginRight: 7,
        marginLeft: 2,

        '& span': {
            fontWeight: 300,
            fontSize: 20,
            color: Colors.Gray5,
            marginBottom: -3
        }
    }
}));

export const CallStatisticTile: React.VFC<CallStatisticTileProps> = (
    {
        value,
        textLine,
        isLoading,
        valueClass,
        dataTestId,
        padValue = true,
        preventShowDash = false,
        hideThousands = false,
        inLine = false
    }) => {

    const classes = useStyles();

    const isDecimalPercentValue = useMemo(() => {
        return (value || '').split(/[,.:]/).length >= 2 || value.endsWith('%');
    }, [value]);

    const validationRule = (arr: string[], index: number, v: string) => {
        return (arr.length == 3 && index === 2 && +v === 0 && +arr[0] === 0 && +arr[1] === 0)
            || (arr.length == 2 && index === 1 && +v === 0 && +arr[0] === 0)
    }

    return (<div className={classNames(classes.container)} data-testid={dataTestId}>
        <div className={classNames(classes.valueLabel, valueClass)}>
            {
                isDecimalPercentValue
                    ? (<div className={classes.valueLabelTopDiv}>
                        {
                            (value || '').split(/[,.:]/).filter(e => e && e.length).map((v, index, arr) => {
                                if (v.endsWith('%')) {
                                    return (
                                        <div key={'split_index_' + index}
                                             className={classNames(classes.valueLabelPercentage,
                                                 valueClass,
                                                 isLoading && !preventShowDash && classes.containerLoading,
                                                 classes.valueLabelBottomDiv)}
                                        >
                                            {isLoading && !preventShowDash
                                                ? ''
                                                : v.startsWith('0')
                                                    ? v.substring(1)
                                                    : ('.' + v)
                                            }
                                        </div>);
                                } else {
                                    return (
                                        <div key={'splitted_index_container_' + index} className={classes.valueLabelTopDiv}>
                                            {
                                                (+v > 0) || validationRule(arr, index, v) || (value.indexOf('%') !== -1) ? (

                                                    <AnimatedNumberText
                                                        className={classNames(
                                                            valueClass,
                                                            isLoading && !preventShowDash && classes.containerLoading,
                                                            classes.valueLabelBottomDiv
                                                        )}
                                                        value={parseFloat(v.replace(' ', ''))}
                                                        dataTestId="animated-number-value"
                                                        dataQa="animated-number-value"
                                                        showLongDash={isLoading && !preventShowDash}
                                                        formatValue={(v) => padValue ?
                                                            v.toFixed(0).padStart(2, '0') :
                                                            v.toFixed(0)
                                                        }
                                                        key={'split_index_sub3_' + index}
                                                        hideThousands={hideThousands}
                                                    />

                                                ) : (<></>)
                                            }

                                            {
                                                (value.indexOf(':') !== -1) &&
                                                ((+v > 0) || validationRule(arr, index, v)) &&
                                                (!isLoading || (isLoading && preventShowDash)) && (

                                                    <TimeUnitsDisplay
                                                        key={'split_index_sub2_' + index}
                                                        index={index}
                                                        arrLength={arr.length}
                                                    />

                                                )
                                            }

                                        </div>
                                    );
                                }
                            })
                        }
                    </div>)
                    : (<div className={classNames(classes.valueLabel, classes.valueLabelTopDiv, valueClass)}>
                        <AnimatedNumberText
                            className={classNames(
                                isLoading && !preventShowDash && classes.containerLoading ,
                                classes.valueLabelBottomDiv,
                                !isLoading && valueClass
                            )}
                            hideThousands={hideThousands}
                            value={parseFloat(value.replace(' ', ''))}
                            dataTestId="animated-number-value"
                            dataQa="animated-number-value"
                            showLongDash={isLoading && !preventShowDash}
                            formatValue={!hideThousands ? (v) => numberWithSpaces(v) : undefined}
                            inLine={inLine}
                        />
                    </div>)
            }
        </div>
        <div className={classes.textLabel2}>
            {textLine}
        </div>
    </div>);
};