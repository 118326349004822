export enum FollowMeSequence {
    Simultaneous = 'Simultaneous',
    Order = 'Order',
    Random = 'Random',
    PercentageList = 'Weighted',
}

export enum CallingPartyDisplay {
    CallerNumberAndName = 'Y',
    CallerNumberAndFrowardedName = 'I',
    ForwardedNumberAndName = 'N',
}

export interface AccountFollowMeSettings {
    default_followme_numbers_timeout: number;
    followme_info: {
        i_account: number;
        i_follow_me: number;
        max_forwards: number;
        sequence: FollowMeSequence;
        timeout: number;
    };
    followme_numbers: FollowMeNumber[];
}

export interface PeriodInfo {
    end_time: string;
    start_time: string;
    month_list: string[];
    monthday_list: number[];
    weekday_list: string[];
    year_list: string[];
}

export interface FollowMeNumber {
    active: string;
    i_account: string;
    i_follow_me: string;
    i_follow_me_number: string;
    i_follow_order: string;
    keep_original_cld: 'Y' | 'N';
    keep_original_cli: CallingPartyDisplay;
    max_sim_calls: unknown;
    period: unknown;
    period_description: unknown;
    period_definition: PeriodInfo[];
    redirect_number: string;
    timeout: string;
    use_tcp: string;
    weight: string;
    domain?: string;
    name: string;
}

export enum TransportProtocol {
    UDP = 'UDP',
    TCP = 'TCP',
}
