import React, {useCallback, useMemo, useState} from 'react';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import Button from '../../Button/Button';
import DataGrid from '../../DataGrid/DataGrid';
import MenuActionDialog from '../../AutoAttendants/MenuActionDialog';
import {SettingsFormType} from './SettingsForm.utils';
import AlertDialog from '../../AlertDialog/AlertDialog';
import {DialogButton} from '../../AlertDialog/DialogContainer';
import {Grid} from '@material-ui/core';
import {
    ActionsFormProps,
    useStyles,
    ActionsFormType,
    MenuActionFormType,
    PlusIcon,
    generateColumns,
} from './ActionsForm.utils';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import {ServiceFeatureName} from '../../../store/types/ServiceFeature';

const ActionsForm: React.FC<ActionsFormProps> = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {values, setFieldValue} = useFormikContext<
        ActionsFormType & SettingsFormType
    >();

    const [showActionModal, setShowActionModal] = useState(false);

    const [bindedAction, setBindedAction] = useState<
        MenuActionFormType | undefined
    >(undefined);

    const serviceFeatures = useSelector(
        (state: ReduxState) => state.autoAttendants.autoAttendantDetails?.extension?.serviceFeatures
            || state.autoAttendants?.autoAttendantInfo?.account_info?.service_features
            || state.autoAttendants?.autoAttendantInfo?.serviceFeatures
            || state.extensions?.serviceFeatures
    );

    const unifiedMessagingVoicemailEnabled = useMemo(() => serviceFeatures
                ?.find((v) => v.name === ServiceFeatureName.UnifiedMessaging)
                ?.attributes.find((v) => v.name === 'fax_only_mode')?.effective_values[0]
            === "N",
        [serviceFeatures]);

    const [
        actionToRemove,
        setActionToRemove,
    ] = useState<MenuActionFormType | null>(null);

    const onSave = useCallback(
        (form: MenuActionFormType) => {
            const actions = [...values?.actions];

            if (bindedAction) {
                const index = actions.findIndex(
                    (v) => v.userInput === bindedAction.userInput,
                );
                if (index !== -1) {
                    actions[index] = form;
                }
            } else {
                actions.push(form);
            }
            setFieldValue('actions', actions);

            setTimeout(() => setBindedAction(undefined)
                , 100)
            setShowActionModal(false);
        },
        [bindedAction, values?.actions],
    );

    const onPressEdit = useCallback(
        (action: MenuActionFormType) => {
            setBindedAction(action);
            setShowActionModal(true);
        },
        [values?.actions],
    );

    const onPressRemove = useCallback(
        (action: MenuActionFormType) => {
            setActionToRemove(action);
        },
        [values?.actions],
    );

    const deleteAction = () => {
        setFieldValue(
            'actions',
            values?.actions.filter(
                (v) => v.userInput !== actionToRemove?.userInput,
            ),
        );
        setActionToRemove(null);
        setShowActionModal(false);
    };

    const columns = useMemo(
        () => generateColumns(t, classes, onPressEdit, onPressRemove, unifiedMessagingVoicemailEnabled),
        [values?.actions],
    );

    return (
        <Grid className={classes.actionsForm}>
            <div className={classes.addButton}>
                <Button
                    primary
                    accent
                    dataQa="ring-schedule-add-interval-button"
                    onClick={() => {
                        setShowActionModal(true);
                        setBindedAction(undefined);
                    }}
                >
                    {PlusIcon}
                    {t('screens:autoAttendants.addAction')}
                </Button>
            </div>

            <DataGrid<MenuActionFormType>
                columns={columns}
                data={values?.actions}
                rowCount={values?.actions.length}
                centeredRows
                narrowRows
                hidePagination
                hideFooter={values?.actions.length !== 0}
                getItemId={(_, index) => index}
                className={classes.table}
                classes={{tableContainer: classes.tableContainer}}
            />

            <MenuActionDialog
                isOpen={showActionModal}
                toggleVisibility={() => setShowActionModal(!showActionModal)}
                initialValue={bindedAction}
                menuActions={values?.actions}
                onSave={onSave}
                deleteAction={onPressRemove}
                isDialExtensionDirectlyBlocked={
                    values?.allowCallersToDialKnownNumber
                }
            />
            <AlertDialog
                isOpen={actionToRemove !== null}
                description={t('screens:extensions.deleteAction', {
                    value: actionToRemove?.userInput,
                })}
                contentClass="alert-content"
                className={classes.removeDialogContainer}
                hideHeader={true}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => setActionToRemove(null)}
                    />,
                    <DialogButton
                        key="delete"
                        label={t('common:delete')}
                        onClick={deleteAction}
                        dataTestId={'delete'}
                    />,
                ]}
                dataQa="remove-interval-modal"
                dataTestId="remove-interval-modal"
            />
        </Grid>
    );
};

export default ActionsForm;
